import React, { FC } from "react";
import DeviceMapsTable from "./DeviceMapsTable";

const DeviceMapsList: FC = () => {
  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Привязки интерфейсов</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <DeviceMapsTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceMapsList;
