import { apiRequest } from "./common";
import { FirmwareSectionName } from "../dto/device-map";

const update = (deviceId: string | number, sync_types: FirmwareSectionName[]) =>
  apiRequest<unknown>(`/api/v1/devices/${deviceId}/sync`, "put", {
    sync_types,
  });

export const deviceSettingsSync = {
  update,
};
