const INPUT_FAN = 3;
const OUTPUT_FAN = 4;
const FROST_DEFENCE = 5;
const TAN_OVERHEATING = 7;
const RECUPERATOR_ERROR = 9;
const FIRE = 12;
const SENSOR_DISCONNECTED = 13;
const OUTPUT_CHANNEL_TEMP_SENSOR = 19;
const CHANNEL_FROZEN = 22;
const WINTER_MODE_SWITCH_ERROR = 23;
const RECUPERATOR_ROTATION_ERROR = 24;
const NO_COOLANT_ERROR = 26;
const BACKWATER_TEMP_SENSOR = 27;
const NOT_ENOUGH_EEPROM = 28;
const HUMIDITY_SENSOR_ERROR = 29;
const CO2_SENSOR_ERROR = 30;

const bitSet = (code: number, bit: number) => (code & (1 << bit)) > 0;

export const alarmed = (code: number) => code > 0;

export const input_fan = (code: number) => bitSet(code, INPUT_FAN);
export const output_fan = (code: number) => bitSet(code, OUTPUT_FAN);
export const frost_defence = (code: number) => bitSet(code, FROST_DEFENCE);
export const tan_overheating = (code: number) => bitSet(code, TAN_OVERHEATING);
export const recuperator_error = (code: number) =>
  bitSet(code, RECUPERATOR_ERROR);
export const fire = (code: number) => bitSet(code, FIRE);
export const sensor_disconnected = (code: number) =>
  bitSet(code, SENSOR_DISCONNECTED);
export const output_channel_temp_sensor = (code: number) =>
  bitSet(code, OUTPUT_CHANNEL_TEMP_SENSOR);
export const channel_frozen = (code: number) => bitSet(code, CHANNEL_FROZEN);
export const winter_mode_switch_error = (code: number) =>
  bitSet(code, WINTER_MODE_SWITCH_ERROR);
export const recuperator_rotation_error = (code: number) =>
  bitSet(code, RECUPERATOR_ROTATION_ERROR);
export const no_coolant_error = (code: number) =>
  bitSet(code, NO_COOLANT_ERROR);
export const backwater_temp_sensor = (code: number) =>
  bitSet(code, BACKWATER_TEMP_SENSOR);
export const not_enough_eeprom = (code: number) =>
  bitSet(code, NOT_ENOUGH_EEPROM);
export const humidity_sensor_error = (code: number) =>
  bitSet(code, HUMIDITY_SENSOR_ERROR);
export const co2_sensor_error = (code: number) =>
  bitSet(code, CO2_SENSOR_ERROR);

export const deviceAlarms = (code) => ({
  alarmed: alarmed(code),
  input_fan: input_fan(code),
  output_fan: output_fan(code),
  tan_overheating: tan_overheating(code),
  recuperator_error: recuperator_error(code),
  fire: fire(code),
  sensor_disconnected: sensor_disconnected(code),
  output_channel_temp_sensor: output_channel_temp_sensor(code),
  frost_defence: frost_defence(code),
  channel_frozen: channel_frozen(code),
  winter_mode_switch_error: winter_mode_switch_error(code),
  recuperator_rotation_error: recuperator_rotation_error(code),
  no_coolant_error: no_coolant_error(code),
  backwater_temp_sensor: backwater_temp_sensor(code),
  not_enough_eeprom: not_enough_eeprom(code),
  humidity_sensor_error: humidity_sensor_error(code),
  co2_sensor_error: co2_sensor_error(code),
});
