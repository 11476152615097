import React, { FC } from "react";

const FirmwareRow: FC<{
  register: Readonly<{
    address: number;
    data_type: string;
    description: string;
  }>;
}> = ({ register: { address, description, data_type } }) => (
  <tr>
    <td>{address}</td>
    <td>{data_type}</td>
    <td>{description}</td>
  </tr>
);

export default FirmwareRow;
