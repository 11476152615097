import React, { FC, useCallback, useMemo } from "react";

import { togglePanelRunningRequest } from "../../redux/actions/panels/panel";
import { deviceState, getUshort } from "../../common";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { PanelInterface } from "../../dto";
import { Link } from "react-router-dom";

const PanelMenu: FC<{ panel: PanelInterface }> = ({ panel }) => {
  const dispatch = useDispatch<DispatchType>();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(togglePanelRunningRequest(panel));
    },
    [panel, dispatch]
  );

  const default_classes = useMemo(
    () =>
      `btn btn-device-menu list-group-item${
        panel.action_in_progress ? " disabled" : ""
      }`,
    [panel.action_in_progress]
  );

  return (
    <div className="list-group m-b-0">
      <div className="row clearfix m-b-0">
        <div
          className="col-lg-12 col-md-12 col-sm-4 col-xs-4"
          style={{ marginBottom: 0 }}
        >
          <a className={default_classes} href="#" onClick={handleClick}>
            {deviceState(getUshort(panel.payload, 0)).stopped ? "Пуск" : "Стоп"}
          </a>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-4 col-xs-4"
          style={{ marginBottom: 0 }}
        >
          <Link
            className={`${default_classes} middle`}
            to={`/panels/${panel.id}/settings`}
          >
            Параметры
          </Link>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-4 col-xs-4"
          style={{ marginBottom: 0 }}
        >
          <Link className={default_classes} to={`/panels/${panel.id}/schedule`}>
            Расписание
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PanelMenu;
