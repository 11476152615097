import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { profileSettingChanged } from "../../redux/actions/profile/profile_actions";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { ProfileUserProps } from "../../dto/user";

const baseStyle: CSSProperties = {
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "flex-start",
  padding: "20px",
  // borderWidth: 2,
  borderRadius: "50%",
  // borderColor: "#eeeeee",
  // borderStyle: "dashed",
  // backgroundColor: "#fafafa",
  // color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  height: "100%",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ProfileAvatar: FC<{ user: ProfileUserProps }> = ({ user }) => {
  const dispatch = useDispatch<DispatchType>();

  const fileAdded = useCallback(
    (file) => {
      dispatch(profileSettingChanged({ value: file, field_name: "avatar" }));
    },
    [dispatch]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        fileAdded(file);
      });
    },
    [fileAdded]
  );

  // clean up
  useEffect(
    () => () => {
      if (user.avatar?.preview) URL.revokeObjectURL(user.avatar.preview);
    },
    [user.avatar]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
    },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: user.avatar?.preview || user.avatar_url ? "none" : "block",
        }}
      >
        <span>Перетащите сюда файл или кликните область.</span>
      </div>
      {(user.avatar?.preview || user.avatar_url) && [
        <div
          key="image-preview"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <img
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            data-dz-thumbnail=""
            alt={user.mock_avatar?.name}
            src={user.avatar?.preview || user.avatar_url}
          />
        </div>,
      ]}
    </div>
  );
};

export default ProfileAvatar;

// export default class ProfileAvatar extends React.Component {
//   constructor(props) {
//     super(props);
//     this.initDropzone = this.initDropzone.bind(this);
//     this.fileAdded = this.fileAdded.bind(this);
//     this.overlayClicked = this.overlayClicked.bind(this);
//     this.state = {
//       dropzone: null,
//     };
//   }
//
//   render() {
//     return (
//       <React.Fragment>
//         <DropzoneComponent
//           ref="dropzone"
//           config={componentConfig}
//           eventHandlers={{
//             ...eventHandlers,
//             addedfile: this.fileAdded,
//             init: this.initDropzone,
//           }}
//           djsConfig={djsConfig}
//         />
//         <div
//           style={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             width: 155,
//             height: 155,
//             zIndex: 20,
//             borderRadius: "50%",
//           }}
//           onClick={this.overlayClicked}
//         />
//       </React.Fragment>
//     );
//   }
//
//   overlayClicked() {
//     ReactDOM.findDOMNode(this.refs.dropzone).click();
//   }
//
//   fileAdded(file) {
//     store.dispatch(profileSettingChanged(file, "avatar"));
//     this.state.dropzone.emit("complete", file);
//   }
//
//   initDropzone(dropzone) {
//     this.state.dropzone = dropzone;
//     if (this.props.user.mockAvatar === void 0) return;
//
//     const stored_avatar = Object.assign({}, this.props.user.mockAvatar, {
//       accepted: true,
//     });
//     dropzone.files.push(stored_avatar);
//     dropzone.emit("addedfile", stored_avatar);
//     dropzone.createThumbnailFromUrl(stored_avatar, this.props.user.avatarUrl);
//     dropzone.emit("complete", stored_avatar);
//   }
// }
//
