import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { DispatchType } from "../../redux/store/type";
import { requestNewPassword } from "../../redux/actions/profile/profile_actions";

const theme = createTheme();

const validate = (email: string) => {
  const errors: { [name: string]: string } = {};

  if (email.length === 0) errors.email = "Не может быть пустым";
  return errors;
};

function NewPassword() {
  const dispatch = useDispatch<DispatchType>();

  const [errors, setErrors] = useState<{
    [name: string]: string;
  }>({});

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email") as string;
      const errors = validate(email);
      setErrors(errors);
      const valid = Object.keys(errors).length === 0;
      if (valid) {
        dispatch(requestNewPassword(email));
      }
    },
    [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#52A3ED" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1">
            Введите e-mail, который Вы указывали при регистрации.
          </Typography>
          <Typography component="small">
            Мы вышлем на него ссылку для сброса пароля.
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <TextField
              error={!!errors.email}
              helperText={errors.email}
              required
              fullWidth
              id="email"
              label="Электропочта"
              name="email"
              autoComplete="username"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Отправить инструкции!
            </Button>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Link href="/users/sign_in" variant="body2">
                  Вход
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default NewPassword;
