export default class DeviceMapDecorator {
  device_map: any;

  constructor(device_map) {
    this.device_map = device_map;
  }

  register_settings(read_address, write_address) {
    return this.device_map.register_settings.find(
      (rs) =>
        rs.read_address === read_address && rs.write_address === write_address
    );
  }

  register_settings_index(read_address, write_address) {
    return this.device_map.register_settings.findIndex(
      (rs) =>
        rs.read_address === read_address && rs.write_address === write_address
    );
  }

  thresholds_void() {
    return (
      this.device_map.register_settings.length === 0 &&
      this.fields_void("settings")
    );
  }

  settings_void() {
    return (
      this.device_map.register_settings.length === 0 &&
      this.fields_void("admin_settings")
    );
  }

  schedule_void() {
    return (
      this.device_map.register_settings.length === 0 &&
      this.fields_void("schedule")
    );
  }

  time_void() {
    return (
      this.device_map.register_settings.length === 0 && this.fields_void("time")
    );
  }

  settings_initialized(field_name) {
    return !this.fields_void(field_name);
  }

  fields_void(field_name) {
    return (
      this.device_map[`${field_name}_read_start_reg`] === null ||
      this.device_map[`${field_name}_write_start_reg`] === null ||
      this.device_map[`${field_name}_read_end_reg`] === null ||
      this.device_map[`${field_name}_write_end_reg`] === null
    );
  }
}
