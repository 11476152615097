import { createReducer } from "@reduxjs/toolkit";
import {
  loadProfile,
  profileSettingChanged,
} from "../actions/profile/profile_actions";
import { ProfileErrorProps, ProfileUserProps } from "../../dto/user";

const opposite_field_name = (field_name) =>
  field_name === "password" ? "password_confirmation" : "password";

const validateForm = (newState: ProfileUserProps) => {
  const errors: ProfileErrorProps = {};
  [
    "email",
    "phone",
    "notify_via_sms",
    "old_password",
    "password",
    "password_confirmation",
  ].forEach((field_name) => {
    const value = newState[field_name];
    switch (field_name) {
      case "email":
      case "old_password":
        if (value === void 0 || value.length === 0)
          errors[field_name] = "Поле не может быть пустым";
        break;
      case "password":
      case "password_confirmation":
        if (
          value &&
          value.length > 0 &&
          newState[opposite_field_name(field_name)] &&
          newState[opposite_field_name(field_name)].length > 0
        ) {
          if (value !== newState[opposite_field_name(field_name)]) {
            errors.password = "Пароль и подтверждение должны совпадать";
            errors.password_confirmation =
              "Пароль и подтверждение должны совпадать";
          }
        } else {
          if (value === void 0 || value.length === 0)
            errors[field_name] = "Поле не может быть пустым";
        }
        break;
      case "phone":
      case "notify_via_sms":
        if (field_name === "phone") {
          if (
            newState.notify_via_sms &&
            (value === void 0 || value.length === 0)
          )
            errors[field_name] = "Поле не может быть пустым";
        } else {
          if (
            value &&
            (newState.phone === void 0 || newState.phone.length === 0)
          )
            errors["phone"] = "Поле не может быть пустым";
        }
        break;
    }
  });
  return errors;
};

export default createReducer<ProfileUserProps | null>(null, (builder) => {
  builder.addCase(loadProfile.fulfilled, (state, action) => ({
    errors: {
      old_password: "Поле не может быть пустым",
      password: "Поле не может быть пустым",
      password_confirmation: "Поле не может быть пустым",
    },
    ...action.payload,
  }));

  builder.addCase(profileSettingChanged, (state, action) => {
    const newState = {
      ...state,
      [action.payload.field_name]: action.payload.value,
    };

    return {
      ...newState,
      errors: validateForm(newState),
    };
  });
});
