import { admin } from "./admin";
import { deviceSettings } from "./device_settings";
import { deviceUsers } from "./device_users";
import { devices } from "./devices";
import { profile } from "./profile";
import { users } from "./users";
import { deviceSettingsSync } from "./sync";

export const api = {
  admin,
  deviceSettings,
  deviceSettingsSync,
  deviceUsers,
  devices,
  profile,
  users,
};
