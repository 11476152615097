import React, { FC, useMemo } from "react";
import PanelRow from "./PanelRow";
import StateRow from "./StateRow";
import SeasonIcon from "./SeasonIcon";
import AlarmRow from "./AlarmRow";
import ReplaceFiltersMessage from "./ReplaceFiltersMessage";
import {
  getUshort,
  getUint,
  season,
  workingMode,
  deviceState,
  deviceAlarms,
} from "../../common";
import { PanelInterface } from "../../dto";

const Panel: FC<{ panel: PanelInterface }> = ({ panel }) => {
  const code = useMemo(() => getUshort(panel.payload, 0), [panel]);
  const state = useMemo(() => deviceState(code), [code]);
  const alarmCode = useMemo(() => getUint(panel.payload, 1), [panel]);
  const colSpan =
    panel.template.rows.length > 0
      ? panel.template.rows[0].blocks.length - 1
      : 1;
  return (
    <div className="row clearfix device-pane panel-row">
      <div
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 align-center"
        style={{ marginBottom: 0, paddingTop: "1rem" }}
      >
        <table className="device-display-table">
          <tbody>
            <tr>
              <td />
              <td colSpan={colSpan}>
                <AlarmRow alarms={deviceAlarms(alarmCode)} />
              </td>
              <td colSpan={colSpan}>
                <SeasonIcon season={season(code)} />
              </td>
            </tr>
            {panel.template.rows.map((row, i) => (
              <PanelRow
                one_line={panel.template.rows.length === 1}
                key={i}
                row_index={i}
                row={row}
                firmware={panel.template.firmware}
                payload={panel.payload}
              />
            ))}
            <tr>
              <td />
              <td colSpan={colSpan}>
                <ReplaceFiltersMessage
                  alarmed={state.needs_filters_replacement}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <StateRow state={state} working_mode={workingMode(code)} />
      </div>
    </div>
  );
};

export default Panel;
