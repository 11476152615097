import React, { FC, useCallback } from "react";

const TabLink: FC<{
  field_name: string;
  active_pane: string;
  title: string;
  onClick: (fieldName: string) => void;
  isDisabled?: () => boolean;
}> = ({ field_name, active_pane, title, onClick, isDisabled }) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      onClick(field_name);
    },
    [field_name, onClick]
  );

  return (
    <li
      role="presentation"
      className={field_name === active_pane ? "active" : ""}
    >
      <a
        href="#"
        aria-expanded={field_name === active_pane}
        onClick={handleClick}
        className={isDisabled === void 0 ? "" : isDisabled() ? "disabled" : ""}
      >
        {title}
      </a>
    </li>
  );
};

export default TabLink;
