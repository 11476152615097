export function stateLabel(state) {
  let active_state = Object.keys(state).find((k) => state[k]);
  if (active_state === void 0) active_state = "no_connection";
  return {
    no_connection: "Нет связи",
    stopped: "Останов",
    awaiting: "Ожидание",
    blocked: "Блокировка",
    ventilating: "Продувка",
    warming: "Прогрев",
    jalousie: "Жалюзи",
    restart: "Перезапуск",
    run: "Работа",
    needs_filters_replacement: "Замените фильтры",
    alarm_reset: "Сброс аварии",
  }[active_state];
}

export const alarm_messages = {
  input_fan: "Вентилятор притока",
  output_fan: "Вентилятор вытяжки",
  tan_overheating: "Перегрев ТЭН",
  recuperator_error: "Авария рекуператора",
  fire: "Пожар",
  sensor_disconnected: "Обрыв датчика",
  output_channel_temp_sensor: "Датчик температуры вытяжки",
  frost_defence: "Защита от заморозки",
  channel_frozen: "Переохлаждение канала",
  winter_mode_switch_error: "Аварийный переход в зиму",
  recuperator_rotation_error: "Нет вращения рекуператора",
  no_coolant_error: "Нет теплоносителя",
  backwater_temp_sensor: "Датчик температуры обратной воды",
  not_enough_eeprom: "Нехватка EEPROM",
  humidity_sensor_error: "Авария датчика влажности",
  co2_sensor_error: "Авария датчика СО2",
};

export function alarmMessages(alarms) {
  const output = [];
  Object.keys(alarms).map((alarm_name) => {
    if (alarm_name === "alarmed" || !alarms[alarm_name]) return true;
    output.push(alarm_messages[alarm_name]);
  });
  return output.join(", ");
}
