import { createReducer } from "@reduxjs/toolkit";
import {
  addBottomCell,
  addDeviceCell,
  addDeviceRow,
  deviceInterfaceVersionChanged,
  removeBottomCell,
  removeDeviceCell,
  removeDeviceRow,
  requestCreateDeviceInterface,
  updateDeviceCell,
} from "../actions/interface/device_interfaces";
import { loadInterfaceWithBlocks } from "../actions/interface/interfaces";
import { DeviceInterfaceProps } from "../../dto/device-map";

export default createReducer<DeviceInterfaceProps>(
  { rows: [{ blocks: [{}] }] },
  (builder) => {
    builder.addCase(
      loadInterfaceWithBlocks.fulfilled,
      (state, action) => action.payload.device_interface
    );
    builder.addCase(addDeviceRow, (state) => {
      if (state.rows.length === 1) {
        const new_row = [];
        state.rows[0].blocks.forEach((b, i) =>
          new_row.push({ row_index: 1, ord: i })
        );
        return {
          ...state,
          rows: [{ blocks: state.rows[0].blocks }, { blocks: new_row }],
        };
      } else return state;
    });
    builder.addCase(removeDeviceRow, (state) => {
      if (state.rows.length === 2)
        return { ...state, rows: [{ blocks: state.rows[0].blocks }] };
      else return state;
    });
    builder.addCase(addDeviceCell, (state) => {
      const len = state.rows[0].blocks.length;
      if (state.rows[0].blocks[len - 1].id === void 0) return state;
      let new_rows = null;
      if (state.rows.length === 2) {
        new_rows = [
          {
            blocks: [
              ...state.rows[0].blocks,
              { row_index: 0, ord: state.rows[0].blocks.length },
            ],
          },
          {
            blocks: [
              ...state.rows[1].blocks,
              { row_index: 1, ord: state.rows[1].blocks.length },
            ],
          },
        ];
      } else {
        new_rows = [
          {
            blocks: [
              ...state.rows[0].blocks,
              { row_index: 0, ord: state.rows[0].blocks.length },
            ],
          },
        ];
      }
      return { ...state, rows: new_rows };
    });
    builder.addCase(removeDeviceCell, (state, action) => {
      let new_rows;
      if (state.rows.length === 2) {
        new_rows = [
          {
            blocks: [
              ...state.rows[0].blocks.slice(0, action.payload.ord),
              ...state.rows[0].blocks.slice(action.payload.blocks.length),
            ],
          },
          {
            blocks: [
              ...state.rows[1].blocks.slice(0, action.payload.ord),
              ...state.rows[1].blocks.slice(action.payload.blocks.length),
            ],
          },
        ];
      } else {
        new_rows = [
          {
            blocks: [
              ...state.rows[0].blocks,
              { row_index: 0, ord: state.rows[0].blocks.length },
            ],
          },
        ];
      }
      return { ...state, rows: new_rows };
    });
    builder.addCase(addBottomCell, (state, action) => {
      const new_rows = [
        {
          blocks: state.rows[0].blocks,
        },
        {
          blocks: [
            ...state.rows[1].blocks,
            { row_index: 1, ord: action.payload },
          ],
        },
      ];
      return { ...state, rows: new_rows };
    });
    builder.addCase(removeBottomCell, (state) => {
      const new_rows = [
        {
          blocks: state.rows[0].blocks,
        },
        {
          blocks: [
            ...state.rows[1].blocks.slice(0, state.rows[1].blocks.length - 1),
          ],
        },
      ];
      return { ...state, rows: new_rows };
    });
    builder.addCase(updateDeviceCell, (state, action) => {
      let new_rows;
      if (state.rows.length === 2) {
        if (action.payload.row_index === 0) {
          new_rows = [
            {
              blocks: [
                ...state.rows[0].blocks.slice(0, action.payload.ord),
                {
                  ...state.rows[0].blocks[action.payload.ord],
                  ...action.payload,
                },
                ...state.rows[0].blocks.slice(action.payload.ord + 1),
              ],
            },
            {
              blocks:
                state.rows[action.payload.row_index].blocks[action.payload.ord]
                  .units && !action.payload.units
                  ? [
                      ...state.rows[1].blocks.slice(0, action.payload.ord - 1),
                      {
                        row_index: 1,
                        ord: action.payload.ord - 1,
                      },
                      ...state.rows[1].blocks.slice(action.payload.ord - 1),
                    ]
                  : state.rows[1].blocks,
            },
          ];
        } else {
          new_rows = [
            {
              blocks: state.rows[0].blocks,
            },
            {
              blocks: [
                ...state.rows[1].blocks.slice(0, action.payload.ord),
                {
                  ...state.rows[1].blocks[action.payload.ord],
                  ...action.payload,
                },
                ...state.rows[1].blocks.slice(action.payload.ord + 1),
              ],
            },
          ];
        }
      } else {
        new_rows = [
          {
            blocks: [
              ...state.rows[0].blocks.slice(0, action.payload.ord),
              {
                ...state.rows[0].blocks[action.payload.ord],
                ...action.payload,
              },
              ...state.rows[0].blocks.slice(action.payload.ord + 1),
            ],
          },
        ];
      }
      return { ...state, rows: new_rows };
    });
    builder.addCase(deviceInterfaceVersionChanged, (state, action) => ({
      ...state,
      version: action.payload,
    }));
    builder.addCase(
      requestCreateDeviceInterface.fulfilled,
      (state, action) => action.payload
    );
  }
);
