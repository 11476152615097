import { api } from "../../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { DeviceBindingProps } from "../../../dto/device-binding";
import { successToaster } from "../../../common/alerts";

export const loadDeviceUsers = createAsyncThunk(
  "device_users/load",
  async (userId: number | string, { rejectWithValue }) => {
    try {
      const [{ user }, { device_users }] = await Promise.all([
        api.users.show(userId),
        api.deviceUsers.list(userId),
      ]);
      return { user, device_users };
    } catch (e) {
      handleErrors("Не удалось загрузить устройства пользователя", e);
      rejectWithValue(e);
    }
  }
);

export const bindDeviceRequest = createAsyncThunk(
  "device_users/bind",
  async (
    { user, device }: { user: { id: number | string }; device: { id: number } },
    { rejectWithValue }
  ) => {
    try {
      const { device_user: deviceBinding } = await api.deviceUsers.create(
        user.id,
        device.id
      );
      successToaster(`Панель ${deviceBinding.serial} привязана к пользователю`);
      return deviceBinding;
    } catch (error) {
      handleErrors("Не удалось привязать панель к пользователю", error);
      return rejectWithValue(error);
    }
  }
);

export const unbindDeviceRequest = createAsyncThunk(
  "device_users/unbind",
  async (device: DeviceBindingProps, { rejectWithValue }) => {
    try {
      const { device_user: deviceBinding } = await api.deviceUsers.destroy(
        device.id
      );
      successToaster(`Панель ${device.serial} отвязана от пользователя`);
      return deviceBinding;
    } catch (error) {
      handleErrors("Не удалось отвязать панель от пользователя", error);
      return rejectWithValue(error);
    }
  }
);
