import React, { FC } from "react";

import RegisterSelect from "./common/RegisterSelect";
import {
  DeviceMapProps,
  FirmwareProps,
  FirmwareSectionName,
  FirmwareSectionRegisterName,
} from "../../dto/device-map";

const SettingsBlock: FC<{
  readonly field_name: FirmwareSectionName;
  readonly is_start: boolean;
  readonly current_device_map: { readonly device_map: DeviceMapProps };
  readonly firmware: FirmwareProps;
  readonly regChanged: (fieldName: keyof DeviceMapProps, value: number) => void;
}> = ({ field_name, is_start, current_device_map, firmware, regChanged }) => {
  const startStop = is_start ? "start" : "end";
  const read_field_name: FirmwareSectionRegisterName = `${field_name}_read_${startStop}_reg`;
  const write_field_name: FirmwareSectionRegisterName = `${field_name}_write_${startStop}_reg`;
  return (
    <div className="row clearfix">
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-5 form-control-label">
        <label htmlFor={field_name}>
          {is_start ? "Начальный адрес" : "Конечный адрес"}
        </label>
      </div>

      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-7">
        <div className="form-group">
          <RegisterSelect
            label="Запись"
            field_name={write_field_name}
            value={current_device_map.device_map[write_field_name] || ""}
            registers={firmware.registers}
            onChange={regChanged}
          />
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-7">
        <div className="form-group">
          <RegisterSelect
            label="Чтение"
            field_name={read_field_name}
            value={current_device_map.device_map[read_field_name] || ""}
            registers={firmware.registers}
            onChange={regChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsBlock;
