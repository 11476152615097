import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FC } from "react";
import Alert from "@mui/material/Alert";

const theme = createTheme();

const ConfirmationSuccess: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#52A3ED" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Alert severity="success">
            Ваш адрес электронной почты успешно подтверждён!
          </Alert>
          <Grid container flexDirection="column" alignItems="center">
            <Grid item>
              <Link variant="h5" href="/users/sign_in">
                Вход
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ConfirmationSuccess;
