import React, { FC, useCallback } from "react";
import DeviceRow from "../interfaces/DeviceRow";
import BlockMapping from "./BlockMapping";
import BitMask from "./BitMask";
import tabPane from "../common/TabPane";
import tabPanel from "../common/TabPanel";
import {
  addBitMask,
  currentBlockChanged,
  removeBitMask,
} from "../../redux/actions/device_map/device_map";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState } from "../../redux/store/type";

const BlocksPane: FC = () => {
  const dispatch = useDispatch();
  const current_device_map = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );
  const toggleBitMask = useCallback(
    (e) => {
      if (e.target.checked) dispatch(addBitMask());
      else dispatch(removeBitMask());
    },
    [dispatch]
  );
  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <table className="device-table">
            <tbody>
              {current_device_map.device_interface.rows.map((row, i) => {
                return (
                  <DeviceRow
                    current_block={current_device_map.current_block}
                    onCellClick={(block) =>
                      dispatch(currentBlockChanged(block))
                    }
                    key={i}
                    row_index={i}
                    row={row}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <BlockMapping />
        </div>
      </div>
      {current_device_map.current_block && [
        <div key={1} className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <input
              type="checkbox"
              id="bit_mask"
              checked={
                current_device_map.current_block.bit_mask &&
                current_device_map.current_block.bit_mask.length > 0
              }
              onChange={toggleBitMask}
            />
            <label htmlFor="bit_mask">Битовая маска</label>
          </div>
        </div>,
        current_device_map.current_block.bit_mask && (
          <div key={2} className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 align-center">
              <BitMask mask_rows={current_device_map.current_block.bit_mask} />
            </div>
          </div>
        ),
      ]}
    </>
  );
};

export default tabPane(tabPanel(BlocksPane), "blocks");
