import React, { FC } from "react";

const TabPane = (WrappedComponent, field_name) => {
  const Pane: FC<any> = (props: any) => {
    return <WrappedComponent {...props} field_name={field_name} />;
  };
  return Pane;
};

export default TabPane;
