import React, { FC, useCallback, useMemo } from "react";
import SettingsBlock from "./SettingsBlock";
import AdminSettingsTable from "./AdminSettingsTable";
import DeviceMapDecorator from "../../redux/decorators/device_map_decorator";
import tabPanel from "../common/TabPanel";
import { regChanged } from "../../redux/actions/device_map/device_map";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import FirmwareDecorator from "../../redux/decorators/firmware_decorator";
import { DeviceMapProps, FirmwareSectionName } from "../../dto/device-map";

const TabContent: FC<{ field_name: FirmwareSectionName }> = ({
  field_name,
}) => {
  const dispatch = useDispatch<DispatchType>();

  const current_device_map = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );

  const device_map = useMemo(
    () => new DeviceMapDecorator(current_device_map.device_map),
    [current_device_map.device_map]
  );

  const firmware = useSelector<BusstationState, any>((state) => state.firmware);

  const startRegChanged = useCallback(
    (name: keyof DeviceMapProps, value: number) => {
      dispatch(regChanged({ name, value }));
      const end_field_name = name.replace(
        "start_reg",
        "end_reg"
      ) as keyof DeviceMapProps;
      if (
        device_map[end_field_name] === void 0 ||
        device_map[end_field_name] < value
      ) {
        const field_type = name.indexOf("write") >= 0 ? "write" : "read";
        const opp_field_type = field_type === "write" ? "read" : "write";
        const opp_start_name = name.replace(field_type, opp_field_type);
        const opp_end_name = `${opp_start_name.replace(
          "start_reg",
          ""
        )}end_reg`;
        let delta = 0;
        if (
          device_map[opp_start_name] !== void 0 &&
          device_map[opp_end_name] !== void 0
        ) {
          delta = device_map[opp_end_name] - device_map[opp_start_name];
        }
        dispatch(regChanged({ name: end_field_name, value: value + delta }));
      }
    },
    [device_map, dispatch]
  );

  const endRegChanged = useCallback(
    (name: keyof DeviceMapProps, value: number) => {
      dispatch(
        regChanged({
          name,
          value,
        })
      );
      const start_field_name = `${name.replace(
        "end_reg",
        ""
      )}start_reg` as keyof DeviceMapProps;
      if (
        device_map.device_map[start_field_name] === void 0 ||
        device_map.device_map[start_field_name] > value
      ) {
        const field_type = name.indexOf("write") >= 0 ? "write" : "read";
        const opp_field_type = field_type === "write" ? "read" : "write";
        const opp_end_name = name.replace(field_type, opp_field_type);
        const opp_start_name = `${opp_end_name.replace(
          "end_reg",
          "start_reg"
        )}`;
        let delta = 0;
        if (
          !(
            device_map.device_map[opp_start_name] === void 0 ||
            device_map.device_map[opp_end_name] === void 0
          )
        ) {
          delta =
            device_map.device_map[opp_end_name] -
            device_map.device_map[opp_start_name];
        }
        dispatch(regChanged({ name: start_field_name, value: value - delta }));
      }
    },
    [device_map.device_map, dispatch]
  );

  return (
    <>
      <SettingsBlock
        field_name={field_name}
        current_device_map={current_device_map}
        firmware={firmware}
        is_start={true}
        regChanged={startRegChanged}
      />
      <SettingsBlock
        field_name={field_name}
        current_device_map={current_device_map}
        firmware={firmware}
        is_start={false}
        regChanged={endRegChanged}
      />
      {["time", "schedule"].indexOf(field_name) < 0 &&
        device_map.settings_initialized(field_name) && (
          <AdminSettingsTable
            field_name={field_name}
            firmware={new FirmwareDecorator(firmware)}
            device_map={current_device_map.device_map}
          />
        )}
    </>
  );
};

export default tabPanel(TabContent);
