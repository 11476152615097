import { deviceMaps } from "./device_maps";
import { firmwares } from "./firmwares";
import { interfaceBlocks } from "./interface_blocks";
import { interfaces } from "./interfaces";
import { users } from "./users";

export const admin = {
  deviceMaps,
  firmwares,
  interfaceBlocks,
  interfaces,
  users,
};
