import React, { FC } from "react";
import BitMaskRow from "./BitMaskRow";

const BitMask: FC<{ mask_rows: number[][] }> = ({ mask_rows }) => (
  <table className="bit-mask">
    <tbody>
      {mask_rows.map((row, i) => (
        <BitMaskRow key={i} bits={row} row_index={i} />
      ))}
    </tbody>
  </table>
);

export default BitMask;
