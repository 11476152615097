import React, { FC, useCallback, useEffect } from "react";
import DeviceRow from "./DeviceRow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import {
  addDeviceRow,
  deviceInterfaceVersionChanged,
  onCellDrop,
  removeDeviceRow,
  requestCreateDeviceInterface,
  requestUpdateDeviceInterface,
} from "../../redux/actions/interface/device_interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { loadInterfaceWithBlocks } from "../../redux/actions/interface/interfaces";
import { LoadingProgress } from "../common/LoadingProgress";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ToolbarBlock from "./ToolbarBlock";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const DeviceEditor: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const navigate = useNavigate();

  const { interfaceId } = useParams();

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadInterfaceWithBlocks(interfaceId));
    }
  }, [dispatch, interfaceId, currentUser.isLoading]);

  const toolbar_blocks = useSelector<BusstationState, any[]>(
    (state) => state.toolbar_blocks
  );

  const device_interface = useSelector<BusstationState, any>(
    (state) => state.device_interface
  );

  useEffect(() => {
    if (device_interface.id > 0 && typeof interfaceId === "undefined")
      navigate(`/interfaces/${device_interface.id}`);
  }, [device_interface, interfaceId, navigate]);

  const levelsChanged = useCallback(
    (e) => {
      const rows = parseInt(e.target.value);
      if (rows === 1) dispatch(removeDeviceRow());
      else dispatch(addDeviceRow());
    },
    [dispatch]
  );

  const versionChanged = useCallback(
    (e) => {
      dispatch(deviceInterfaceVersionChanged(e.target.value));
    },
    [dispatch]
  );

  const handleSave = useCallback(() => {
    if (device_interface.id === void 0 || device_interface.id === null) {
      dispatch(requestCreateDeviceInterface(device_interface));
    } else {
      dispatch(requestUpdateDeviceInterface(device_interface));
    }
  }, [dispatch, device_interface]);

  if (!device_interface) return <LoadingProgress />;

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Блоки интерфейса</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <DndProvider backend={HTML5Backend}>
                <Drawer
                  variant="permanent"
                  container={window.document.body}
                  anchor="right"
                  ModalProps={{
                    keepMounted: true,
                  }}
                  open
                >
                  <List>
                    {toolbar_blocks &&
                      toolbar_blocks.map((block) => (
                        <ListItem key={block.id} disablePadding>
                          <ToolbarBlock key={block.id} block={block} />
                        </ListItem>
                      ))}
                  </List>
                </Drawer>
                <div className="row clearfix">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-5 form-control-label">
                    <label htmlFor="version">Версия</label>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-7">
                    <div className="form-group">
                      <div className="form-line">
                        <input
                          id="version"
                          type="text"
                          value={device_interface.version ?? ""}
                          className="form-control"
                          placeholder="Версия"
                          onChange={versionChanged}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <div className="form-line">
                        <Select
                          className="form-control"
                          value={device_interface.rows.length}
                          onChange={levelsChanged}
                        >
                          <MenuItem value={1}>Один уровень</MenuItem>
                          <MenuItem value={2}>Два уровня</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-4 col-xs-12">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-lg waves-effect"
                        onClick={handleSave}
                      >
                        Сохранить интерфейс
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <table className="device-table">
                      <tbody>
                        {device_interface.rows.map((row, i) => {
                          return (
                            <DeviceRow
                              onCellDrop={(block) =>
                                dispatch(onCellDrop(block))
                              }
                              key={i}
                              row_index={i}
                              row={row}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </DndProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceEditor;
