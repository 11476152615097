import React, { FC, useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers";

export type SettingProps = {
  hour: number;
  minute: number;
  state: string;
  time: any;
  temperature: number;
  input_fan: number;
  output_fan: number;
};

interface DeviceScheduleRowProps {
  setting: SettingProps;
  onChange: (setting: SettingProps) => void;
}

const DeviceScheduleRow: FC<DeviceScheduleRowProps> = ({
  setting,
  onChange,
}) => {
  const valueChanged = useCallback(
    (e) => {
      onChange({ ...setting, [e.target.name]: parseInt(e.target.value) });
    },
    [setting, onChange]
  );

  const stateChanged = useCallback(
    (e) => {
      const state = e.target.value;
      onChange({ ...setting, state });
    },
    [setting, onChange]
  );

  const timeChanged = useCallback(
    (time) => {
      console.log(time);
      const [hour, minute] = [time.hour(), time.minute()];
      const newSetting = { ...setting, hour, minute };
      onChange(newSetting);
    },
    [setting, onChange]
  );

  return (
    <div className="row clearfix">
      <div className="visible-xs col-xs-6">
        <label className="form-label">Состояние</label>
      </div>
      <div className="col-lg-4 col-md-3 col-sm-3 col-xs-6 text-center">
        <FormControl fullWidth>
          <Select value={setting.state} onChange={stateChanged}>
            <MenuItem value="stop">Стоп</MenuItem>
            <MenuItem value="start">Старт</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="visible-xs col-xs-6">
        <label className="form-label">Время</label>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6">
        <FormControl fullWidth>
          <TimePicker
            value={setting.time}
            className="hide-hr"
            onChange={timeChanged}
          />
        </FormControl>
      </div>
      <div className="visible-xs col-xs-6">
        <label className="form-label">Температура</label>
      </div>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 text-center">
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            type="number"
            value={setting.temperature}
            name="temperature"
            onChange={valueChanged}
          />
        </FormControl>
      </div>
      <div className="visible-xs col-xs-6">
        <label className="form-label">Вентилятор притока</label>
      </div>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 text-center">
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            type="number"
            value={setting.input_fan}
            name="input_fan"
            onChange={valueChanged}
          />
        </FormControl>
      </div>
      <div className="visible-xs col-xs-6">
        <label className="form-label">Вентилятор вытяжки</label>
      </div>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-6 text-center">
        <FormControl fullWidth>
          <TextField
            variant="outlined"
            type="number"
            value={setting.output_fan}
            name="output_fan"
            onChange={valueChanged}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default DeviceScheduleRow;
