import React, { FC, useCallback } from "react";
import { toggleBit } from "../../redux/actions/device_map/device_map";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";

const BitMaskCell: FC<{
  row_index: number;
  cell_index: number;
  checked: boolean;
}> = ({ row_index, cell_index, checked }) => {
  const dispatch = useDispatch<DispatchType>();
  const handleToggleBit = useCallback(() => {
    dispatch(
      toggleBit({
        row_index,
        cell_index,
      })
    );
  }, [row_index, cell_index, dispatch]);
  return (
    <td onClick={handleToggleBit}>
      <i className="material-icons">{(checked && "done") || ""}</i>
    </td>
  );
};

export default BitMaskCell;
