import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";
import { FC, useCallback, useEffect } from "react";

const LicenseAgreementDialog: FC<{ acceptLicense: () => void }> = ({
  acceptLicense,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAccept = useCallback(() => {
    acceptLicense();
    handleClose();
  }, [acceptLicense, handleClose]);

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open, descriptionElementRef]);

  return (
    <>
      Я принимаю условия&nbsp;
      <Link href="#" onClick={handleClickOpen}>
        лицензионного соглашения
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ (ПУБЛИЧНАЯ ОФЕРТА)
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p>
              Настоящее Соглашение определяет права и обязанности Лицензиара и
              Лицензиата при использовании Системы ИНТЕХ Диспетчеризация.
            </p>
            <p>
              Настоящее Соглашение адресовано неопределенному кругу лиц и
              является публичной офертой в соответствии с п. 2 ст. 437 ГК РФ.
            </p>
            <p>
              Безусловным акцептом Лицензиатом настоящего Соглашения является
              Регистрация на Сайте путем заполнения Регистрационной формы.
            </p>

            <ol>
              <li>
                ОСНОВНЫЕ ПОНЯТИЯ
                <ol>
                  <li>
                    Система ИНТЕХ Диспетчеризация (далее также «Система») –
                    информационная система, доступная на Сайте Лицензиара,
                    позволяющая Лицензиату осуществлять дистанционное управление
                    и мониторинг вентиляционными агрегатами ИНТЕХ. Система
                    включает в себя интерфейс, программное обеспечение, Сайт и
                    иные элементы, необходимые для надлежащего функционирования.
                    Все права на Систему и входящие в нее элементы принадлежат
                    Лицензиару в полном объеме. Ни одно из положений настоящего
                    Соглашения не может трактоваться как передача (отчуждение)
                    исключительных прав на Систему Лицензиата.
                  </li>
                  <li>
                    Cайт Лицензиара (Сайт) – сайт, расположенный в сети Интернет
                    по адресу{" "}
                    <a href="https://monitoring.intechvent.ru">
                      https://monitoring.intechvent.ru
                    </a>
                    .
                  </li>
                  <li>
                    Лицензиар – ООО «ВЕНТИЛЯЦИОННЫЙ ЗАВОД ИННОВАЦИОННЫХ
                    ТЕХНОЛОГИЙ», являющееся стороной настоящего Соглашения,
                    предоставляющее Лицензиату доступ к Системе.
                  </li>
                  <li>
                    Лицензиат – физическое (юридическое) лицо, индивидуальный
                    предприниматель, акцептовавший настоящее Соглашение.
                    Лицензиар может являться Пользователем либо Наблюдателем в
                    зависимости от выбираемого им функционала Системы и способа
                    Регистрации.
                  </li>
                  <li>
                    Вентиляционные установки ИНТЕХ - принадлежащие Пользователю
                    вентиляционные установки, по отношению к которым ООО
                    «ВЕНТИЛЯЦИОННЫЙ ЗАВОД ИННОВАЦИОННЫХ ТЕХНОЛОГИЙ» является
                    изготовителем.
                  </li>
                  <li>
                    Пользователь – Лицензиат, использующий Систему в целях
                    дистанционного управления и мониторинга вентиляционными
                    установками ИНТЕХ.
                  </li>
                  <li>
                    Наблюдатель – Лицензиат, использующий Систему с согласия
                    Пользователя, в целях дистанционного мониторинга
                    вентиляционных установок ИНТЕХ.
                  </li>
                  <li>
                    Личный кабинет Лицензиата (Личный кабинет)– совокупность
                    защищенных страниц на Сайте, создаваемых при Регистрации
                    Лицензиата, посредством которого осуществляется
                    использование Системы и взаимодействие Сторон. Доступ к
                    Личному кабинету осуществляется Лицензиатом посредством
                    ввода Логина и пароля на Cайте.
                  </li>
                  <li>
                    Регистрация – заполнение Лицензиатом Регистрационной формы
                    путем указания необходимых сведений и выбора Логина и
                    пароля.
                  </li>
                  <li>
                    Регистрационная форма – форма которую Лицензиат должен
                    заполнить и предоставить Лицензиару для прохождения
                    Регистрации на Сайте. Форма направляется Пользователю при
                    проверке его электронной почты и обязательна к заполнению.
                  </li>
                  <li>
                    Законодательство – действующее законодательство Российской
                    Федерации.
                  </li>
                  <li>
                    Соглашение – настоящее Соглашение, включая все приложения и
                    правила, указанные в настоящем Соглашении
                  </li>
                  либо прямо поименованные как приложения к настоящему
                  Соглашению.
                  <li>
                    Неисключительная лицензия (Лицензия) – неисключительное
                    безвозмездное право использования Системы в порядке и
                    способами, указанными в настоящем Соглашении.
                  </li>
                  <li>
                    Идентификационные данные – информация, предназначенная для
                    идентификации Лицензиата в процессе использования Системы. В
                    качестве идентификационных данных выступают
                    идентификационное имя (далее – Логин) и пароль.
                  </li>
                </ol>
              </li>
              <li>
                ПРЕДМЕТ СОГЛАШЕНИЯ
                <ol>
                  <li>
                    В соответствии с условиями настоящего Соглашения Лицензиар
                    предоставляет Лицензиату Неисключительную лицензию, а
                    Лицензиат обязуется принять Лицензию и использовать ее
                    способами, указанными в Соглашении.
                  </li>
                  <li>
                    Все условия, оговоренные далее, относятся как к Системе в
                    целом, так и ко всем ее компонентам в отдельности.
                  </li>
                  <li>Территория передаваемых прав – все страны мира.</li>
                  <li>
                    Срок передаваемых прав ограничен сроком действия настоящего
                    Соглашения.
                  </li>
                  <li>
                    Лицензиар имеет право в любой момент изменять настоящее
                    Соглашение и все приложения к нему (полностью или в части) в
                    одностороннем порядке, без предварительного согласования с
                    Лицензиатом. Все изменения вступают в силу на следующий
                    календарный день после размещения на Сайте.
                  </li>
                  <li>
                    Лицензиат обязуется самостоятельно следить за изменениями
                    настоящего Соглашения и приложений к нему путем
                    периодического ознакомления с актуальной редакцией, но не
                    менее одного раза в месяц. Лицензиат самостоятельно отвечает
                    за любые последствия, наступившие в связи с неознакомлением
                    с Соглашением и приложениями к нему.
                  </li>
                  <li>
                    В случае если Лицензиат не согласен с каким-либо положением
                    Соглашения и (или) приложений к нему, он обязан
                    незамедлительно прекратить использование Системы.
                  </li>
                </ol>
              </li>
              <li>
                РЕГИСТРАЦИЯ
                <ol>
                  <li>
                    Регистрация производится путем заполнения Лицензиатом
                    Регистрационной формы.
                  </li>
                  <li>
                    При Регистрации Лицензиат указывает адрес электронной почты,
                    Логин и пароль, серийные номера вентиляционных установок
                    ИНТЕХ, которые необходимо подключить к Системе, а также иные
                    сведения в соответствии с Регистрационной формой.
                  </li>
                  <li>
                    Регистрация считается оконченной в момент заполнения
                    Регистрационной формы и отправки ее Лицензиару на
                    электронную почту intech_07@mail.ru. По окончании проверки
                    модератором, Лицензиату активируется доступ к личному
                    кабинету в Системе и Регистрация считается оконченной.
                  </li>
                  <li>
                    Лицензиат должен подтвердить адрес электронной почты путем
                    прохождения по ссылке, указанной в письме. Лицензиат
                    самостоятельно несет ответственность за неподтверждение
                    адреса электронной почты.
                  </li>
                  <li>
                    После Регистрации Лицензиату доступен функционал Системы.
                    Лицензиат гарантирует, что данные, указанные при
                    Регистрации, являются актуальными и достоверными.
                  </li>
                  <li>
                    В случае если при Регистрации Лицензиат действует от имени
                    юридического лица (индивидуального предпринимателя), он
                    гарантирует, что имеет соответствующие полномочия на акцепт
                    настоящего Соглашения. Лицензиар в любой момент имеет право
                    запросить у Лицензиата подтверждение полномочий Лицензиата.
                  </li>
                  <li>
                    Лицензиат может изменить или дополнить реквизиты, указанные
                    в Регистрационной форме путем направления письменного
                    заявления Лицензиару на адрес электронной почты
                    intech_07@mail.ru.
                  </li>
                </ol>
              </li>
              <li>
                ПОРЯДОК И СПОСОБЫ ИСПОЛЬЗОВАНИЯ СИСТЕМЫ. ПОРЯДОК ПРЕДОСТАВЛЕНИЯ
                ДОСТУПА К СИСТЕМЕ. ОГРАНИЧЕНИЯ
                <ol>
                  <li>
                    В рамках Неисключительной лицензии Лицензиат вправе
                    использовать Систему следующими способами:
                    <ol>
                      <li>
                        Получение доступа к Системе посредством Сайта
                        Лицензиара.
                      </li>
                      <li>
                        Использование Системы по целевому назначению для
                        собственных нужд Лицензиата, в том числе, но не
                        ограничиваясь:
                        <ul>
                          <li>
                            - дистанционного управления вентиляционными
                            установками ИНТЕХ
                          </li>
                          <li>
                            - дистанционного мониторинга вентиляционными
                            установками ИНТЕХ
                          </li>
                          <li>
                            - дистанционного изменения наименований
                            вентиляционных установок ИНТЕХ
                          </li>
                          <li>- иное.</li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                  <li>
                    При использовании Системы Лицензиату запрещается:
                    <ol>
                      <li>
                        Осуществлять модификацию программного обеспечения,
                        входящего в Систему, в том числе изменять,
                        декомпилировать, дизассемблировать, дешифровать и
                        производить иные действия с объектным кодом программного
                        обеспечения.
                      </li>
                      <li>
                        Использовать Систему способом, прямо не предусмотренным
                        настоящим Соглашением.
                      </li>
                      <li>
                        Распространять, копировать или иным образом осуществлять
                        обнародование программного обеспечения, входящего в
                        Систему.
                      </li>
                      <li>
                        Пытаться обойти технические ограничения в программном
                        обеспечении, входящем в состав Системы.
                      </li>
                      <li>
                        Осуществлять использование Системы или ее частей за
                        пределами срока действия настоящего Соглашения.
                      </li>
                      <li>
                        Использовать Систему для рекламы Лицензиата без
                        письменного согласия Лицензиара.
                      </li>
                      <li>
                        Использовать Систему под товарным знаком Лицензиата, а
                        также представлять Систему как собственность Лицензиата.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Доступ к Системе (Неисключительная лицензия) считается
                    предоставленным с момента окончания Регистрации.
                  </li>
                  <li>
                    Доступ к Системе предоставляется круглосуточно за
                    исключением случаев проведения профилактических работ.
                  </li>
                  <li>
                    Лицензиат не имеет права передавать третьим лицам права на
                    использование Системы.
                  </li>
                  <li>
                    Посредством Системы запрещается использовать (размещать)
                    материалы, Контент, содержащие:
                    <ol>
                      <li>
                        Нецензурные или бранные слова или словосочетания,
                        порнографические изображения и тексты или сцены
                        сексуального характера с участием несовершеннолетних.
                      </li>
                      <li>
                        Угрозы, призывы к насилию и совершению противоправных
                        действий, нарушению Законодательства.
                      </li>
                      <li>
                        Элементы насилия, жестокости, расовой, межнациональной
                        или межрелигиозной розни, а также ссылки на такие
                        материалы.
                      </li>
                      <li>Нацистскую атрибутику или символику.</li>
                      <li>
                        Пропаганду преступной деятельности, руководства по
                        совершению противоправных действий.
                      </li>
                      <li>
                        Материал, содержащий угрозы, который дискредитирует,
                        оскорбляет, порочит честь и достоинство или деловую
                        репутацию или нарушает неприкосновенность частной жизни
                        других Пользователей или третьих лиц.
                      </li>
                      <li>Элементы, нарушающие права третьих лиц.</li>
                    </ol>
                  </li>
                  <li>
                    Лицензиат гарантирует, что посредством Системы осуществляет
                    дистанционное управление и мониторинг вентиляционных
                    установок ИНТЕХ, права на которые принадлежат Лицензиату.
                  </li>
                  <li>
                    Лицензиат гарантирует, что принимает на себя все возможные
                    риски, а также несет всю полноту ответственности за все
                    действия и/или бездействия, совершаемые с использованием
                    Системы.
                  </li>
                </ol>
              </li>
              <li>
                ПРАВА И ОБЯЗАННОСТИ СТОРОН
                <ol>
                  <li>
                    Лицензиар обязан:
                    <ol>
                      <li>Предоставить Лицензиату доступ к Системе.</li>
                      <li>
                        Осуществлять техническую поддержку Лицензиата по
                        вопросам работы с Системой, оперативно устранять
                        неисправности Системы, препятствующие ее использованию.
                      </li>
                      <li>
                        Предпринимать общепринятые технические и организационные
                        меры защиты для обеспечения сохранности и (при
                        необходимости) конфиденциальности информации,
                        передаваемой, получаемой или хранимой Лицензиатом при
                        использовании им Системы. Доступ к информации,
                        передаваемой, получаемой или хранимой Лицензиатом при
                        использовании им Системы, может быть предоставлен иным
                        лицам только в случаях, прямо предусмотренных
                        Законодательством.
                      </li>
                      <li>
                        При обработке персональных данных Лицензиата соблюдать
                        условия обработки персональных данных, установленные
                        Федеральным законом № 152 «О персональных данных».
                      </li>
                    </ol>
                  </li>
                  <li>
                    Лицензиар вправе:
                    <ol>
                      <li>
                        5.Получать лицензионное вознаграждение в рамках
                        настоящего Соглашения.
                      </li>
                      <li>
                        При несоблюдении Лицензиатом обязанностей и (или)
                        ограничений, установленных настоящим Соглашением и (или)
                        Законодательством, приостановить доступ к Системе до
                        устранения нарушения, уведомив об этом Лицензиата. В
                        случае неустранения такого нарушения в течение 1
                        (одного) месяца с даты получения Лицензиатом уведомления
                        Лицензиар вправе расторгнуть настоящее Соглашение в
                        одностороннем порядке.
                      </li>
                      <li>
                        В одностороннем порядке вносить изменения и/или
                        дополнения в условия настоящего Соглашения (приложений к
                        нему).
                      </li>
                      <li>
                        Приостанавливать доступ к Системе в случае проведения
                        профилактических работ, ремонта оборудования и линий
                        связи.
                      </li>
                      <li>
                        Осуществлять проверку достоверности, актуальности и
                        полноты информации, указанной Лицензиатом (в том числе с
                        учетом последующего изменения такой информации). Для
                        целей проверки информации Лицензиар вправе использовать
                        персональные данные, а также иную информацию,
                        передаваемую Лицензиатом в рамках настоящего Соглашения.
                      </li>
                      <li>
                        Использовать наименование Лицензиата, а также его
                        товарные знаки (знаки обслуживания) и логотипы
                        Лицензиата для целей рекламы услуг Лицензиара.
                      </li>
                      <li>
                        Модифицировать или выпускать обновления программного
                        обеспечения и Системы, добавлять новые свойства или
                        функциональные возможности программного обеспечения,
                        повышающие его работоспособность или иным способом
                        улучшающие его характеристики, в том числе в целях
                        удовлетворения требований законодательства Российской
                        Федерации.
                      </li>
                      <li>
                        .В одностороннем порядке расторгнуть настоящее
                        Соглашение и (или) блокировать доступ Лицензиата к
                        Системе. Лицензиар не несет ответственности за убытки
                        (ущерб), возникшие у Лицензиата в связи с расторжением
                        Соглашения и (или) блокировкой.
                      </li>
                      <li>
                        Лицензиар не обязан сообщать Лицензиату причины
                        расторжения Соглашения и (или) блокировки.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Лицензиат обязуется:
                    <ol>
                      <li>
                        Обеспечивать за свой счет наличие доступа к сети
                        Интернет и пользовательского оборудования,
                        соответствующего опубликованным на Сайте требованиям.
                      </li>
                      <li>
                        Не осуществлять с использованием Системы действия,
                        которые могут причинить ущерб или беспокойство иным
                        пользователям услуг, а также любым третьим лицам.
                      </li>
                      <li>
                        Получить согласие субъектов персональных данных на
                        обработку их персональных данных в Системе, а также
                        предоставлять такое согласие по требованию Лицензиара.
                      </li>
                      <li>
                        Предоставлять Лицензиару полную, достоверную и
                        актуальную информацию о себе, поддерживать актуальность
                        и полноту данной информации посредством Личного
                        кабинета.
                      </li>
                      <li>
                        Размещать с помощью Личного кабинета Контент, права на
                        который принадлежат Лицензиату и который не нарушает
                        интеллектуальные права третьих лиц.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Лицензиат вправе:
                    <ol>
                      <li>
                        Пользоваться Неисключительной лицензией в соответствии с
                        настоящим Соглашением, в любых не запрещенных законом
                        целях.
                      </li>
                      <li>
                        Получать Техническую поддержку при использовании
                        Системы.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                ОТВЕТСТВЕННОСТЬ СТОРОН
                <ol>
                  <li>
                    Стороны несут ответственность в соответствии с действующим
                    Законодательством.
                  </li>
                  <li>
                    Система предоставляется Лицензиату «как есть» (as is), в
                    соответствии с общепринятым в международной практике
                    принципом. Это означает, что за проблемы, возникающие в
                    процессе установки, обновления, поддержки и эксплуатации
                    Системы (в т. ч. проблемы совместимости с другими
                    программными продуктами (пакетами, драйверами и др.),
                    несоответствия результатов использования Системы ожиданиям
                    Лицензиата и т. п.), Лицензиар ответственности не несет.
                    Лицензиат должен понимать, что несет полную ответственность
                    за возможные негативные последствия, вызванные
                    несовместимостью или конфликтами Системы с другими
                    программными продуктами, установленными на компьютере или
                    ином устройстве Лицензиата. Система не предназначена и не
                    может быть использована в информационных системах,
                    работающих в опасных средах либо обслуживающих системы
                    жизнеобеспечения, в которых сбой в работе Системы может
                    создать угрозу жизни людей или повлечь большие материальные
                    убытки.
                  </li>
                  <li>
                    Лицензиар не несет ответственности за невозможность
                    использования Системы по причинам, зависящим от Лицензиата
                    или третьих лиц.
                  </li>
                  <li>
                    Лицензиар не несет ответственности в случае, если доступ к
                    Системе ограничен в связи с отсутствием у Лицензиата доступа
                    к сети Интернет.
                  </li>
                  <li>
                    Лицензиар прилагает все возможные усилия для обеспечения
                    нормальной работоспособности Системы и Сайта, однако не
                    несет ответственности за неисполнение или ненадлежащее
                    исполнение обязательств по настоящему Соглашению, а также за
                    прямые и косвенные убытки Лицензиата, включая упущенную
                    выгоду и возможный ущерб, возникший в том числе, но не
                    ограничиваясь, в результате:
                    <ol>
                      <li>
                        Неправомерных действий пользователей сети Интернет,
                        направленных на нарушение информационной безопасности
                        или нормального функционирования Сайта и Системы.
                      </li>
                      <li>
                        Отсутствия (невозможности установления, прекращения и
                        пр.) интернет-соединения между сервером Лицензиата и
                        сервером Лицензиара.
                      </li>
                      <li>
                        Проведения государственными и муниципальными органами, а
                        также иными организациями оперативно-розыскных
                        мероприятий.
                      </li>
                      <li>
                        Установления государственного регулирования (или
                        регулирования иными организациями) хозяйственной
                        деятельности коммерческих организаций в сети Интернет
                        и/или установления указанными субъектами разовых
                        ограничений, затрудняющих или делающих невозможным
                        исполнение настоящего Лицензионного соглашения.
                      </li>
                      <li>
                        Случаев, связанных с действиями (бездействием)
                        пользователей сети Интернет и/или других субъектов,
                        направленными на ухудшение общей ситуации с
                        использованием сети Интернет и/или компьютерного
                        оборудования, существовавшей на момент заключения
                        настоящего Лицензионного соглашения.
                      </li>
                      <li>Любых иных случаях.</li>
                    </ol>
                  </li>
                  <li>
                    Лицензиар оставляет за собой право приостанавливать работу
                    Сайта и Системы для проведения профилактических работ, по
                    возможности – в ночное время или выходные дни.
                  </li>
                  <li>
                    Лицензиат обязуется при использовании Системы не нарушать
                    Законодательство, интересы любых иных третьих лиц. Лицензиар
                    не несет ответственность за все действия и/или бездействия
                    Лицензиата, совершаемые с использованием Системы, а равно не
                    несет ответственность за невыполнение Лицензиатом
                    обязательств перед третьими лицами.
                  </li>
                  <li>
                    Лицензиат самостоятельно несет ответственность за
                    сохранность своего Логина и пароля и за убытки, которые
                    могут возникнуть по причине их несанкционированного
                    использования.
                  </li>
                  <li>
                    Лицензиар не несет ответственность, если Логин и пароль от
                    Личного кабинета Лицензиата стали известны третьим лицам.
                  </li>
                  <li>
                    Лицензиата несет всю ответственность за все действия и/или
                    бездействия, совершаемые с использованием Системы, при любых
                    обстоятельствах ответственность Лицензиара перед Лицензиатом
                    и/или любыми третьими лицами отсутствует.
                  </li>
                  <li>
                    В случае возникновения между Лицензиатом и Лицензиаром
                    споров или разногласий, вытекающих из настоящего
                    Лицензионного соглашения или связанных с ним, стороны примут
                    все меры к их разрешению путем переговоров между собой.
                  </li>
                  <li>
                    В случае если не удается разрешить возникшие между сторонами
                    споры и/или разногласия путем переговоров, такие споры
                    разрешаются в Арбитражном суде Челябинской области либо в
                    суде общей юрисдикции по месту нахождения Лицензиара.
                  </li>
                  <li>
                    Лицензиар не несет ответственность за Контент, размещаемый
                    Лицензиатом в Системе. В случае предъявления претензий
                    Лицензиару со стороны третьих лиц о нарушении исключительных
                    прав на Контент, размещенный Лицензиатом в Системе,
                    Лицензиат обязан оплатить штраф Лицензиару в размере 1 000
                    000 (один миллион) рублей.
                  </li>
                  <li>
                    Ни одна из Сторон не несет ответственности за неисполнение
                    или ненадлежащее исполнение обязательств по настоящему
                    Соглашению, если надлежащее исполнение оказалось невозможным
                    вследствие непреодолимой силы, то есть чрезвычайных и
                    непредотвратимых при данных условиях обстоятельств:
                    стихийного бедствия, военных действий, массовых беспорядков,
                    пожара, землетрясения и т. п., а также в результате решений
                    и действий (бездействия) органов государственной власти,
                    неправомерных действий третьих лиц.
                  </li>
                  <li>
                    В случае если в течение <strong>30 (тридцати)</strong> дней
                    данные обстоятельства не прекращаются, каждая из Сторон
                    вправе в одностороннем порядке расторгнуть настоящее
                    Соглашение без возмещения другой Стороне убытков, связанных
                    с расторжением настоящего Соглашения.
                  </li>
                </ol>
              </li>
              <li>
                ПЕРСОНАЛЬНЫЕ ДАННЫЕ. ОБЕСПЕЧЕНИЕ КОНФИДЕНЦИАЛЬНОСТИ
                <ol>
                  <li>
                    Лицензиат дает согласие на обработку Лицензиаром
                    персональных данных Лицензиата, указываемых при Регистрации,
                    в том числе: фамилия, имя, отчество, адрес электронной
                    почты, номер контактного телефона, реквизиты юридического
                    лица.
                    <ol>
                      <li>
                        Настоящее согласие действует бессрочно, срок хранения
                        персональных данных Лицензиата не ограничен.
                      </li>
                      <li>
                        При обработке персональных данных Лицензиата Лицензиар
                        руководствуется Федеральным законом РФ «О персональных
                        данных» № 152-ФЗ от 29.07.2006.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Лицензиат имеет право отозвать свое согласие посредством
                    составления соответствующего письменного документа, который
                    может быть направлен Лицензиатом в адрес Лицензиара по почте
                    заказным письмом с уведомлением о вручении либо вручен лично
                    под расписку представителю Лицензиара. В случае получения
                    письменного заявления об отзыве настоящего согласия на
                    обработку персональных данных Лицензиар обязан прекратить их
                    обработку. В указанном случае настоящее Соглашение считается
                    расторгнутым, а доступ к Системе прекращается.
                  </li>
                  <li>
                    Лицензиат осознает и дает согласие, что в результате сбоя,
                    технических неисправностей, действий третьих лиц, в том
                    числе (но не ограничиваясь) вирусной или хакерской атаки,
                    данные Лицензиата, размещенные в Системе, могут стать
                    доступны третьим лицам. Лицензиат обязуется не предъявлять
                    требований к Лицензиару о возмещении убытков (ущерба),
                    возникших в связи с этим.
                  </li>
                  <li>
                    Лицензиат дает согласие на:
                    <ol>
                      <li>
                        Хранение персональных данных на сервере Лицензиара (в
                        том числе вне территории Российской Федерации).
                      </li>
                      <li>
                        Использование персональных и статистических данных
                        Лицензиата для демонстрации рекламных объявлений.
                      </li>
                      <li>
                        Направления Лицензиату информации об услугах, новостях
                        Лицензиара и (или) партнеров Лицензиара.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Лицензиат имеет право в любой момент изменить данные,
                    указанные при Регистрации, используя Личный кабинет.
                    <ol>
                      <li>
                        Лицензиар обрабатывает только данные Лицензиата, которые
                        необходимы для исполнения настоящего Соглашения.
                      </li>
                      <li>
                        Лицензиат обязуется предварительно получать согласие
                        субъекта персональных данных на использование
                        персональных данных посредством Системы. Лицензиат
                        обязуется не размещать персональные данные третьих лиц
                        либо сотрудников Лицензиата, не давших согласия на такое
                        размещение.
                      </li>
                      <li>
                        Лицензиар обязуется приложить все усилия для обеспечения
                        конфиденциальности данных, размещенных Лицензиатом
                        посредством Системы, на весь период их нахождения на
                        сервере Лицензиара, кроме тех данных, которые
                        расположены в общем доступе Системы.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                ИНФОРМАЦИОННОЕ ВЗАИМОДЕЙСТВИЕ МЕЖДУ СТОРОНАМИ
                <ol>
                  <li>
                    Обмен документами между Сторонами осуществляется посредством
                    Личного кабинета, а также электронной почты.
                  </li>
                  <li>
                    Электронные сообщения, отправленные посредством Личного
                    кабинета, или электронной почты являются электронными
                    документами, равнозначными документам на бумажном носителе,
                    подписанным собственноручной подписью Лицензиата (при
                    отправке электронного сообщения Лицензиатом) или
                    уполномоченного лица Лицензиара (при отправке личного
                    сообщения от имени Лицензиара).
                  </li>
                  <li>
                    Любые файлы, вложенные в электронное сообщение
                    (attachments), являются неотъемлемой частью данного
                    сообщения.
                  </li>
                  <li>
                    Лицензиар имеет право отправлять Лицензиату новостные письма
                    на адрес электронной почты, указанный Лицензиатом при
                    регистрации.
                  </li>
                </ol>
              </li>
              <li>
                СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ И ПОРЯДОК ЕГО РАСТОРЖЕНИЯ
                <ol>
                  <li>
                    Настоящее Соглашение вступает в силу с даты акцепта и
                    действует до расторжения Сторонами.
                  </li>
                  <li>
                    Настоящее Соглашение является офертой, и в силу действующего
                    гражданского законодательства РФ Лицензиар имеет право на
                    отзыв оферты в соответствии со ст. 436 ГК РФ. В случае
                    отзыва настоящего Соглашения Лицензиаром в течение срока его
                    действия настоящее Соглашение считается прекращенным с
                    момента отзыва. Отзыв осуществляется путем размещения
                    соответствующей информации на Сайте.
                  </li>
                  <li>
                    Настоящее Соглашение может быть расторгнуто досрочно:
                    <ol>
                      <li>По взаимному соглашению Лицензиара и Лицензиата.</li>
                      <li>
                        По инициативе Лицензиара в одностороннем внесудебном
                        порядке в случае нарушения Лицензиатом условий
                        настоящего Лицензионного соглашения.
                      </li>
                      <li>
                        По инициативе Лицензиара в одностороннем внесудебном
                        порядке в случае нарушения Лицензиатом авторского права
                        третьих лиц.
                      </li>
                      <li>
                        По инициативе Лицензиара в одностороннем порядке в
                        случае неиспользования Лицензиатом Системы в течение 30
                        (тридцати) календарных дней подряд.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                ПРОЧИЕ УСЛОВИЯ
                <ol>
                  <li>
                    Действие Неисключительной лицензии по настоящему Соглашению
                    распространяется на все последующие обновления / новые
                    версии Системы.
                  </li>
                  <li>
                    Принимая условия настоящего Лицензионного соглашения,
                    Лицензиат подтверждает наличие у него законных оснований для
                    обработки принадлежащей ему информации с использованием
                    Системы.
                  </li>
                  <li>
                    Все вопросы, не урегулированные настоящим Соглашением,
                    подлежат разрешению в соответствии с Законодательством.
                  </li>
                </ol>
              </li>
              <li>
                РЕКВИЗИТЫ ЛИЦЕНЗИАРА
                <p />
                <p>ООО «ВЕНТИЛЯЦИОННЫЙ ЗАВОД ИННОВАЦИОННЫХ ТЕХНОЛОГИЙ»</p>
                <p>ИНН/КПП - 7453264491 / 745301001</p>
                <p>ОГРН - 1147453000606</p>
                <p>Расчётный счёт - 40702810990490018665</p>
                <p>Наименование банка - ОАО «ЧЕЛЯБИНВЕСТБАНК»</p>
                <p>Корреспондентский счёт - 30101810400000000779</p>
                <p>БИК - 047501779</p>
                <p>Генеральный директор - Штоль Дмитрий Сергеевич</p>
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button onClick={handleAccept} color="primary">
            Принять
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LicenseAgreementDialog;
