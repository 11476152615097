import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { DispatchType } from "../../redux/store/type";
import { useNavigate, useParams } from "react-router-dom";
import { requestResetPassword } from "../../redux/actions/profile/profile_actions";

const theme = createTheme();

const validate = ({
  password,
  password_confirmation,
  reset_password_token,
}) => {
  const errors: { [name: string]: string } = {};
  if (password.length === 0 || password !== password_confirmation) {
    errors.password =
      password.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (
    password_confirmation.length === 0 ||
    password_confirmation !== password_confirmation
  ) {
    errors.password_confirmation =
      password_confirmation.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (!reset_password_token || reset_password_token.length === 0) {
    errors.reset_password_token = "Не может быть пустым";
  }
  return errors;
};

function EditPassword() {
  const dispatch = useDispatch<DispatchType>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<{
    [name: string]: string;
  }>({});

  const { reset_password_token } = useParams();

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const password = data.get("password") as string;
      const password_confirmation = data.get("password_confirmation") as string;
      const errors = validate({
        password,
        password_confirmation,
        reset_password_token,
      });
      setErrors(errors);
      const valid = Object.keys(errors).length === 0;
      if (valid) {
        await dispatch(
          requestResetPassword({
            password,
            password_confirmation,
            reset_password_token,
          })
        ).unwrap();
        navigate("/users/sign_in");
      }
    },
    [dispatch, reset_password_token, navigate]
  );

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#52A3ED" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            component="h4"
          >
            Введите новый пароль и подтверждение
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <TextField
              margin="normal"
              error={!!errors.password}
              helperText={errors.password}
              required
              fullWidth
              name="password"
              label="Пароль"
              type="password"
              id="password"
              autoComplete="new-password"
            />
            <TextField
              margin="normal"
              error={!!errors.password_confirmation}
              helperText={errors.password_confirmation}
              required
              fullWidth
              name="password_confirmation"
              label="Подтверждение пароля"
              type="password"
              id="password_confirmation"
              autoComplete="new-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Изменить пароль!
            </Button>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Link href="/users/sign_in" variant="body2">
                  Вход
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default EditPassword;
