import { createReducer } from "@reduxjs/toolkit";
import {
  addInterface,
  loadInterfaceList,
  requestRemoveInterface,
} from "../actions/interface/interfaces";

export default createReducer([], (builder) => {
  builder.addCase(
    loadInterfaceList.fulfilled,
    (state, action) => action.payload
  );
  builder.addCase(addInterface, (state, action) => [...state, action.payload]);
  builder.addCase(requestRemoveInterface.fulfilled, (state, action) => {
    const idx = state.findIndex((i) => i.id === action.payload.id);
    if (idx >= 0) {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    } else {
      return state;
    }
  });
});
