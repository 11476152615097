import React, { FC } from "react";

const TabPanel = (WrappedComponent) => {
  const Panel: FC<any> = (props: any) => {
    return (
      <div
        role="tabpanel"
        className={`tab-pane fade${
          props.active_pane === props.field_name ? " active in" : ""
        }`}
        id={props.field_name}
      >
        <WrappedComponent {...props} />
      </div>
    );
  };
  return Panel;
};

export default TabPanel;
