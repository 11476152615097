import { Moment } from "moment";

export const deviceHours = (code: number) => code & 0x1f;
export const deviceMinutes = (code: number) => (code & 0x7e0) >> 5;

// export const deviceDayOfWeek = (code: number) => (code & 0x3800) >> 11;

export const deviceDay = (code: number) => (code & 0x7c000) >> 14;

export const deviceMonth = (code: number) => (code & 0x780000) >> 19;

export const deviceYear = (code: number) => (code & 0xff800000) >> 23;

export const fromDate = (datetime: Moment) => {
  console.log(
    `hours: ${datetime.hour()}, minutes: ${datetime.minute()}, day: ${datetime.day()}, date: ${datetime.date()}, month: ${
      datetime.month() + 1
    }, year: ${datetime.year()}`
  );
  return (
    datetime.hours() |
    (datetime.minutes() << 5) |
    (datetime.day() << 11) |
    (datetime.date() << 14) |
    ((datetime.month() + 1) << 19) |
    ((datetime.year() - 2000) << 23)
  );
};
