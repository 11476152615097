import React, { FC, useCallback, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { alarmMessages } from "../helpers/device_state_helper";

import { stateLabel } from "../helpers/device_state_helper";
import {
  reconnectPanelRequest,
  destroyPanelRequest,
  loadPanels,
} from "../../redux/actions/panels/panel";
import { deviceAlarms, deviceState, getUint, getUshort } from "../../common";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { useDispatch, useSelector } from "react-redux";
import { PanelInterface } from "../../dto";
import { Link } from "react-router-dom";
import { LoadingProgress } from "../common/LoadingProgress";
import Box from "@mui/material/Box";

const PanelLink: FC<{ device: any; currentUser: any }> = ({
  device,
  currentUser,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: device.online ? "lightgreen" : "",
        borderRadius: "2px",
        textAlign: "center",
      }}
    >
      <Link
        style={{ color: device.online ? "white" : "black" }}
        to={`panels/${device.id}`}
      >
        {device.serial}
      </Link>
      <ReconnectButton {...device} />
      {currentUser.attributes.role === "admin" && (
        <RemovePanelButton {...device} />
      )}
    </div>
  );
};

const AlarmCell: FC<{ value: string }> = ({ value }) => {
  return value.length > 0 ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "2px",
        textAlign: "center",
        color: "white",
        backgroundColor: "red",
      }}
    >
      {value}
    </div>
  ) : (
    <span />
  );
};

const UserLink: FC<{ id: number; first_name: string; last_name: string }> = ({
  id,
  last_name,
  first_name,
}) => {
  return (
    <Link to={`admin/users/${id}/panels`}>{`${first_name} ${last_name}`}</Link>
  );
};

const UsersCell: ({ users }: { users: any }) => any = ({ users }) => {
  return users.map((user) => <UserLink key={user.id} {...user} />);
};

const ReconnectButton: FC<PanelInterface> = (panel) => {
  const dispatch = useDispatch<DispatchType>();
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(reconnectPanelRequest(panel));
    },
    [panel, dispatch]
  );

  return (
    <a
      href="#"
      style={{ verticalAlign: "middle", marginLeft: "0.5em" }}
      title="Обновить подключение"
      onClick={onClick}
    >
      <i className="material-icons">cached</i>
    </a>
  );
};

const RemovePanelButton: FC<PanelInterface> = (panel) => {
  const dispatch = useDispatch<DispatchType>();

  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      if (
        confirm(
          "Вы уверены, что хотите удалить панель и историю ее сообщений? Это действие нельзя отменить."
        )
      ) {
        dispatch(destroyPanelRequest(panel));
      }
    },
    [panel, dispatch]
  );

  return (
    <a
      href="#"
      title="Удалить панель"
      style={{ verticalAlign: "middle", marginLeft: "0.5em" }}
      onClick={onClick}
    >
      <i className="material-icons">delete_forever</i>
    </a>
  );
};

const PanelTable: FC = () => {
  const dispatch = useDispatch<DispatchType>();

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const isLoading = useSelector<BusstationState, boolean>(
    (state) => state.isLoading
  );

  const devices = useSelector<BusstationState, PanelInterface[]>(
    (state) => state.devices
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadPanels());
    }
  }, [dispatch, currentUser.isLoading]);

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="block-header">
          <h2>Панели</h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="body">
                {currentUser.isLoading || isLoading ? (
                  <LoadingProgress />
                ) : devices.length === 0 ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    Нет панелей в списке
                  </Box>
                ) : (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Название</TableCell>
                          <TableCell align="center">Серийный номер</TableCell>
                          <TableCell align="center">Состояние</TableCell>
                          <TableCell align="center">Авария</TableCell>
                          {currentUser.attributes.role !== "engineer" && [
                            <TableCell key="users" align="center">
                              Пользователи
                            </TableCell>,
                            <TableCell key="supervisors" align="center">
                              Наблюдатели
                            </TableCell>,
                          ]}
                        </TableRow>
                      </TableHead>
                      {
                        <TableBody>
                          {devices.map((device) => {
                            const code = getUshort(device.payload, 0);
                            const state = deviceState(code);
                            const alarmCode = getUint(device.payload, 1);
                            const alarms = deviceAlarms(alarmCode);
                            return (
                              <TableRow
                                key={device.serial}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="center">
                                  {device.label}
                                </TableCell>
                                <TableCell align="center">
                                  <PanelLink
                                    device={device}
                                    currentUser={currentUser}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {stateLabel(state)}
                                </TableCell>
                                <TableCell align="center">
                                  <AlarmCell value={alarmMessages(alarms)} />
                                </TableCell>
                                {currentUser.attributes.role !== "engineer" && [
                                  <TableCell key="usersCell" align="center">
                                    <UsersCell users={device.users} />
                                  </TableCell>,
                                  <TableCell
                                    key="supervisorsCell"
                                    align="center"
                                  >
                                    {device.supervisors
                                      .map((s) => s.name)
                                      .join(", ")}
                                  </TableCell>,
                                ]}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      }
                    </Table>
                  </TableContainer>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PanelTable;
