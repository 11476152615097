import React, { FC } from "react";

const RegValueTableHeader: FC = () => (
  <div className="row clearfix">
    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-center">
      <label>Внутреннее значение</label>
    </div>
    <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5 text-center">
      <label>Выводимое значение</label>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"></div>
  </div>
);

export default RegValueTableHeader;
