import React, { FC } from "react";

const RegValueRow: FC<{
  id: number;
  internal_value: number;
  interface_value: string;
  internalValueChanged: (value: number, id: number) => void;
  interfaceValueChanged: (value: string, id: number) => void;
  removeRegPreset: (id: number) => void;
}> = ({
  id,
  interface_value,
  internal_value,
  internalValueChanged,
  interfaceValueChanged,
  removeRegPreset,
}) => {
  const handleInternalValueChanged = (e) => {
    e.preventDefault();
    internalValueChanged(parseInt(e.target.value), id);
  };

  const handleInterfaceValueChanged = (e) => {
    e.preventDefault();
    interfaceValueChanged(e.target.value, id);
  };

  const handleRemoveRegPreset = (e) => {
    e.preventDefault();
    removeRegPreset(id);
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
        <input
          type="number"
          onChange={handleInternalValueChanged}
          value={internal_value}
          className="form-control"
        />
      </div>
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
        <input
          type="text"
          onChange={handleInterfaceValueChanged}
          value={interface_value}
          className="form-control"
        />
      </div>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <a
          href="#"
          className="btn btn-default"
          title="Удалить значение"
          onClick={handleRemoveRegPreset}
        >
          <i className="material-icons">delete_forever</i>
        </a>
      </div>
    </div>
  );
};

export default RegValueRow;
