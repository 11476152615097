import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { BlockProps } from "../../dto/device-map";
import useMediaQuery from "@mui/material/useMediaQuery";

interface PanelCellProps {
  block: BlockProps;
  one_line: boolean;
  row_index: number;
  rightPadding?: boolean;
  leftPadding?: boolean;
}

const BinaryBackground: FC<BlockProps> = ({
  id,
  backgrounds,
  state,
  units,
  inner,
}) => {
  return (
    <img
      src={
        backgrounds.find((bb) => bb.condition === (state !== 0 ? "on" : "off"))
          ?.url
      }
      className={`${units ? "two-units-img" : "one-unit-img"}${
        inner ? " arrow" : ""
      }`}
      alt={id.toString()}
    />
  );
};

const StepBackground: FC<BlockProps> = ({
  id,
  backgrounds,
  state,
  units,
  inner,
}) => {
  const steps = useMemo(
    () => backgrounds.map(({ value }) => value),
    [backgrounds]
  );

  const diffs = useMemo(() => {
    const val = typeof state === "string" ? parseFloat(state) : state;
    return steps.map((s) => Math.abs(s - val));
  }, [steps, state]);

  const stepIdx = useMemo(() => diffs.lastIndexOf(Math.min(...diffs)), [diffs]);

  return (
    <img
      src={backgrounds.find((bb) => bb.value === steps[stepIdx])?.url}
      className={`${units ? "two-units-img" : "one-unit-img"}${
        inner ? " arrow" : ""
      }`}
      alt={id.toString()}
    />
  );
};

const PanelCell: FC<PanelCellProps> = ({
  block,
  one_line,
  row_index,
  leftPadding,
  rightPadding,
}) => {
  const val = useRef<HTMLDivElement | null>(null);
  const secondVal = useRef<HTMLDivElement | null>(null);

  const measUnits = useCallback((units) => {
    if (units === void 0 || units === null) return "";
    switch (parseInt(units)) {
      case 1:
        return "%";
      case 2:
        return "&#8451;";
      default:
        return "";
    }
  }, []);

  const valueWithUnits = useCallback(
    (value, units) => {
      return `${value}${measUnits(units)}`;
    },
    [measUnits]
  );

  const narrowScreen = useMediaQuery("(max-width:767px)");

  const placeLabels = useCallback(() => {
    const height = block.inner
      ? narrowScreen
        ? 33
        : 66
      : block.units
      ? narrowScreen
        ? 76
        : 152
      : narrowScreen
      ? 38
      : 76;
    if (one_line || row_index > 0) {
      val.current.style.top = `${height}px`;
      if (secondVal.current)
        secondVal.current.style.top = `${height + val.current.clientHeight}px`;
    } else {
      val.current.style.bottom = `${height}px`;
      if (secondVal.current) {
        if (block.units) {
          secondVal.current.style.top = `${height}px`;
        } else {
          secondVal.current.style.bottom = `${
            height + val.current.clientHeight
          }px`;
        }
      }
    }
  }, [
    block.inner,
    block.units,
    row_index,
    one_line,
    val,
    secondVal,
    narrowScreen,
  ]);

  useEffect(() => {
    if (val.current && (!block.two_values || secondVal.current)) {
      placeLabels();
      window.addEventListener("resize", placeLabels);
    }
    return () => window.removeEventListener("resize", placeLabels);
  }, [block.two_values, placeLabels]);

  return (
    <td
      rowSpan={block.units ? 2 : 1}
      className={
        rightPadding || leftPadding
          ? rightPadding
            ? "right-padded"
            : "left-padded"
          : ""
      }
    >
      {(block.binary && <BinaryBackground {...block} />) || (
        <StepBackground {...block} />
      )}
      {block.display_value && (
        <div
          ref={val}
          className="align-center"
          style={{ position: "absolute", width: "100%", zIndex: 10 }}
          dangerouslySetInnerHTML={{
            __html: valueWithUnits(
              block.two_values
                ? block.state
                : block.value === void 0 || block.value === null
                ? block.state
                : block.value,
              block.meas_units
            ),
          }}
        />
      )}
      {block.display_value && block.two_values && (
        <div
          ref={secondVal}
          className="align-center"
          style={{ position: "absolute", width: "100%", zIndex: 10 }}
          dangerouslySetInnerHTML={{
            __html: valueWithUnits(
              block.value,
              block.two_values ? block.second_meas_units : block.meas_units
            ),
          }}
        />
      )}
    </td>
  );
};

export default PanelCell;
