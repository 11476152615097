import { createReducer } from "@reduxjs/toolkit";
import {
  loadDeviceMaps,
  requestRemoveDeviceMap,
} from "../actions/device_map/device_maps_list";

export default createReducer<any[]>([], (builder) => {
  builder.addCase(loadDeviceMaps.fulfilled, (state, action) => action.payload);
  builder.addCase(requestRemoveDeviceMap.pending, (state, action) => {
    const idx = state.findIndex((m) => m.id === action.meta.arg.id);
    if (idx >= 0) {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    } else return state;
  });
});
