import React, { FC, MouseEventHandler, useCallback } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const BlockControls: FC<{
  id: number | string;
  value: string | number;
  condition: string;
  handleInputChange: (value: string | number, name: string) => void;
  save: MouseEventHandler<HTMLButtonElement>;
  remove: MouseEventHandler<HTMLButtonElement>;
  background: any;
}> = ({
  id,
  value,
  condition,
  handleInputChange,
  save,
  remove,
  background,
}) => {
  const conditionChanged = useCallback(
    (e) => {
      handleInputChange(e.target.value, e.target.name);
    },
    [handleInputChange]
  );

  const valueChanged = useCallback(
    (e) => {
      const val =
        e.target.value.length > 0 ? parseInt(e.target.value) : e.target.value;
      handleInputChange(val, e.target.name);
    },
    [handleInputChange]
  );

  return (
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="row clearfix">
            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-5 form-control-label">
              <label htmlFor="condition">Условие</label>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-8 col-xs-7 zero-mb">
              <div className="form-group zero-mb">
                <div className="form-line">
                  <Select
                    id="condition"
                    name="condition"
                    value={condition}
                    onChange={conditionChanged}
                    className="form-control show-tick"
                  >
                    <MenuItem value="">-- Выберите --</MenuItem>
                    <MenuItem value="on">Включено</MenuItem>
                    <MenuItem value="off">Выключено</MenuItem>
                    <MenuItem value="step">Шаг</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-5 form-control-label">
              <label htmlFor="condition">Значение</label>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-8 col-xs-7 zero-mb">
              <div className="form-group zero-mb">
                <div className="form-line">
                  <input
                    name="value"
                    type="numeric"
                    value={value}
                    onChange={valueChanged}
                    className="form-control"
                    disabled={condition !== "step"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <button
              type="button"
              className="btn btn-primary btn-lg m-l-15 waves-effect"
              onClick={save}
              disabled={
                condition === void 0 ||
                condition.length === 0 ||
                background === void 0
              }
            >
              Сохранить
            </button>
            {id && (
              <button
                type="button"
                className="btn btn-danger btn-lg m-l-15 waves-effect"
                onClick={remove}
              >
                Удалить
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockControls;
