import LeftSideBar from "../left_side_bar/LeftSideBar";
import UserEventFeed from "../panels/UserEventFeed";
import { Outlet } from "react-router-dom";
import React, { useCallback, useRef } from "react";
import Logo from "images/logo-bg-w-bg.png";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { setIsMenuOpen } from "../../redux/actions/menu";
import useMediaQuery from "@mui/material/useMediaQuery";

export const App = () => {
  const menuRef = useRef<HTMLElement | null>(null);
  const bodyRef = useRef<HTMLBodyElement | null>(null);

  const matches = useMediaQuery("(min-width:1170px)");

  const isMenuOpen = useSelector<BusstationState, boolean>(
    (state) => state.isMenuOpen
  );

  const dispatch = useDispatch<DispatchType>();

  const toggle = useCallback(() => {
    dispatch(setIsMenuOpen(!isMenuOpen));
  }, [dispatch, isMenuOpen]);

  return (
    <body
      ref={bodyRef}
      className={`theme-blue${isMenuOpen ? " overlay-open" : ""}${
        matches ? "" : " ls-closed"
      }`}
      data-perforator="1"
    >
      <div className={`overlay${isMenuOpen ? " fade-in" : " fade-out"}`}></div>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-header">
            {/*<a*/}
            {/*  href="#"*/}
            {/*  className="navbar-toggle collapsed"*/}
            {/*  data-toggle="collapse"*/}
            {/*  data-target="#navbar-collapse"*/}
            {/*  aria-expanded="false"*/}
            {/*></a>*/}
            <a
              href="#"
              className="bars"
              style={{
                display: matches ? "none" : "block",
                opacity: matches ? 0 : 1,
                transition: "opacity: 2s",
              }}
              onClick={(e) => {
                e.preventDefault();
                toggle();
              }}
            ></a>
            <a className="navbar-brand" href="//intechvent.ru">
              <img src={Logo} alt="Logo" />
              <span className="hidden-xs">Диспетчеризация</span>
            </a>
          </div>
        </div>
      </nav>
      <LeftSideBar ref={menuRef} />
      <section className="content">
        <UserEventFeed />
        <Outlet />
      </section>
    </body>
  );
};
