import React, { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  blockMeasUnitsChanged,
  blockRegisterChanged,
  blockSecondMeasUnitsChanged,
  blockValueRegisterChanged,
  toggleValuesNumber,
} from "../../redux/actions/device_map/device_map";
import { BusstationState, DispatchType } from "../../redux/store/type";

const BlockRow: FC<{
  register: number;
  label: string;
  current_firmware?: any;
  registerChanged: (value: number) => void;
}> = ({ register, label, registerChanged, current_firmware }) => {
  const handleRegisterChanged = (e) => {
    registerChanged(parseInt(e.target.value));
  };

  return (
    <tr>
      <td>{label}</td>
      <td>
        <select
          value={typeof register === "undefined" ? 0 : register}
          onChange={handleRegisterChanged}
          className="form-control"
        >
          {current_firmware &&
            current_firmware.registers.map((register) => {
              return (
                <option
                  key={register.address}
                  value={register.address}
                >{`${register.address}: ${register.description}`}</option>
              );
            })}
        </select>
      </td>
    </tr>
  );
};

const BlockMapping: FC = () => {
  const dispatch = useDispatch<DispatchType>();

  const { current_block: block } = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );

  const firmware = useSelector<BusstationState, any>((state) => state.firmware);

  const handleBlockRegisterChanged = (register) => {
    dispatch(blockRegisterChanged(register));
  };

  const handleBlockValueRegisterChanged = (register) => {
    dispatch(blockValueRegisterChanged(register));
  };

  // const handleBlockSecondValueRegisterChanged = (register) => {
  //   dispatch(blockSecondValueRegisterChanged(register));
  // };

  const handleBlockUnitsChanged = (e) => {
    dispatch(blockMeasUnitsChanged(e.target.value));
  };

  const handleBlockSecondUnitsChanged = (e) => {
    dispatch(blockSecondMeasUnitsChanged(e.target.value));
  };

  const handleToggleValuesNumber = (e) => {
    dispatch(toggleValuesNumber(e.target.checked));
  };

  return (
    <table className="table">
      <tbody>
        {block && [
          <BlockRow
            key={1}
            label="Состояние"
            register={block.register}
            current_firmware={firmware}
            registerChanged={handleBlockRegisterChanged}
          />,
          <BlockRow
            key={2}
            label="Значение из"
            register={block.value_register}
            current_firmware={firmware}
            registerChanged={handleBlockValueRegisterChanged}
          />,
        ]}
        {block && (
          <tr>
            <td>Единицы измерения</td>
            <td>
              <select
                className="form-control"
                value={block?.meas_units || 0}
                onChange={handleBlockUnitsChanged}
              >
                <option value={0}>Нет</option>
                <option value={1}>%</option>
                <option value={2}>&#8451;</option>
              </select>
              <select
                disabled={!block.two_values}
                className="form-control"
                value={block?.second_meas_units || 0}
                onChange={handleBlockSecondUnitsChanged}
              >
                <option value={0}>Нет</option>
                <option value={1}>%</option>
                <option value={2}>&#8451;</option>
              </select>
            </td>
          </tr>
        )}
        {block && (
          <tr>
            <td colSpan={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="two_values"
                    checked={block && block.two_values}
                    onChange={handleToggleValuesNumber}
                  />
                }
                label="Выводить два значения"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BlockMapping;
