import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { ProfileUserProps } from "../../../dto/user";
import { successToaster } from "../../../common/alerts";

export const profileSettingChanged = createAction<{
  value: any;
  field_name: keyof ProfileUserProps;
}>("profile/setting/changed");

export const saveProfileSettingsRequest = createAsyncThunk(
  "profile/settings/save",
  async (current_user: any) => {
    const {
      email,
      first_name,
      last_name,
      phone,
      notify_via_email,
      notify_via_sms,
    } = current_user;
    const formData = new FormData();
    const profileData = {
      email,
      first_name,
      last_name,
      phone,
      notify_via_email,
      notify_via_sms,
    };
    Object.keys(profileData).map((field_name) => {
      if (profileData[field_name] !== void 0) {
        formData.append(`profile[${field_name}]`, profileData[field_name]);
      }
    });
    if (
      Object.prototype.hasOwnProperty.call(current_user, "avatar") &&
      current_user.avatar instanceof File
    ) {
      formData.append("profile[avatar]", current_user.avatar);
    }
    try {
      await api.profile.update(formData);
      successToaster("Профиль сохранён");
    } catch (error) {
      handleErrors("Не удалось сохранить профиль", error);
    }
  }
);

export const updatePasswordRequest = createAsyncThunk(
  "password/update",
  async (user: any) => {
    try {
      const { id, old_password, password, password_confirmation } = user;
      const { user: updatedUser } = await api.profile.updatePassword(
        id,
        old_password,
        password,
        password_confirmation
      );
      successToaster("Пароль изменён");
      return updatedUser;
    } catch (error) {
      handleErrors("Не удалось сменить пароль", error);
    }
  }
);

export const loadProfile = createAsyncThunk("profile/load", async () => {
  try {
    const { user } = await api.profile.show();
    return user;
  } catch (e) {
    handleErrors("Не удалось загрузить данные профиля", e);
  }
});

export const requestNewPassword = createAsyncThunk(
  "password/new",
  async (email: string) => {
    try {
      const { message } = await api.profile.newPassword(email);
      successToaster(
        "Мы выслали инструкции по восстановлению пароля. Проверьте почту."
      );
      return message;
    } catch (e) {
      handleErrors("Не удалось запросить сброс пароля", e);
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  "password/reset",
  async ({
    password,
    password_confirmation,
    reset_password_token,
  }: {
    password: string;
    password_confirmation: string;
    reset_password_token: string;
  }) => {
    try {
      const { message } = await api.profile.resetPassword(
        password,
        password_confirmation,
        reset_password_token
      );
      successToaster("Ваш пароль изменён");
      return message;
    } catch (e) {
      handleErrors("Не удалось изменить пароль", e);
    }
  }
);
