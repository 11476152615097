import { createReducer } from "@reduxjs/toolkit";
import {
  addBlockBackground,
  loadInterfaceBlock,
  removeBlockBackground,
  requestUpdateBlock,
  updateBlock,
  updateBlockBackground,
} from "../actions/interface_block/interface_blocks";
import { InterfaceBlockProps } from "../../dto/device-map";

export default createReducer<InterfaceBlockProps>(
  { name: "", block_backgrounds: [] },
  (builder) => {
    builder.addCase(
      loadInterfaceBlock.fulfilled,
      (state, action) => action.payload
    );
    builder.addCase(updateBlock, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(addBlockBackground, (state, action) => {
      return {
        ...state,
        block_backgrounds:
          state.block_backgrounds === void 0
            ? [action.payload.background]
            : [action.payload.background, ...state.block_backgrounds],
        new_background: {},
      };
    });
    builder.addCase(removeBlockBackground, (state, action) => {
      if (state.id === void 0) return state;
      const idx = state.block_backgrounds.findIndex(
        (bb) => bb.id === action.payload.background.id
      );
      if (idx < 0) return state;
      return {
        ...state,
        block_backgrounds: [
          ...state.block_backgrounds.slice(0, idx),
          ...state.block_backgrounds.slice(idx + 1),
        ],
      };
    });
    builder.addCase(updateBlockBackground, (state, action) => {
      if (state.block_backgrounds === void 0) return state;
      const idx = state.block_backgrounds.findIndex(
        (bb) => bb.id === action.payload.background.id
      );
      if (idx < 0) {
        const new_props = {};
        const props_map = {
          condition: "new_condition",
          background: "new_background",
          value: "new_value",
        };
        Object.keys(action.payload.background).forEach((key) => {
          if (key === "id") return true;
          new_props[props_map[key]] = action.payload.background[key];
        });
        return { ...state, ...new_props };
      } else {
        const updated_background = {
          ...state.block_backgrounds[idx],
          ...action.payload.background,
        };
        return {
          ...state,
          block_backgrounds: [
            ...state.block_backgrounds.slice(0, idx),
            updated_background,
            ...state.block_backgrounds.slice(idx + 1),
          ],
        };
      }
    });
    builder.addCase(
      requestUpdateBlock.fulfilled,
      (state, action) => action.payload
    );
  }
);
