import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import {
  addBlockBackground,
  loadInterfaceBlock,
  removeBlockBackground,
  requestCreateBlock,
  requestUpdateBlock,
  updateBlock,
  updateBlockBackground,
} from "../../redux/actions/interface_block/interface_blocks";
import { useParams } from "react-router-dom";
import { LoadingProgress } from "../common/LoadingProgress";
import ImageFileUploader from "./ImageFileUploader";
import { v4 } from "uuid";

const InterfaceBlock: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const block = useSelector<BusstationState, any>((state) => state.block);

  const { interfaceBlockId } = useParams();

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadInterfaceBlock(interfaceBlockId));
    }
  }, [dispatch, interfaceBlockId, currentUser.isLoading]);

  const save = useCallback(() => {
    if (block.id === void 0 || block.id === null) {
      dispatch(requestCreateBlock(block));
    } else {
      dispatch(requestUpdateBlock(block));
    }
  }, [block, dispatch]);

  const updateBlockInfo = useCallback(
    (e) => {
      const info = {
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      };
      dispatch(updateBlock(info));
    },
    [dispatch]
  );

  const handleAddBlockBackground = useCallback(
    (background) => {
      dispatch(
        addBlockBackground({ block, background: { ...background, id: v4() } })
      );
    },
    [dispatch, block]
  );

  const handleUpdateBlockBackground = useCallback(
    (background) => {
      dispatch(updateBlockBackground({ block, background }));
    },
    [dispatch, block]
  );

  const handleRemoveBlockBackground = useCallback(
    (background) => {
      dispatch(removeBlockBackground({ block, background }));
    },
    [dispatch, block]
  );

  if (interfaceBlockId && !block.id) return <LoadingProgress />;

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Блок интерфейса</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-7">
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        id="block_name"
                        name="name"
                        type="text"
                        value={block.name}
                        onChange={updateBlockInfo}
                        className="form-control"
                        placeholder="Название блока"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-3 col-xs-7">
                  <input
                    type="checkbox"
                    id="units"
                    name="units"
                    checked={block.units}
                    onChange={updateBlockInfo}
                  />
                  <label htmlFor="units" style={{ marginTop: "1rem" }}>
                    Два ряда
                  </label>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg m-l-15 waves-effect"
                    onClick={save}
                    disabled={
                      block.name === null ||
                      block.name === void 0 ||
                      block.name.length === 0 ||
                      block.block_backgrounds === void 0 ||
                      block.block_backgrounds.length === 0
                    }
                  >
                    Сохранить блок
                  </button>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-7">
                  <input
                    type="checkbox"
                    id="display_value"
                    name="display_value"
                    checked={block.display_value}
                    onChange={updateBlockInfo}
                  />
                  <label htmlFor="display_value">Показывать значение</label>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-7">
                  <input
                    type="checkbox"
                    id="inner"
                    name="inner"
                    checked={block.inner}
                    onChange={updateBlockInfo}
                  />
                  <label htmlFor="inner">Значение внутри блока</label>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <ImageFileUploader
                    save={handleAddBlockBackground}
                    updateBlock={handleUpdateBlockBackground}
                  />
                  {block.block_backgrounds &&
                    block.block_backgrounds.map((block) => {
                      return (
                        <ImageFileUploader
                          key={block.id}
                          {...block}
                          save={handleUpdateBlockBackground}
                          updateBlock={handleUpdateBlockBackground}
                          removeBlock={handleRemoveBlockBackground}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterfaceBlock;
