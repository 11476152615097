import { apiRequest } from "./common";
import { DeviceBindingProps } from "../dto/device-binding";

const API_URL = `/api/v1/devices/users`;

const deviceUsersUrl = (userId?: number | string) =>
  userId ? `${API_URL}?user_id=${userId}` : API_URL;

const deviceUserUrl = (userId: number | string) => `${API_URL}/${userId}`;

const list = async (userId: number | string) =>
  apiRequest<{ device_users: DeviceBindingProps[] }>(deviceUserUrl(userId));

const listDevices = async (userId?: number | string) =>
  apiRequest<{ devices: { id: number; serial: number }[] }>(
    deviceUsersUrl(userId)
  );

const create = async (userId: number | string, deviceId: number) =>
  apiRequest<{ device_user: DeviceBindingProps }>(API_URL, "post", {
    id: userId,
    device_id: deviceId,
  });

const destroy = async (deviceUserId: number | string) =>
  apiRequest<{ device_user: DeviceBindingProps }>(
    deviceUserUrl(deviceUserId),
    "delete"
  );

export const deviceUsers = {
  list,
  listDevices,
  create,
  destroy,
};
