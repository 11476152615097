import { api } from "../../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { successToaster } from "../../../common/alerts";
import { FirmwareSectionName } from "../../../dto/device-map";

export const requestDeviceSettings = createAsyncThunk(
  "device/settings/request",
  async ({
    deviceId,
    firmwareSections,
  }: {
    deviceId: number | string;
    firmwareSections: FirmwareSectionName[];
  }) => {
    try {
      const [{ device }] = await Promise.all([
        api.devices.show(deviceId),
        api.deviceSettingsSync.update(deviceId, firmwareSections),
      ]);
      return device;
    } catch (e) {
      handleErrors("Не удалось загрузить настройки панели", e);
    }
  }
);

export const saveDeviceSettings = createAsyncThunk(
  "devices/settings/save",
  async (panel: any) => {
    try {
      const { device: updatedPanel } = await api.deviceSettings.update(panel);
      successToaster("Настройки сохранены");
      return updatedPanel;
    } catch (error) {
      handleErrors("Не удалось сохранить настройки", error);
    }
  }
);
