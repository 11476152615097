import React, { FC, useCallback, useEffect } from "react";
import DeviceScheduleTabs from "./DeviceScheduleTabs";

import {
  requestDeviceSettings,
  saveDeviceSettings,
} from "../../redux/actions/device_settings/device_settings";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { PanelInterface } from "../../dto";
import { updatePanel } from "../../redux/actions/panels/panel";
import { Link, useParams } from "react-router-dom";

const DeviceSchedulePanel: FC = () => {
  const dispatch = useDispatch<DispatchType>();

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const { panelId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(
        requestDeviceSettings({
          deviceId: panelId,
          firmwareSections: ["schedule"],
        })
      );
    }
  }, [dispatch, panelId, currentUser.isLoading]);

  const panel = useSelector<BusstationState, PanelInterface>(
    (state) => state.panel
  );

  const toggleScheduleMode = useCallback(
    (e) => {
      dispatch(
        updatePanel({ serial: panel.serial, schedule_mode: e.target.checked })
      );
    },
    [panel.serial, dispatch]
  );

  const handleSaveDeviceSettings = useCallback(() => {
    dispatch(
      saveDeviceSettings({
        id: panel.id,
        schedule: panel.schedule,
        schedule_mode: panel.schedule_mode,
      })
    );
  }, [panel.id, panel.schedule, panel.schedule_mode, dispatch]);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <h2>
            <Link style={{ textDecoration: "none" }} to={`/panels/${panel.id}`}>
              НАЗАД
            </Link>{" "}
            | Расписание панели {panel.serial}
          </h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="body">
                <DeviceScheduleTabs />
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <input
                      id="schedule_mode"
                      type="checkbox"
                      onChange={toggleScheduleMode}
                      checked={panel.schedule_mode}
                    />
                    <label htmlFor="schedule_mode">Работа по расписанию</label>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-lg-1 col-md-11 col-sm-11 col-xs-6">
                    <button
                      className="btn btn-primary waves-effect"
                      onClick={handleSaveDeviceSettings}
                    >
                      Сохранить!
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: panel.action_in_progress ? "" : "none",
                  }}
                  className="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSchedulePanel;
