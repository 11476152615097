import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { DispatchType } from "../../redux/store/type";
import { registerUser } from "../../api/redux-token-auth-config";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import LicenseAgreementDialog from "./LicenseAgreement";
import { errorToaster } from "../../common/alerts";
import Alert from "@mui/material/Alert";
import { v4 } from "uuid";

const theme = createTheme();

const validate = ({
  first_name,
  last_name,
  password,
  password_confirmation,
  email,
  email_confirmation,
}) => {
  const errors: { [name: string]: string } = {};

  if (email.length === 0 || email !== email_confirmation) {
    errors.email =
      email.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (email_confirmation.length === 0 || email !== email_confirmation) {
    errors.email_confirmation =
      email_confirmation.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (password.length === 0 || password !== password_confirmation) {
    errors.password =
      password.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (
    password_confirmation.length === 0 ||
    password_confirmation !== password_confirmation
  ) {
    errors.password_confirmation =
      password_confirmation.length === 0
        ? "Не может быть пустым"
        : "Не совпадает со вторым полем";
  }
  if (first_name.length === 0) {
    errors.first_name = "Не может быть пустым";
  }
  if (last_name.length === 0) {
    errors.last_name = "Не может быть пустым";
  }
  return errors;
};

function SignUp() {
  const dispatch = useDispatch<DispatchType>();
  const [licenseAccepted, setLicenseAccepted] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [name: string]: string;
  }>({});

  const [responseErrors, setResponseErrors] = useState<string[]>([]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email");
      const email_confirmation = data.get("email_confirmation");
      const password = data.get("password");
      const password_confirmation = data.get("password_confirmation");
      const first_name = data.get("first_name");
      const last_name = data.get("last_name");
      const errors = validate({
        first_name,
        last_name,
        password,
        password_confirmation,
        email,
        email_confirmation,
      });
      setErrors(errors);
      const valid = Object.keys(errors).length === 0;
      if (valid && licenseAccepted) {
        dispatch(
          registerUser({
            email,
            email_confirmation,
            password,
            password_confirmation,
            first_name,
            last_name,
            licenseAccepted,
          })
        ).catch((e) => {
          setResponseErrors(e.response?.data?.errors);
        });
      } else {
        if (!licenseAccepted) errorToaster("Примите лицензионное соглашение");
      }
    },
    [dispatch, licenseAccepted]
  );

  const handleLicenseAccepted = useCallback((accepted) => {
    setLicenseAccepted(accepted.target.checked);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#52A3ED" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Введите ваши данные
          </Typography>
          {responseErrors &&
            responseErrors.map((error) => (
              <Alert key={v4()} severity="error">
                {error}
              </Alert>
            ))}
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  error={!!errors.first_name}
                  helperText={errors.first_name}
                  required
                  fullWidth
                  id="first_name"
                  label="Имя"
                  name="first_name"
                  autoComplete="first-name"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  error={!!errors.last_name}
                  helperText={errors.last_name}
                  required
                  fullWidth
                  id="last_name"
                  label="Фамилия"
                  name="last_name"
                  autoComplete="last-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                  fullWidth
                  id="email"
                  label="Электропочта"
                  name="email"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.email_confirmation}
                  helperText={errors.email_confirmation}
                  required
                  fullWidth
                  id="email_confirmation"
                  label="Электропочта ещё раз"
                  name="email_confirmation"
                  autoComplete="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.password}
                  helperText={errors.password}
                  required
                  fullWidth
                  name="password"
                  label="Пароль"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!!errors.password_confirmation}
                  helperText={errors.password_confirmation}
                  required
                  fullWidth
                  name="password_confirmation"
                  label="Подтверждение пароля"
                  type="password"
                  id="password_confirmation"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      required
                      onChange={handleLicenseAccepted}
                      checked={licenseAccepted}
                      color="primary"
                    />
                  }
                  label={
                    <LicenseAgreementDialog
                      acceptLicense={() => handleLicenseAccepted(true)}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!licenseAccepted}
            >
              Зарегистрироваться!
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/users/sign_in" variant="body2">
                  Уже зарегистрированы? Входите
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SignUp;
