import React, { useCallback, useEffect } from "react";
import DeviceBindingsTable from "./DeviceBindingsTable";
import DeviceSearchForm from "./DeviceSearchForm";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import {
  loadDeviceUsers,
  unbindDeviceRequest,
} from "../../redux/actions/device_users";
import { useParams } from "react-router-dom";

export default function DeviceBindingsPanel() {
  const dispatch = useDispatch<DispatchType>();
  const devices = useSelector<BusstationState, any[]>(
    (state) => state.device_bindings
  );

  const { userId } = useParams();

  const unbindDevice = useCallback(
    (device) => {
      dispatch(unbindDeviceRequest(device));
    },
    [dispatch]
  );

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadDeviceUsers(userId));
    }
  }, [dispatch, userId, currentUser.isLoading]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Пользователи</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <DeviceSearchForm userId={userId} />
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <DeviceBindingsTable
                    devices={devices}
                    removeDevice={unbindDevice}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
