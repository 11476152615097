import { createReducer } from "@reduxjs/toolkit";
import {
  loadFirmwareList,
  requestRemoveFirmware,
  saveFirmwareRequest,
} from "../actions/firmware";

export default createReducer<any[]>([], (builder) => {
  builder.addCase(
    loadFirmwareList.fulfilled,
    (state, action) => action.payload
  );
  builder.addCase(saveFirmwareRequest.fulfilled, (state, action) => {
    const idx = state.findIndex((fw) => fw.id === action.payload.id);
    if (idx < 0) return [...state, action.payload];
    else
      return [
        ...state.slice(0, idx),
        { ...state[idx], ...action.payload },
        ...state.slice(idx + 1),
      ];
  });
  builder.addCase(requestRemoveFirmware.fulfilled, (state, action) => {
    const idx = state.findIndex((item) => item.id === action.payload.id);
    if (idx > -1) {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    } else {
      return state;
    }
  });
});
