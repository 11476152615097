import { deviceUrl } from "./devices";
import { apiRequest } from "./common";
import { FirmwareSectionName } from "../dto/device-map";
import { PanelInterface } from "../dto";

const API_URL = `/api/v1/devices`;
const settingsUrl = (panelId: number | string) =>
  `${API_URL}/${panelId}/settings`;

const show = async (
  panelId: number | string,
  settings_type: FirmwareSectionName
) =>
  apiRequest<{ device: PanelInterface }>(
    `${deviceUrl(panelId)}?settings_type=${settings_type}`
  );

const update = async (panel: PanelInterface) =>
  apiRequest<{ device: PanelInterface }>(settingsUrl(panel.id), "put", panel);

export const deviceSettings = {
  show,
  update,
};
