import { apiRequest } from "../common";
import {
  DeviceInterfaceProps,
  DeviceMapProps,
  FirmwareProps,
} from "../../dto/device-map";

const API_URL = "/api/v1/admin/device_maps";

const deviceMapUrl = (deviceMapId: number | string) =>
  `${API_URL}/${deviceMapId}`;

const list = async () => apiRequest<{ device_maps: DeviceMapProps[] }>(API_URL);

const show = async (deviceMapId: number | string) =>
  apiRequest<{
    device_map: DeviceMapProps;
    device_interface: DeviceInterfaceProps;
    current_firmware: FirmwareProps;
  }>(deviceMapUrl(deviceMapId));

const create = async (data: any) =>
  apiRequest<{ device_map: DeviceMapProps }>(API_URL, "POST", data);

const update = async (deviceMapId: number | string, data: any) =>
  apiRequest<{ device_map: DeviceMapProps }>(
    deviceMapUrl(deviceMapId),
    "PUT",
    data
  );

const destroy = async (deviceMapId: number | string) =>
  apiRequest<{ device_map: DeviceMapProps }>(
    deviceMapUrl(deviceMapId),
    "DELETE"
  );

export const deviceMaps = {
  list,
  show,
  create,
  update,
  destroy,
};
