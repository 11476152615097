import React, { FC, useCallback, useMemo } from "react";
import TabLink from "../common/TabLink";
import TabsContainer from "../common/TabsContainer";
import DeviceScheduleSettingTabPane from "./DeviceScheduleSettingTabPane";
import {
  convertToUint,
  deviceScheduleHours,
  deviceScheduleInputFan,
  deviceScheduleMinutes,
  deviceScheduleOutputFan,
  deviceScheduleState,
  deviceScheduleTemperature,
  fromScheduleSettings,
} from "../../common";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { updatePanelSchedule } from "../../redux/actions/panels/panel";
import { PanelInterface } from "../../dto";

const days = {
  monday: "Понедельник",
  tuesday: "Вторник",
  wednesday: "Среда",
  thursday: "Четверг",
  friday: "Пятница",
  saturday: "Суббота",
  sunday: "Воскресенье",
};
const pattern = Object.keys(days);

const short_days = {
  monday: "ПН",
  tuesday: "ВТ",
  wednesday: "СР",
  thursday: "ЧТ",
  friday: "ПТ",
  saturday: "СБ",
  sunday: "ВС",
};

interface DeviceScheduleTabsProps {
  schedule: number[];
  active_pane: string;
  tabClicked: () => void;
}

const DeviceScheduleTabs: FC<DeviceScheduleTabsProps> = ({
  tabClicked,
  active_pane,
}) => {
  const dispatch = useDispatch<DispatchType>();
  const panel = useSelector<BusstationState, PanelInterface>(
    (state) => state.panel
  );
  const settings = useMemo(() => {
    return convertToUint(panel.schedule).map((code) => {
      const hour = deviceScheduleHours(code);
      const minute = deviceScheduleMinutes(code);
      const state = deviceScheduleState(code);
      const input_fan = deviceScheduleInputFan(code);
      const output_fan = deviceScheduleOutputFan(code);
      const temperature = deviceScheduleTemperature(code);
      const time = moment({ hour, minute }); //.format("HH:mm");
      return {
        time,
        hour,
        minute,
        state,
        input_fan,
        output_fan,
        temperature,
      };
    });
  }, [panel.schedule]);

  const onSettingsChanged = useCallback(
    (setting, settingIndex, i) => {
      const replaceIdx = (i * 4 + settingIndex) * 2;
      const values = fromScheduleSettings(setting);
      dispatch(updatePanelSchedule({ values, replaceIdx }));
    },
    [dispatch]
  );

  return (
    <>
      <ul className="nav nav-tabs tab-nav-right hidden-xs" role="tablist">
        {pattern.map((day) => (
          <TabLink
            key={day}
            active_pane={active_pane}
            title={days[day]}
            field_name={day}
            onClick={tabClicked}
          />
        ))}
      </ul>
      <ul className="nav nav-tabs tab-nav-right visible-xs" role="tablist">
        {pattern.map((day) => {
          return (
            <TabLink
              key={day}
              active_pane={active_pane}
              title={short_days[day]}
              field_name={day}
              onClick={tabClicked}
            />
          );
        })}
      </ul>
      <div className="tab-content">
        {pattern.map((day, i) => {
          return (
            <DeviceScheduleSettingTabPane
              key={day}
              active_pane={active_pane}
              field_name={day}
              day={day}
              dayNumber={i}
              schedule={settings.slice(i * 4, i * 4 + 4)}
              onChange={(setting, settingIndex) =>
                onSettingsChanged(setting, settingIndex, i)
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default TabsContainer(DeviceScheduleTabs, "monday");
