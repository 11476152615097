import React, { FC } from "react";

import TabLink from "../common/TabLink";

import BlocksTabPane from "./BlocksPane";
import SettingsTabPane from "./SettingsPane";
import AdminSettingsTabPane from "./AdminSettingsPane";
import ScheduleTabPane from "./SchedulePane";
import TimeTabPane from "./TimePane";
import ControlRegistersTabPane from "./control_registers/ControlRegistersPane";
import TabsContainer from "../common/TabsContainer";

const MapTabs: FC<{ active_pane: string; tabClicked: () => void }> = ({
  active_pane,
  tabClicked,
}) => {
  return (
    <>
      <ul className="nav nav-tabs tab-nav-right" role="tablist">
        {[
          { field_name: "blocks", title: "Блоки" },
          { field_name: "settings", title: "Уставки" },
          { field_name: "admin_settings", title: "Настройки" },
          { field_name: "schedule", title: "Расписание" },
          { field_name: "time", title: "Время" },
          { field_name: "control_registers", title: "Регистры управления" },
        ].map((info, i) => (
          <TabLink
            key={i}
            active_pane={active_pane}
            field_name={info.field_name}
            title={info.title}
            onClick={tabClicked}
          />
        ))}
      </ul>
      <div className="tab-content">
        <BlocksTabPane active_pane={active_pane} />
        <SettingsTabPane active_pane={active_pane} />
        <AdminSettingsTabPane active_pane={active_pane} />
        <ScheduleTabPane active_pane={active_pane} />
        <TimeTabPane active_pane={active_pane} />
        <ControlRegistersTabPane active_pane={active_pane} />
      </div>
    </>
  );
};

export default TabsContainer(MapTabs, "blocks");
