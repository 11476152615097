import React, { FC, useCallback } from "react";
import PanelCell from "./PanelCell";
import { getByDataType } from "../../common";

interface PanelRowProps {
  row_index: number;
  row: {
    blocks: {
      state_register: number;
      value_register: number;
      bit_mask?: number;
      inner: boolean;
      display_value: boolean;
      id: number;
      two_values: boolean;
      units: boolean;
      backgrounds: any[];
      binary: boolean;
    }[];
  };
  one_line: boolean;
  firmware: any;
  payload: any;
}

const PanelRow: FC<PanelRowProps> = ({
  payload,
  row,
  row_index,
  firmware,
  one_line,
}) => {
  const fwRow = useCallback(
    (reg_address) => {
      return firmware[reg_address.toString()];
    },
    [firmware]
  );

  const dataType = useCallback(
    (reg_address) => {
      if (reg_address === null || typeof reg_address === "undefined") return;
      return fwRow(reg_address)["data_type"];
    },
    [fwRow]
  );

  const valueFrom = useCallback(
    (reg_address, bit_mask) => {
      if (reg_address === null || typeof reg_address === "undefined") return;
      const offset = parseInt(reg_address, 10);
      let output = getByDataType(payload, offset, dataType(reg_address));
      if (offset === 0 && bit_mask) {
        output = output & bit_mask;
      }
      return output;
    },
    [payload, dataType]
  );

  return (
    <tr>
      {row.blocks.map((block, i) => {
        return (
          <PanelCell
            one_line={one_line}
            key={i}
            block={{
              ...block,
              state:
                dataType(block.value_register) === "float"
                  ? valueFrom(block.state_register, block.bit_mask).toFixed(1)
                  : valueFrom(block.value_register, block.bit_mask),
              value:
                dataType(block.value_register) === "float"
                  ? valueFrom(block.value_register, block.bit_mask).toFixed(1)
                  : valueFrom(block.value_register, block.bit_mask),
            }}
            row_index={row_index}
            rightPadding={i === 0}
            leftPadding={i === row.blocks.length - 1}
          />
        );
      })}
    </tr>
  );
};

export default PanelRow;
