import React, { FC, useCallback, useEffect, useMemo } from "react";
import DeviceSettingsTable from "./DeviceSettingsTable";
import {
  requestDeviceSettings,
  saveDeviceSettings,
} from "../../redux/actions/device_settings/device_settings";
import {
  deviceDay,
  deviceHours,
  deviceMinutes,
  deviceMonth,
  deviceYear,
  fromDataType,
  fromDate,
  getUint,
  isoDateString,
  splitUint,
} from "../../common";
import {
  updatePanelSettings,
  updatePanelTime,
} from "../../redux/actions/panels/panel";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { PanelInterface } from "../../dto";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Link, useParams } from "react-router-dom";

const DeviceSettingsPanel: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const panel = useSelector<BusstationState, PanelInterface>(
    (state) => state.panel
  );

  const { currentUser } = useSelector<
    BusstationState,
    {
      currentUser?: {
        isSignedIn: boolean;
        isLoading: boolean;
        attributes?: any;
      };
    }
  >((state) => state.reduxTokenAuth);

  const { panelId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(
        requestDeviceSettings({
          deviceId: panelId,
          firmwareSections: ["settings", "time"],
        })
      );
    }
  }, [dispatch, panelId, currentUser.isLoading]);

  const saveSettings = useCallback(() => {
    dispatch(
      saveDeviceSettings({
        id: panel.id,
        settings: panel.settings,
        time: panel.time,
        time_set_at: panel.isoTimeSetAt,
      })
    );
  }, [panel.id, panel.settings, panel.time, panel.isoTimeSetAt, dispatch]);

  const settingChanged = useCallback(
    (value, replaceIdx, dataType) => {
      const values = fromDataType(value, dataType);
      dispatch(updatePanelSettings({ values, replaceIdx }));
    },
    [dispatch]
  );

  const timeChanged = useCallback(
    (time) => {
      if (time.target) return;
      const isoTimeSetAt = isoDateString(new Date());
      time = fromDate(time);
      time = splitUint(time);
      dispatch(updatePanelTime({ values: time, timeSetAt: isoTimeSetAt }));
    },
    [dispatch]
  );

  const time = useMemo(() => {
    const code = getUint(panel.time, 0);
    return moment({
      year: deviceYear(code) + 2000,
      month: deviceMonth(code) - 1,
      day: deviceDay(code),
      hour: deviceHours(code),
      minute: deviceMinutes(code),
    });
  }, [panel.time]);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <h2>
            <Link to={`/panels/${panel.id}`}>НАЗАД</Link> | Настройки панели{" "}
            {panel.label ?? panel.serial}
          </h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="body">
                <>
                  <DeviceSettingsTable
                    settingChanged={settingChanged}
                    settings={panel.template.settings}
                    payload={panel.settings}
                    firmware={panel.template.firmware}
                  />
                  <div className="row clearfix">
                    <div className="col-lg-10 col-md-10 col-sm-8 col-xs-6">
                      <DateTimePicker
                        label="Время устройства"
                        value={time}
                        onChange={timeChanged}
                      />
                    </div>
                  </div>
                </>
                {currentUser.attributes.role === "admin" && (
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <Link to={`/panels/${panel.id}/admin-settings`}>
                        Настройки администратора
                      </Link>
                    </div>
                  </div>
                )}
                <div className="row clearfix">
                  <div className="col-lg-1 col-md-2 col-sm-6 col-xs-6">
                    <button
                      className="btn btn-primary waves-effect"
                      onClick={saveSettings}
                    >
                      Сохранить!
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: panel.action_in_progress ? "" : "none",
                  }}
                  className="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceSettingsPanel;
