import { splitUint } from "./index";

export const deviceScheduleOutputFan = (scheduleRecord: number) =>
  (scheduleRecord & (0x7f << 25)) >> 25;

export const deviceScheduleInputFan = (scheduleRecord: number) =>
  (scheduleRecord & (0x7f << 18)) >> 18;

export const deviceScheduleTemperature = (scheduleRecord: number) =>
  (scheduleRecord & (0x1f << 13)) >> 13;

export const deviceScheduleHours = (scheduleRecord: number) =>
  Math.floor(((scheduleRecord & (0xfff << 1)) >> 1) / 100);

export const deviceScheduleMinutes = (scheduleRecord: number) =>
  ((scheduleRecord & (0xfff << 1)) >> 1) % 100;

export const deviceScheduleState = (scheduleRecord: number) =>
  (scheduleRecord & 1) > 0 ? "start" : "stop";

const fromState = (state: string) => (state === "start" ? 1 : 0);
const fromTime = (hour: number, minute: number) =>
  ((hour * 100 + minute) & 0xfff) << 1;
const fromTemperature = (temp: number) => (temp & 0x1f) << 13;
const fromInputFan = (inputFan: number) => (inputFan & 0x7f) << 18;
const fromOutputFan = (outputFan: number) => (outputFan & 0x7f) << 25;

export const fromScheduleSettings = ({
  state,
  hour,
  minute,
  temperature,
  input_fan,
  output_fan,
}: {
  state: string;
  hour: number;
  minute: number;
  temperature: number;
  input_fan: number;
  output_fan: number;
}) => {
  return splitUint(
    fromState(state) |
      fromTime(hour, minute) |
      fromTemperature(temperature) |
      fromInputFan(input_fan) |
      fromOutputFan(output_fan)
  );
};
