import React, { FC, useCallback, useRef } from "react";

import {
  addFirmwareFile,
  saveFirmwareRequest,
  setFirmwareNumber,
} from "../../redux/actions/firmware";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";

const FileUploader: FC<{
  firmware_number: number;
  file_name: string;
}> = ({ firmware_number, file_name }) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<DispatchType>();

  const current_firmware = useSelector<BusstationState, any>(
    (state) => state.current_firmware
  );

  const handleSaveFirmware = useCallback(() => {
    dispatch(saveFirmwareRequest(current_firmware));
  }, [dispatch, current_firmware]);

  const handleSetFirmwareNumber = useCallback(
    (e) => {
      dispatch(setFirmwareNumber(e.target.value));
    },
    [dispatch]
  );

  const handleAddFirmwareFile = useCallback(
    (e) => {
      dispatch(
        addFirmwareFile({ file_name: e.target.value, file: e.target.files[0] })
      );
    },
    [dispatch]
  );
  return (
    <form>
      <div className="row clearfix">
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
          <div className="form-group">
            <div className="form-line">
              <input
                name="firmware_number"
                type="number"
                value={firmware_number || ""}
                onChange={handleSetFirmwareNumber}
                className="form-control"
                placeholder="Номер прошивки"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
          <div className="form-group">
            <div className="form-line">
              <div className="input-group" style={{ marginBottom: 0 }}>
                <span
                  className="input-group-addon"
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="material-icons"
                    onClick={() => {
                      if (fileInput.current) fileInput.current.click();
                    }}
                  >
                    folder_open
                  </i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  value={file_name || ""}
                  readOnly={true}
                  placeholder="Файл прошивки"
                />
                <input
                  name="file"
                  ref={fileInput}
                  type="file"
                  onChange={handleAddFirmwareFile}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <button
            type="button"
            onClick={handleSaveFirmware}
            className="btn btn-primary btn-lg m-l-15 waves-effect"
            disabled={
              file_name === void 0 ||
              file_name.length === 0 ||
              firmware_number === void 0 ||
              firmware_number === 0
            }
          >
            Добавить прошивку
          </button>
        </div>
      </div>
    </form>
  );
};

export default FileUploader;
