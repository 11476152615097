import React from "react";
import UserProfile from "./UserProfile";

export default function UserProfilePane() {
  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Профиль</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <UserProfile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
