import React, { FC, useEffect } from "react";
import DeviceMapRow from "./DeviceMapRow";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadDeviceMaps } from "../../redux/actions/device_map/device_maps_list";
import { LoadingProgress } from "../common/LoadingProgress";

const DeviceMapsTable: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const device_maps = useSelector<BusstationState, any[]>(
    (state) => state.device_maps
  );

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) dispatch(loadDeviceMaps());
  }, [dispatch, currentUser.isLoading]);

  if (!device_maps) return <LoadingProgress />;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Версия прошивки</th>
          <th>Версия интерфейса</th>
          <th>Создана</th>
          <th>Обновлена</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {device_maps.map((device_map) => {
          return <DeviceMapRow key={device_map.id} device_map={device_map} />;
        })}
      </tbody>
    </table>
  );
};

export default DeviceMapsTable;
