import React, { FC } from "react";
import { FormLabel } from "@mui/material";

const DeviceScheduleHeaderRow: FC = () => (
  <div className="row clearfix hidden-xs">
    <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3 text-center">
      <FormLabel>Состояние</FormLabel>
    </div>
    <div className="col-lg-2 col-md-3 col-sm-3 col-xs-3 text-center">
      <FormLabel>Время</FormLabel>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
      <FormLabel>Уставка температуры</FormLabel>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
      <FormLabel>Уставка вентилятора притока</FormLabel>
    </div>
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
      <FormLabel>Уставка вентилятора вытяжки</FormLabel>
    </div>
  </div>
);

export default DeviceScheduleHeaderRow;
