import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import BlockControls from "./BlockControls";
import { useDropzone } from "react-dropzone";

const baseStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  padding: "20px",
  // borderWidth: 2,
  // borderRadius: 2,
  // borderColor: "#eeeeee",
  // borderStyle: "dashed",
  // backgroundColor: "#fafafa",
  // color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface ImageFileUploaderProps {
  id?: string | number;
  condition?: string;
  value?: any;
  mock_file?: {
    name: string;
    size: number;
    type: string;
  };
  url?: string;
  background?: File & { preview?: string };
}

const ImageFileUploader: FC<
  ImageFileUploaderProps & {
    updateBlock: (block: ImageFileUploaderProps) => void;
    save: (block: ImageFileUploaderProps) => void;
    removeBlock?: (block: { id: number | string }) => void;
  }
> = ({ updateBlock, save, removeBlock, ...props }) => {
  const [state, setState] = useState<ImageFileUploaderProps>(props);

  const reset = () => {
    setState({
      condition: "",
      value: "",
      background: void 0,
    });
  };

  const fileAdded = useCallback(
    (file) => {
      setState({ ...state, background: file });
      updateBlock({ id: state.id, background: file });
    },
    [state, updateBlock]
  );

  const handleInputChange = useCallback(
    (value, field_name) => {
      const block: ImageFileUploaderProps = { ...state, [field_name]: value };
      setState(block);
      updateBlock(block);
    },
    [state, setState, updateBlock]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) => {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        fileAdded(file);
      });
    },
    [fileAdded]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      // "image/jpeg": [".jpeg", ".jpg"],
      // "image/png": [".png"],
      "image/gif": [".gif"],
    },
    maxFiles: 1,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(
    () => () => {
      if (state.background && state.background.preview)
        URL.revokeObjectURL(state.background.preview);
    },
    [state.background]
  );

  const handleSave = useCallback(() => {
    if (
      state.condition === void 0 ||
      state.condition.length === 0 ||
      (state.condition === "step" && state.value.length === 0) ||
      state.background === void 0
    )
      return;
    save({
      id: state.id,
      condition: state.condition,
      value: state.value,
      background: state.background,
    });
    if (props.id === void 0) reset();
  }, [
    state.id,
    props.id,
    state.condition,
    state.value,
    state.background,
    save,
  ]);

  const handleRemove = useCallback(() => {
    if (removeBlock) removeBlock({ id: state.id });
  }, [state.id, removeBlock]);

  const size = useMemo(() => {
    const size = state.background?.size || state.mock_file?.size || 0;
    return Math.ceil(size / 100.0) / 10.0;
  }, [state.background, state.mock_file]);

  return (
    <form method="post">
      <div className="row clearfix">
        <BlockControls
          id={state.id}
          condition={state.condition || ""}
          value={
            state.value === void 0 || state.value === null ? "" : state.value
          }
          background={state.background}
          handleInputChange={handleInputChange}
          save={handleSave}
          remove={handleRemove}
        />
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="row clearfix">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ marginBottom: 0 }}
                >
                  <div
                    className={
                      ["filepicker", "dropzone", "dz-clickable"].join(" ") +
                      (state.background?.preview || state.url
                        ? " dz-started"
                        : "")
                    }
                    {...getRootProps({ style })}
                  >
                    <input {...getInputProps()} />
                    <div
                      className="dz-default dz-message"
                      style={{ width: "100%" }}
                    >
                      <span>Перетащите сюда файл или кликните область.</span>
                    </div>
                    {(state.background?.preview || state.url) && [
                      <div
                        key="image-preview"
                        className="dz-preview dz-complete dz-image-preview"
                      >
                        <div>
                          <img
                            style={{ height: 104 }}
                            data-dz-thumbnail=""
                            alt={
                              state.background?.name || state.mock_file?.name
                            }
                            src={state.background?.preview || state.url}
                          />
                        </div>
                        <div className="dz-progress">
                          <span
                            className="dz-upload"
                            data-dz-uploadprogress=""
                          ></span>
                        </div>
                        <div className="dz-error-message">
                          <span data-dz-errormessage=""></span>
                        </div>
                      </div>,
                      <div
                        key="image-details"
                        className="dz-details"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "16px 0",
                        }}
                      >
                        <div className="dz-size">
                          <span
                            data-dz-size={
                              state.background?.size ||
                              state.mock_file?.size ||
                              0
                            }
                          >
                            <strong>{size}</strong> KB
                          </span>
                        </div>
                        <div className="dz-filename">
                          <span data-dz-name="">
                            {state.background?.name || state.mock_file?.name}
                          </span>
                        </div>
                      </div>,
                    ]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ImageFileUploader;
// extends React.Component {
//   constructor(props) {
//     super(props);
//     this.save = this.save.bind(this);
//     this.fileAdded = this.fileAdded.bind(this);
//     this.handleInputChange = this.handleInputChange.bind(this);
//     this.initDropzone = this.initDropzone.bind(this);
//     this.reset = this.reset.bind(this);
//     this.state = {
//       dropzone: null,
//       id: this.props.id || ImageFileUploader.uniqId(),
//       condition: this.props.condition,
//       value: this.props.value,
//       background: this.props.background,
//     };
//   }
//
//   // componentDidMount() {
//   //   if (this.state.background !== void 0)
//   //     this.state.dropzone.addFile(this.state.background);
//   // }
// }

// ImageFileUploader.propTypes = {
//   id: PropTypes.any,
//   condition: PropTypes.string,
//   value: PropTypes.any,
//   mock_file: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     size: PropTypes.number.isRequired,
//     type: PropTypes.string.isRequired,
//   }),
//   url: PropTypes.string,
// };
