import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import Root from "./components/Root";

import UserProfilePane from "./components/users/UserProfilePane";
import UsersPanel from "./components/users/UsersPanel";
import DevicePanel from "./components/panels/DevicePanel";
import PanelTable from "./components/panels/PanelTable";
import DeviceSettingsPanel from "./components/device_settings/DeviceSettingsPanel";
import DeviceAdminSettingsPanel from "./components/device_settings/DeviceAdminSettingsPanel";
import DeviceSchedulePanel from "./components/device_settings/DeviceSchedulePanel";
import FirmwareTable from "./components/firmwares/FirmwareTable";
import Firmware from "./components/firmwares/Firmware";
import DeviceEditor from "./components/interfaces/DeviceEditor";
import InterfaceBlocks from "./components/interfaces/InterfaceBlocks";
import DeviceMap from "./components/device_maps/DeviceMap";
import DeviceMapsList from "./components/device_maps/DeviceMapsList";
import { authLoader } from "./loaders";
import InterfaceTable from "./components/interfaces/InterfaceTable";
import InterfaceBlock from "./components/interfaces/InterfaceBlock";
import ErrorPage from "./components/common/error-page";
import { ProtectedLayout } from "./components/common/ProtectedLayout";
import { DeviseLayout } from "./components/common/DeviseLayout";
import { App } from "./components/common/App";
import SignIn from "./components/common/SignIn";
import SignUp from "./components/common/SignUp";
import { AdminLayout } from "./components/common/AdminLayout";
import DeviceBindingsPanel from "./components/device_users/DeviceBindingsPanel";
import ConfirmationSuccess from "./components/common/ConfirmationSuccess";
import NewPassword from "./components/common/NewPassword";
import EditPassword from "./components/common/EditPassword";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      errorElement={<ErrorPage />}
      loader={authLoader}
    >
      <Route errorElement={<ErrorPage />}>
        <Route element={<ProtectedLayout />}>
          <Route element={<App />}>
            <Route index element={<PanelTable />} />
            <Route path="/profile" element={<UserProfilePane />} />
            <Route path="/panels/:panelId" element={<DevicePanel />} />
            <Route
              path="/panels/:panelId/settings"
              element={<DeviceSettingsPanel />}
            />
            <Route
              path="/panels/:panelId/admin-settings"
              element={<DeviceAdminSettingsPanel />}
            />
            <Route
              path="/panels/:panelId/schedule"
              element={<DeviceSchedulePanel />}
            />
            <Route element={<AdminLayout />}>
              <Route path="/admin/users" element={<UsersPanel />} />
              <Route
                path="/admin/users/:userId/panels"
                element={<DeviceBindingsPanel />}
              />
              <Route path="/firmwares/:firmwareId" element={<Firmware />} />
              <Route path="/firmwares" element={<FirmwareTable />} />
              <Route path="/interfaces" element={<InterfaceTable />} />
              <Route path="/interfaces/new" element={<DeviceEditor />} />
              <Route
                path="/interfaces/:interfaceId"
                element={<DeviceEditor />}
              />
              <Route path="/interface-blocks" element={<InterfaceBlocks />} />
              <Route
                path="/interface-blocks/new"
                element={<InterfaceBlock />}
              />
              <Route
                path="/interface-blocks/:interfaceBlockId"
                element={<InterfaceBlock />}
              />
              <Route
                path="/device-maps/new/:interfaceId"
                element={<DeviceMap />}
              />
              <Route path="/device-maps/:deviceMapId" element={<DeviceMap />} />
              <Route path="/device-maps" element={<DeviceMapsList />} />
            </Route>
          </Route>
        </Route>
        <Route path="/users" element={<DeviseLayout />}>
          <Route path="sign_in" element={<SignIn />} />
          <Route path="sign_up" element={<SignUp />} />
          <Route
            path="confirmation-success"
            element={<ConfirmationSuccess />}
          />
          <Route path="password/new" element={<NewPassword />} />
          <Route
            path="password/edit/:reset_password_token"
            element={<EditPassword />}
          />
        </Route>
      </Route>
    </Route>
  )
);
