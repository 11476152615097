import { apiRequest } from "../common";
import { DeviceInterfaceProps } from "../../dto/device-map";

const API_URL = "/api/v1/admin/interfaces";

const interfaceUrl = (interfaceId: number | string) =>
  `${API_URL}/${interfaceId}`;

const create = async (data: any) =>
  apiRequest<{ device_interface: DeviceInterfaceProps }>(
    API_URL,
    "post",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

const update = async (interfaceId: number | string, data: any) =>
  apiRequest<{ device_interface: DeviceInterfaceProps }>(
    interfaceUrl(interfaceId),
    "put",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

const show = async (interfaceId: number | string) =>
  apiRequest<{ device_interface: DeviceInterfaceProps }>(
    interfaceUrl(interfaceId)
  );

const list = async () =>
  apiRequest<{ device_interfaces: DeviceInterfaceProps[] }>(API_URL);

const destroy = async (interfaceId: number | string) =>
  apiRequest<{ device_interface: DeviceInterfaceProps }>(
    interfaceUrl(interfaceId),
    "delete"
  );

export const interfaces = {
  create,
  update,
  show,
  list,
  destroy,
};
