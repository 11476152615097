import { apiRequest } from "../common";
import { InterfaceBlockProps } from "../../dto/device-map";

const API_URL = "/api/v1/admin/interface_blocks";
const interfaceBlockUrl = (interfaceBlockId: number | string) =>
  `${API_URL}/${interfaceBlockId}`;

const create = async (data: any) =>
  apiRequest<{ block: InterfaceBlockProps }>(API_URL, "post", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const update = async (blockId: number | string, data: any) =>
  apiRequest<{ block: InterfaceBlockProps }>(
    interfaceBlockUrl(blockId),
    "put",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

const show = async (interfaceBlockId: number | string) =>
  apiRequest<{ block: InterfaceBlockProps }>(
    interfaceBlockUrl(interfaceBlockId)
  );

const list = async () => apiRequest<{ blocks: InterfaceBlockProps[] }>(API_URL);

const destroy = async (interfaceBlockId: number | string) =>
  apiRequest<{ block: InterfaceBlockProps }>(
    interfaceBlockUrl(interfaceBlockId),
    "delete"
  );

export const interfaceBlocks = {
  create,
  show,
  list,
  destroy,
  update,
};
