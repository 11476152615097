import { createReducer } from "@reduxjs/toolkit";
import {
  addFirmwareFile,
  resetCurrentFirmware,
  setFirmwareNumber,
} from "../actions/firmware";

export default createReducer<{
  file_name?: string;
  file?: Blob;
  number?: number;
}>({}, (builder) => {
  builder.addCase(setFirmwareNumber, (state, action) => ({
    ...state,
    number: action.payload,
  }));
  builder.addCase(resetCurrentFirmware, () => ({}));
  builder.addCase(addFirmwareFile, (state, action) => ({
    ...state,
    ...action.payload,
  }));
});
