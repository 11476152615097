import { createReducer } from "@reduxjs/toolkit";
import {
  loadUsers,
  removeUserRequest,
  updateUserRequest,
} from "../actions/users/users";

export default createReducer<any[]>([], (builder) => {
  builder.addCase(loadUsers.fulfilled, (state, action) => action.payload);
  builder.addCase(updateUserRequest.fulfilled, (state, action) =>
    updateUser(state, action)
  );
  builder.addCase(removeUserRequest.fulfilled, (state, action) => {
    const idx = state.findIndex((u) => u.id === action.payload.id);
    if (idx < 0) return state;
    return [...state.slice(0, idx), ...state.slice(idx + 1)];
  });
});

const updateUser = (state, action) => {
  const idx = state.findIndex((u) => u.id === action.payload.id);
  if (idx < 0) return state;
  return [
    ...state.slice(0, idx),
    { ...state[idx], ...action.payload },
    ...state.slice(idx + 1),
  ];
};
