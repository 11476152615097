import React, { FC } from "react";

const ReplaceFiltersMessage: FC<{ readonly alarmed: boolean }> = ({
  alarmed,
}) => (
  <div
    className="device-filters-alarm"
    style={{ display: alarmed ? "" : "none" }}
  >
    <label>Замените фильтры</label>
  </div>
);

export default ReplaceFiltersMessage;
