import React, { useRef } from "react";
import DeviceSettingsRow from "./DeviceSettingsRow";

import { FC } from "react";
import { range } from "lodash";
import { getByDataType } from "../../common";
import { LoadingProgress } from "../common/LoadingProgress";

interface DeviceSettingsTableProps {
  settings: {
    read_address: number;
    write_address: number;
    label: string;
    settings: any[];
  }[];
  payload: number[];
  settingChanged: (value: number, offset: number, dataType: string) => void;
  firmware: any;
}

const DeviceSettingsTable: FC<DeviceSettingsTableProps> = ({
  firmware,
  settings,
  payload,
  settingChanged,
}) => {
  const offset = useRef<number>(0);
  return (
    (settings.length === 0 && <LoadingProgress />) || (
      <>
        {range(
          settings[0].read_address,
          settings[settings.length - 1].read_address + 1
        ).map((reg, i) => {
          const fw = firmware[reg.toString()];
          if (!fw) return null;

          let setting = settings.find((s) => s.read_address === reg);
          if (!setting || !setting.label || setting.label.length === 0)
            setting = { ...setting, label: fw.description };

          if (i == 0) offset.current = 0;
          const index = offset.current;
          const value = getByDataType(payload, index, fw.data_type);
          offset.current += ["uint", "int", "float"].includes(fw.data_type)
            ? 2
            : 1;
          return (
            <DeviceSettingsRow
              key={`${setting.write_address}:${setting.read_address}`}
              setting={setting}
              value={value}
              settingChanged={(value) =>
                settingChanged(value, index, fw.data_type)
              }
            />
          );
        })}
      </>
    )
  );
};

export default DeviceSettingsTable;
