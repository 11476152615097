import { apiRequest } from "./common";
import { PanelInterface } from "../dto";

const API_URL = "/api/v1/devices";
export const deviceUrl = (deviceId: number | string) =>
  `${API_URL}/${deviceId}`;

const updateLabel = async (panelId: number | string, label: string) =>
  apiRequest(`${deviceUrl(panelId)}/labels`, "put", { label });

const destroyLabel = async (panelId: number | string) =>
  apiRequest(`${deviceUrl(panelId)}/labels`, "delete");

const toggleRunning = async (panelId: number | string, start: boolean) =>
  apiRequest<{ device: PanelInterface }>(
    `${deviceUrl(panelId)}/operation`,
    "put",
    {
      start,
    }
  );

const destroyConnection = async (deviceId: number | string) =>
  apiRequest(`${deviceUrl(deviceId)}/connection`, "delete");

const show = async (panelId: number | string) =>
  apiRequest<{ device: PanelInterface }>(deviceUrl(panelId));

const list = async () => apiRequest<{ devices: PanelInterface[] }>(API_URL);

const destroy = async (deviceId: number | string) =>
  apiRequest(deviceUrl(deviceId), "delete");

export const devices = {
  destroy,
  destroyConnection,
  updateLabel,
  destroyLabel,
  toggleRunning,
  show,
  list,
};
