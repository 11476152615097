import React, { FC, useCallback } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../common";
import { requestRemoveInterface } from "../../redux/actions/interface/interfaces";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { DeviceInterfaceProps } from "../../dto/device-map";

const InterfaceTableRow: FC<{
  item: DeviceInterfaceProps;
}> = ({ item }) => {
  const dispatch = useDispatch<DispatchType>();
  const remove = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(requestRemoveInterface(item));
    },
    [dispatch, item]
  );

  return (
    <tr>
      <td>
        <Link to={`/interfaces/${item.id}`}>{item.version}</Link>
      </td>
      <td>{moment(item.updated_at).format(DATE_FORMAT)}</td>
      <td>
        <Link title="Создать привязку" to={`/device-maps/new/${item.id}`}>
          <i className="material-icons">device_hub</i>
        </Link>
        <a href="#" onClick={remove}>
          <i className="material-icons">delete_forever</i>
        </a>
      </td>
    </tr>
  );
};

export default InterfaceTableRow;
