import { combineReducers } from "redux";

import firmwares from "./firmwares";
import firmware from "./firmware";
import devices from "./devices";
import interfaces from "./interfaces";
import current_firmware from "./current_firmware";
import block from "./interface_block";
import blocks from "./interface_blocks";
import toolbar_blocks from "./toolbar_blocks";
import device_interface from "./device_interface";
import current_device_map from "./device_map";
import device_maps from "./device_maps_list";
import panel from "./panel";
import edit_dialog_visible from "./edit_dialog";
import read_address from "./read_address";
import write_address from "./write_address";
import users from "./users";
import device_bindings from "./device_users/devices";
import bindings_user from "./device_users/user";
import profile from "./profile";
import { reduxTokenAuthReducer } from "redux-token-auth";
import isLoading from "./is-loading";
import isMenuOpen from "./is-menu-open";

const rootReducer = combineReducers({
  firmwares,
  firmware,
  interfaces,
  devices,
  device_interface,
  current_firmware,
  block,
  blocks,
  toolbar_blocks,
  current_device_map,
  device_maps,
  panel,
  edit_dialog_visible,
  read_address,
  write_address,
  users,
  device_bindings,
  bindings_user,
  profile,
  reduxTokenAuth: reduxTokenAuthReducer,
  isLoading,
  isMenuOpen,
});

export default rootReducer;
