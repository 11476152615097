import { createReducer } from "@reduxjs/toolkit";
import {
  bindDeviceRequest,
  loadDeviceUsers,
  unbindDeviceRequest,
} from "../../actions/device_users";
import { DeviceBindingProps } from "../../../dto/device-binding";

export default createReducer<DeviceBindingProps[]>([], (builder) => {
  builder.addCase(
    loadDeviceUsers.fulfilled,
    (state, action) => action.payload.device_users
  );
  builder.addCase(bindDeviceRequest.fulfilled, (state, action) => {
    const idx = state.findIndex((d) => d.serial === action.payload.serial);
    if (idx >= 0) return state;
    return [action.payload, ...state];
  });
  builder.addCase(unbindDeviceRequest.fulfilled, (state, action) => {
    const idx = state.findIndex((d) => d.serial === action.payload.serial);
    if (idx < 0) return state;
    return [...state.slice(0, idx), ...state.slice(idx + 1)];
  });
});
