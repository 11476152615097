import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { BusstationState } from "../../redux/store/type";
import Logo from "images/logo-bg-w-bg.png";

export const DeviseLayout = () => {
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  if (currentUser.isSignedIn) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand" href="//intechvent.ru">
              <img src={Logo} alt="Logo" />
              <span className="hidden-xs">Диспетчеризация</span>
            </a>
          </div>
        </div>
      </nav>
      <section className="content" style={{ marginLeft: 15 }}>
        <Outlet />
      </section>
    </>
  );
};
