import React, { FC } from "react";

import { Popover, OverlayTrigger } from "react-bootstrap";
import { alarmMessages, alarm_messages } from "../helpers/device_state_helper";

interface AlarmRowProps {
  alarms: {
    alarmed: boolean;
    input_fan: boolean;
    output_fan: boolean;
    tan_overheating: boolean;
    recuperator_error: boolean;
    fire: boolean;
    sensor_disconnected: boolean;
    output_channel_temp_sensor: boolean;
    frost_defence: boolean;
    channel_frozen: boolean;
    winter_mode_switch_error: boolean;
    recuperator_rotation_error: boolean;
    no_coolant_error: boolean;
    backwater_temp_sensor: boolean;
    not_enough_eeprom: boolean;
    humidity_sensor_error: boolean;
    co2_sensor_error: boolean;
  };
}

const AlarmRow: FC<AlarmRowProps> = ({ alarms }) => (
  <OverlayTrigger
    overlay={
      <Popover id="alarms-popover" title="Аварии">
        {Object.keys(alarms).map((alarm_name) => {
          if (alarm_name === "alarmed" || !alarms[alarm_name]) return null;
          return (
            <div key={alarm_name}>
              <label>{alarm_messages[alarm_name]}</label>
            </div>
          );
        })}
      </Popover>
    }
  >
    <div
      className="device-alarm text-left"
      style={{ display: alarms.alarmed ? "" : "none" }}
    >
      <label>{alarmMessages(alarms)}</label>
    </div>
  </OverlayTrigger>
);

export default AlarmRow;
