import React, { FC, useCallback } from "react";

const DeviceCell: FC<{
  block: any;
  current_block: any;
  onCellClick: (block: any) => void;
}> = ({ block, current_block, onCellClick }) => {
  const handleClick = useCallback(() => {
    onCellClick(block);
  }, [block, onCellClick]);

  return (
    <td
      onClick={handleClick}
      rowSpan={block.units ? 2 : 1}
      style={{
        width: block.thumb_url === void 0 ? 100 : "auto",
        borderWidth:
          current_block &&
          block.ord === current_block.ord &&
          block.row_index === current_block.row_index
            ? "3px"
            : "1px",
      }}
    >
      <img
        alt={block.id}
        src={block.thumb_url}
        className={
          block.inner ? "arrow" : block.units ? "two-units-img" : "one-unit-img"
        }
      />
    </td>
  );
};

export default DeviceCell;
