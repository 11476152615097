import React, { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { requestRemoveInterfaceBlock } from "../../redux/actions/interface_block/interface_blocks";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { InterfaceBlockProps } from "../../dto/device-map";

const InterfaceBlockRow: FC<{ readonly block: InterfaceBlockProps }> = ({
  block,
}) => {
  const dispatch = useDispatch<DispatchType>();
  const removeBlock = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(requestRemoveInterfaceBlock(block));
    },
    [block, dispatch]
  );
  return (
    <tr key={block.id}>
      <td>
        <Link to={`/interface-blocks/${block.id}`}>{block.name}</Link>
      </td>
      <td>
        <img
          className={`${block.units ? "two-units-img" : "one-unit-img"}${
            block.inner ? " arrow" : ""
          }`}
          src={block.url}
          alt={block.name}
        />
      </td>
      <td>
        <a href="#" onClick={removeBlock}>
          <i className="material-icons">delete_forever</i>
        </a>
      </td>
    </tr>
  );
};

export default InterfaceBlockRow;
