import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { BlockProps } from "../../../dto/device-map";
import { successToaster } from "../../../common/alerts";

export const updateBlock = createAction<any>("block/update");

export const addBlockBackground = createAction<{ block: any; background: any }>(
  "block/background/add"
);

export const removeBlockBackground = createAction<{
  block: any;
  background: any;
}>("block/background/remove");

export const updateBlockBackground = createAction<{
  block: any;
  background: any;
}>("block/background/update");

export const requestRemoveInterfaceBlock = createAsyncThunk(
  "interface_blocks/remove",
  async (block: any) => {
    try {
      await api.admin.interfaceBlocks.destroy(block.id);
      successToaster("Блок интерфейса удалён");
      return block;
    } catch (error) {
      handleErrors("Не удалось сохранить блок", error);
    }
  }
);

const prepareFormData = (block: any) => {
  const form = new FormData();
  form.append("name", block.name);
  form.append("units", (block.units ? 2 : 1) as unknown as string);
  form.append("display_value", block.display_value);
  form.append("inner", block.inner);
  block.block_backgrounds.forEach((bb) => {
    if (typeof bb.url === "undefined") {
      form.append("block_backgrounds_attributes[][condition]", bb.condition);
      form.append("block_backgrounds_attributes[][value]", bb.value);
      form.append("block_backgrounds_attributes[][background]", bb.background);
    } else {
      form.append("block_backgrounds[][id]", bb.id);
      form.append("block_backgrounds[][condition]", bb.condition);
      form.append("block_backgrounds[][value]", bb.value);
      if (bb.background instanceof File)
        form.append("block_backgrounds[][background]", bb.background);
    }
  });
  return form;
};

export const requestUpdateBlock = createAsyncThunk(
  "interface_blocks/update",
  async (block: BlockProps) => {
    const form = prepareFormData(block);
    try {
      const { block: updatedBlock } = await api.admin.interfaceBlocks.update(
        block.id,
        form
      );
      successToaster("Блок успешно сохранен");
      return updatedBlock;
    } catch (error) {
      handleErrors("Не удалось сохранить блок", error);
    }
  }
);

export const requestCreateBlock = createAsyncThunk(
  "interface_blocks/create",
  async (block: any) => {
    const form = prepareFormData(block);

    try {
      const { block: createdBlock } = await api.admin.interfaceBlocks.create(
        form
      );
      return createdBlock;
    } catch (error) {
      handleErrors("Не удалось сохранить блок", error);
    }
  }
);

export const loadInterfaceBlocks = createAsyncThunk(
  "interface_blocks/list/load",
  async () => {
    const { blocks } = await api.admin.interfaceBlocks.list();
    return blocks;
  }
);

export const loadInterfaceBlock = createAsyncThunk(
  "interface_blocks/load",
  async (interfaceBlockId?: number | string) => {
    if (interfaceBlockId) {
      const { block } = await api.admin.interfaceBlocks.show(interfaceBlockId);
      return block;
    } else {
      return {
        id: null,
        name: null,
        inner: false,
        display_value: true,
        units: false,
      };
    }
  }
);
