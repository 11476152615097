import React, { FC } from "react";
import DeviceBindingsRow from "./DeviceBindingsRow";
import { PanelInterface } from "../../dto";

const DeviceBindingsTable: FC<{
  devices: PanelInterface[];
  removeDevice: (device: PanelInterface) => void;
}> = ({ devices, removeDevice }) => (
  <table className="table">
    <thead>
      <tr>
        <th>Серийный номер</th>
        <th>Подключена</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {devices.map((d) => {
        return (
          <DeviceBindingsRow key={d.id} device={d} remove={removeDevice} />
        );
      })}
    </tbody>
  </table>
);

export default DeviceBindingsTable;
