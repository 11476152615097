import { createReducer } from "@reduxjs/toolkit";
import {
  loadInterfaceBlocks,
  requestCreateBlock,
  requestRemoveInterfaceBlock,
} from "../actions/interface_block/interface_blocks";

export default createReducer<any[]>([], (builder) => {
  builder.addCase(
    loadInterfaceBlocks.fulfilled,
    (state, action) => action.payload
  );
  builder.addCase(requestCreateBlock.fulfilled, (state, action) => [
    ...state,
    action.payload,
  ]);
  builder.addCase(requestRemoveInterfaceBlock.fulfilled, (state, action) => {
    const idx = state.findIndex((block) => block.id === action.payload.id);
    if (idx < 0) return state;
    return [...state.slice(0, idx), ...state.slice(idx + 1)];
  });
});
