import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DeviceMapProps, DeviceMapStateProps } from "../../../dto/device-map";
import { handleErrors } from "../../../common";
import { successToaster } from "../../../common/alerts";

export const controlRegChanged = createAction<{ name: string; value: any }>(
  "registers/control/changed"
);

export const regSaveSettings = createAction<any>("registers/settings/save");

export const toggleEditDialog = createAction<boolean>("edit_dialog/visible");

export const initEditRegisterDialog = createAction<{
  read_address: number;
  write_address: number;
}>("edit_dialog/init");

export const regChanged = createAction<{
  name: keyof DeviceMapProps;
  value: number;
}>("device_map/reg_changed");

export const blockRegisterChanged = createAction<number>("register/changed");

export const blockValueRegisterChanged = createAction<number>(
  "value_register/changed"
);

export const toggleValuesNumber = createAction<boolean>(
  "block/values_number/changed"
);

export const blockMeasUnitsChanged = createAction<number>(
  "block/meas_units/changed"
);

export const blockSecondMeasUnitsChanged = createAction<number>(
  "block/meas_units/changed/second"
);

export const currentBlockChanged = createAction<any>("current_block/changed");

export const addBitMask = createAction("bit_mask/add");

export const removeBitMask = createAction("bit_mask/remove");

export const toggleBit = createAction<{
  row_index: number;
  cell_index: number;
}>("bit_mask/toggle");

export const requestCreateDeviceMap = createAsyncThunk(
  "device_map/create",
  async (payload: DeviceMapProps) => {
    const formData = prepareDeviceMapData(payload);
    try {
      const { device_map } = await api.admin.deviceMaps.create(formData);
      successToaster("Карта устройства сохранена");
      return device_map;
    } catch (error) {
      handleErrors("Не удалось сохранить карту устройства", error);
    }
  }
);

export const requestUpdateDeviceMap = createAsyncThunk(
  "device_map/update",
  async (payload: DeviceMapProps) => {
    const formData = prepareDeviceMapData(payload);
    try {
      const { device_map } = await api.admin.deviceMaps.update(
        payload.id,
        formData
      );
      successToaster("Карта устройства сохранена");
      return device_map;
    } catch (error) {
      handleErrors("Не удалось сохранить карту устройства", error);
    }
  }
);

export const loadDeviceMap = createAsyncThunk(
  "device_map/load",
  async ({
    deviceMapId,
    interfaceId,
  }: {
    deviceMapId?: string;
    interfaceId?: string;
  }): Promise<DeviceMapStateProps> => {
    if (deviceMapId) {
      try {
        const [
          { device_map, device_interface, current_firmware },
          { firmwares },
        ] = await Promise.all([
          api.admin.deviceMaps.show(deviceMapId),
          api.admin.firmwares.list({ deviceMapId }),
        ]);

        return { device_map, device_interface, current_firmware, firmwares };
      } catch (e) {
        handleErrors("Не удалось загрузить карту устройства", e);
      }
    } else {
      if (!interfaceId)
        throw new Error("No deviceMapId and no interfaceId was provided");
      try {
        const [{ device_interface }, { firmwares }] = await Promise.all([
          api.admin.interfaces.show(interfaceId),
          api.admin.firmwares.list({ interfaceId }),
        ]);
        return {
          device_interface,
          firmwares,
          device_map: {
            interface_id: device_interface.id,
            registers: [],
            control_registers: {},
            register_settings: [],
          },
        };
      } catch (e) {
        handleErrors("Не удалось загрузить карту устройства", e);
      }
    }
  }
);

function mapRegisters(registers, fieldName) {
  const o = {};
  registers.forEach((r) => {
    if (typeof fieldName === "function") {
      o[r.id] = fieldName(r);
    } else {
      o[r.id] = r[fieldName] || 0;
    }
  });
  return o;
}

const formatBitMask = (r) =>
  !(r.bit_mask === void 0 || r.bit_mask === null || r.bit_mask.length === 0)
    ? r.bit_mask[1].slice().reverse().join("") +
      r.bit_mask[0].slice().reverse().join("")
    : "";

function prepareDeviceMapData(device_map: DeviceMapProps) {
  return {
    firmware_id: device_map.firmware_id,
    interface_id: device_map.interface_id,
    map: mapRegisters(device_map.registers, "register"),
    value_map: mapRegisters(device_map.registers, "value_register"),
    meas_units: mapRegisters(device_map.registers, "meas_units"),
    second_meas_units: mapRegisters(device_map.registers, "second_meas_units"),
    values_number: mapRegisters(device_map.registers, (r) =>
      r.two_values ? 2 : 1
    ),
    bit_mask: mapRegisters(device_map.registers, formatBitMask),
    firmware_sections: {
      settings_read_start_reg: device_map.settings_read_start_reg,
      settings_read_end_reg: device_map.settings_read_end_reg,
      settings_write_start_reg: device_map.settings_write_start_reg,
      settings_write_end_reg: device_map.settings_write_end_reg,
      admin_settings_read_start_reg: device_map.admin_settings_read_start_reg,
      admin_settings_read_end_reg: device_map.admin_settings_read_end_reg,
      admin_settings_write_start_reg: device_map.admin_settings_write_start_reg,
      admin_settings_write_end_reg: device_map.admin_settings_write_end_reg,
      schedule_read_start_reg: device_map.schedule_read_start_reg,
      schedule_read_end_reg: device_map.schedule_read_end_reg,
      schedule_write_start_reg: device_map.schedule_write_start_reg,
      schedule_write_end_reg: device_map.schedule_write_end_reg,
      time_read_start_reg: device_map.time_read_start_reg,
      time_read_end_reg: device_map.time_read_end_reg,
      time_write_start_reg: device_map.time_write_start_reg,
      time_write_end_reg: device_map.time_write_end_reg,
    },
    register_settings: device_map.register_settings,
    control_registers: device_map.control_registers,
  };
}
