import { generateAuthActions } from "redux-token-auth";
import { REDUX_TOKEN_AUTH_URL } from "../config/config";

const config = {
  authUrl: REDUX_TOKEN_AUTH_URL,
  userAttributes: {
    id: "id",
    email: "email",
    first_name: "first_name",
    last_name: "last_name",
    role: "role",
    notify_via_sms: "notify_via_sms",
    notify_via_email: "notify_via_email",
    phone: "phone",
    avatar_url: "avatar_url",
    mock_avatar: "mock_avatar",
    centrifugo_token: "centrifugo_token",
    centrifugo_feed_token: "centrifugo_feed_token",
  },
  userRegistrationAttributes: {
    first_name: "first_name",
    last_name: "last_name",
    email: "email",
    email_confirmation: "email_confirmation",
    password: "password",
    password_confirmation: "password_confirmation",
    licenseAccepted: "license_accepted",
  },
};

const { registerUser, signInUser, signOutUser, verifyCredentials } =
  generateAuthActions(config);

export { registerUser, signInUser, signOutUser, verifyCredentials };
