export default class FirmwareDecorator {
  firmware: any;

  constructor(firmware) {
    this.firmware = firmware;
  }

  register(address) {
    address = address.toString();
    const r = this.firmware.registers.find((r) => r.address === address);
    return r === void 0 ? { description: "" } : r;
  }
}
