import React, { FC, useCallback } from "react";
import {
  ControlRegisters,
  FirmwareRegisterProps,
  FirmwareSectionRegisters,
} from "../../../dto/device-map";

const RegisterSelect: FC<{
  label?: string;
  field_name: keyof FirmwareSectionRegisters | keyof ControlRegisters;
  value: any;
  registers: FirmwareRegisterProps[];
  onChange: (fieldName: string, value: number) => void;
}> = ({ label, field_name, value, onChange, registers }) => {
  const handleOnChange = useCallback(
    (e) => {
      onChange(field_name, parseInt(e.target.value));
    },
    [field_name, onChange]
  );

  return (
    <>
      {label && (
        <label htmlFor={field_name} className="form-label">
          {label}
        </label>
      )}
      <select
        id={field_name}
        name={field_name}
        value={value}
        onChange={handleOnChange}
        className="form-control"
      >
        {registers.map((register) => {
          return (
            <option
              key={register.address}
              value={register.address}
            >{`${register.address}: ${register.description}`}</option>
          );
        })}
      </select>
    </>
  );
};

export default RegisterSelect;
