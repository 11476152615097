import React, { FC, useCallback } from "react";
import moment from "moment/moment";
import { DATE_FORMAT } from "../../common";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import {
  removeUserRequest,
  updateUserRequest,
} from "../../redux/actions/users/users";
import { Link } from "react-router-dom";
import { UserProps } from "../../dto/user";

const UserRow: FC<{
  user: UserProps;
  disabled: boolean;
}> = ({ user, disabled }) => {
  const dispatch = useDispatch<DispatchType>();
  const removeUser = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(removeUserRequest(user));
    },
    [dispatch, user]
  );

  const toggleUserActive = useCallback(
    (e) => {
      const active = e.target.checked;
      dispatch(
        updateUserRequest({
          user: { ...user, active },
          successMessage: `Аккаунт пользователя ${user.email} ${
            active ? "активирован" : "отключен"
          }`,
        })
      );
    },
    [dispatch, user]
  );

  const changeUserRole = useCallback(
    (e) => {
      dispatch(
        updateUserRequest({
          user: { ...user, role: e.target.value },
          successMessage: `Роль пользователя ${user.email} изменена`,
        })
      );
    },
    [dispatch, user]
  );

  return (
    <tr>
      <td>
        <Link
          to={`/admin/users/${user.id}/panels`}
          title="Управление устройствами пользователя"
        >
          {user.email}
        </Link>
      </td>
      <td>{moment(user.created_at).format(DATE_FORMAT)}</td>
      <td>{user.name}</td>
      <td>
        <select
          className="form-control"
          disabled={disabled}
          value={user.role || ""}
          onChange={changeUserRole}
        >
          <option value="engineer">Пользователь</option>
          <option value="reseller">Дилер</option>
          <option value="admin">Администратор</option>
        </select>
      </td>
      <td className="text-center">
        <input
          id={`user_active${user.id}`}
          type="checkbox"
          onChange={toggleUserActive}
          checked={user.active}
        />
        <label htmlFor={`user_active${user.id}`} />
      </td>
      <td>
        <a href="#" onClick={removeUser}>
          <i className="material-icons">delete_forever</i>
        </a>
      </td>
    </tr>
  );
};

export default UserRow;
