import { errorToaster } from "./alerts";

export const prepareForDisplay = (errors) => {
  if (Array.isArray(errors)) return errors.join(", ");
  return Object.keys(errors).map((e) => {
    const error = errors[e];
    if (Array.isArray(error)) return `${e}: [${error.join(", ")}]`;
    return `${e}: {${prepareForDisplay(error)}}`;
  });
};

export const handleErrors = (prefix: string, error: any) => {
  if (error.response?.data?.errors) {
    console.log(error.response.data.errors);
    errorToaster(`${prefix}: ${prepareForDisplay(error.response.data.errors)}`);
  } else if (error.message) {
    errorToaster(`${prefix}: ${error.message}`);
  } else {
    errorToaster(prefix);
  }
};
