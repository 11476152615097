import React, { FC, useCallback } from "react";
import tabPane from "../../common/TabPane";

import { controlRegChanged } from "../../../redux/actions/device_map/device_map";
import RegisterSelect from "../common/RegisterSelect";
import tabPanel from "../../common/TabPanel";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState } from "../../../redux/store/type";
import { ControlRegisterName } from "../../../dto/device-map";

const lines = {
  status: "Регистр кода режима",
  state_change: "Регистр смены состояния",
  wanted_state: "Регистр желаемого состояния",
};

const ControlRegistersPane: FC = () => {
  const dispatch = useDispatch();
  const current_device_map = useSelector<BusstationState, any>(
    (state) => state.current_device_map
  );
  const firmware = useSelector<BusstationState, any>((state) => state.firmware);
  const handleControlRegChanged = useCallback(
    (name, value) => {
      dispatch(controlRegChanged({ name, value }));
    },
    [dispatch]
  );
  return (
    <>
      {Object.keys(lines).map((field_name: ControlRegisterName) => (
        <div key={field_name} className="row clearfix">
          <div className="col-lg-3 col-md-3 col-sm-5 col-xs-5 form-control-label">
            <label htmlFor={field_name}>{lines[field_name]}</label>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-4 col-xs-7">
            <div className="form-group">
              <RegisterSelect
                field_name={field_name}
                value={
                  current_device_map.device_map.control_registers[field_name] ||
                  ""
                }
                registers={firmware.registers}
                onChange={handleControlRegChanged}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default tabPane(tabPanel(ControlRegistersPane), "control_registers");
