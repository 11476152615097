import { api } from "../../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { DeviceMapProps } from "../../../dto/device-map";
import { successToaster } from "../../../common/alerts";

export const loadDeviceMaps = createAsyncThunk("device_maps/load", async () => {
  try {
    const { device_maps } = await api.admin.deviceMaps.list();
    return device_maps;
  } catch (e) {
    handleErrors("Не удалось загрузить карты устройств", e);
  }
});

export const requestRemoveDeviceMap = createAsyncThunk(
  "device_maps/remove",
  async (device_map: DeviceMapProps) => {
    try {
      await api.admin.deviceMaps.destroy(device_map.id);
      successToaster("Карта устройства удалена");
      return device_map;
    } catch (error) {
      handleErrors("Не удалось удалить карту устройства", error);
    }
  }
);
