import React, { FC, useCallback, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { bindDeviceRequest } from "../../redux/actions/device_users";
import { api } from "../../api";

const DeviceSearchForm: FC<{ userId: number | string }> = ({ userId }) => {
  const dispatch = useDispatch<DispatchType>();
  const [open, setOpen] = useState(false);
  const [serial, setSerial] = useState<number | null>(null);
  const [devices, setDevices] = useState<
    readonly { id: number; serial: number }[]
  >([]);

  const loading = open && devices.length === 0;

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  React.useEffect(() => {
    let active = true;

    if (currentUser.isLoading || !loading) {
      return undefined;
    }

    (async () => {
      const { devices } = await api.deviceUsers.listDevices(userId);

      if (active) {
        setDevices(devices);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, currentUser.isLoading, userId]);

  const bindDevice = useCallback(
    (device) => {
      dispatch(bindDeviceRequest({ user: { id: userId }, device }));
    },
    [dispatch, userId]
  );

  return (
    <div className="row clearfix">
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div className="form-group">
          <Autocomplete
            id="device-search-form"
            disableClearable
            options={devices.map((d) => d.serial)}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option, value) =>
              option.toString() === value.toString()
            }
            getOptionLabel={(option) => option.toString()}
            loading={loading}
            value={serial}
            onChange={(event, value) => {
              setSerial(parseInt(value.toString()));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Поиск установки"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <button
          type="button"
          className="btn btn-primary btn-lg m-l-15 waves-effect"
          onClick={() => {
            if (serial) {
              const device = devices.find((d) => d.serial === serial);
              if (device) bindDevice(device);
            }
          }}
        >
          Добавить!
        </button>
      </div>
    </div>
  );
};

export default DeviceSearchForm;
