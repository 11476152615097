import React, { FC, useCallback, useState } from "react";

const RegNewValueRow: FC<{
  createRegPreset: (internal_value: number, interface_value: number) => void;
}> = ({ createRegPreset }) => {
  const [values, setValues] = useState<{
    internal_value?: number;
    interface_value?: number;
  }>({});

  const handleValueChanged = useCallback(
    (e, fieldName) => {
      const value = parseInt(e.target.value);
      setValues({ ...values, [fieldName]: value });
    },
    [values]
  );

  const handleInternalValueChanged = useCallback(
    (e) => {
      handleValueChanged(e, "internal_value");
    },
    [handleValueChanged]
  );

  const handleInterfaceValueChanged = useCallback(
    (e) => {
      handleValueChanged(e, "interface_value");
    },
    [handleValueChanged]
  );

  const savePreset = useCallback(() => {
    if (!!values.internal_value && !!values.interface_value) {
      createRegPreset(values.internal_value, values.interface_value);
      setValues({});
    }
  }, [values, createRegPreset]);

  const handleCreateRegPreset = (e) => {
    e.preventDefault();
    savePreset();
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
        <input
          type="number"
          onChange={handleInternalValueChanged}
          value={values.internal_value}
          className="form-control"
        />
      </div>
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
        <input
          type="text"
          onChange={handleInterfaceValueChanged}
          value={values.interface_value}
          className="form-control"
        />
      </div>
      <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
        <a
          href="#"
          className="btn btn-default"
          title="Добавить значение"
          onClick={handleCreateRegPreset}
        >
          <i className="material-icons">note_add</i>
        </a>
      </div>
    </div>
  );
};

export default RegNewValueRow;
