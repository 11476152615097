import React, { FC } from "react";
import UserRow from "./UserRow";
import { useSelector } from "react-redux";
import { BusstationState } from "../../redux/store/type";

const UsersTable: FC<{ users: any[] }> = ({ users }) => {
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Зарегистрирован</th>
          <th>Имя</th>
          <th>Роль</th>
          <th>Активирован</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <UserRow
              key={user.id}
              user={user}
              disabled={currentUser.attributes.role === "reseller"}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default UsersTable;
