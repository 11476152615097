import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { updatePanel } from "../../redux/actions/panels/panel";
import { removeDevice } from "../../redux/actions/device";
import { Centrifuge, State } from "centrifuge";

declare global {
  interface Window {
    centrifuge: Centrifuge;
  }
}

const UserEventFeed: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const centrifuge = useMemo(() => {
    if (!currentUser.attributes.centrifugo_token) return null;

    if (!window.centrifuge) {
      window.centrifuge = new Centrifuge(
        process.env.REACT_APP_WEBSOCKET_URL ??
          "ws://localhost:8000/connection/websocket",
        {
          token: currentUser.attributes.centrifugo_token,
        }
      )
        .on("connecting", function (ctx) {
          console.log(`centrifugo connecting: ${ctx.code}, ${ctx.reason}`);
        })
        .on("connected", function (ctx) {
          console.log(`centrifugo connected over ${ctx.transport}`);
        })
        .on("disconnected", function (ctx) {
          console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
        });
    }
    return window.centrifuge;
  }, [currentUser.attributes.centrifugo_token]);

  const add = useCallback(
    (payload: any) => {
      dispatch(updatePanel({ ...payload, online: true }));
    },
    [dispatch]
  );

  const remove = useCallback(
    (device: { id: number; serial: number | string }) => {
      dispatch(removeDevice(device));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!centrifuge || !currentUser.attributes.centrifugo_feed_token) return;

    if (
      centrifuge.state !== State.Connected &&
      centrifuge.state !== State.Connecting
    ) {
      console.log("connect centrifuge");
      centrifuge.connect();
    }

    const channel = `user_feed_${currentUser.attributes.id}`;
    let subscription = centrifuge.getSubscription(channel);
    if (!subscription) {
      subscription = centrifuge.newSubscription(channel, {
        token: currentUser.attributes.centrifugo_feed_token,
      });
      subscription
        .on("publication", ({ data: { command, payload } }) => {
          switch (command) {
            case "add":
            case "update":
              add(payload);
              break;
            case "remove":
              remove(payload);
              break;
          }
        })
        .on("subscribing", function (ctx) {
          console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
        })
        .on("subscribed", function (ctx) {
          console.log("subscribed", ctx);
        })
        .on("unsubscribed", function (ctx) {
          console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
        });
    }
    subscription.subscribe();
    return () => subscription.unsubscribe();
  }, [
    centrifuge,
    currentUser.attributes.centrifugo_feed_token,
    currentUser.attributes.id,
    add,
    remove,
  ]);
  return <React.Fragment />;
};

export default UserEventFeed;
