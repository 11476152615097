import React, { FC } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../../common";

interface DeviceInterface {
  serial: number | string;
  created_at: any;
}

const DeviceBindingsRow: FC<{
  device: DeviceInterface;
  remove: (device: DeviceInterface) => void;
}> = ({ device, remove }) => (
  <tr>
    <td>{device.serial}</td>
    <td>{moment(device.created_at).format(DATE_FORMAT)}</td>
    <td>
      <button className="btn btn-primary" onClick={() => remove(device)}>
        <i className="material-icons">delete_forever</i>
      </button>
    </td>
  </tr>
);

export default DeviceBindingsRow;
