import React, { FC } from "react";
import { ItemTypes } from "./Constants";
import { useDrag } from "react-dnd";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const ToolbarBlock: FC<{
  block: {
    name: string;
    inner: boolean;
    units: boolean;
    id: number;
    thumb_url: string;
  };
}> = ({ block }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TOOLBAR_BLOCK,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: block,
  }));

  return (
    <Card sx={{ display: "flex", minWidth: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <img
            ref={drag}
            title={block.name}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            className={
              (block.units ? "two-units-img " : "one-unit-img ") +
              (block.inner ? "arrow" : "")
            }
            alt={block.id.toString()}
            src={block.thumb_url}
          />
        </CardContent>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {block.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {block.units ? "2U" : "1U"}
          </Typography>
        </CardContent>
      </Box>
    </Card>
    // <Card sx={{ minWidth: 250, display: "flex" }}>
    //   <CardContent>
    //     <img
    //       ref={drag}
    //       title={block.name}
    //       style={{ opacity: isDragging ? 0.5 : 1 }}
    //       className={
    //         block.inner
    //           ? "arrow"
    //           : block.units
    //           ? "two-units-img"
    //           : "one-unit-img"
    //       }
    //       alt={block.id.toString()}
    //       src={block.thumb_url}
    //     />
    //   </CardContent>
    // </Card>
  );
};

export default ToolbarBlock;
