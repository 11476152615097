import { apiRequest } from "./common";
import { UserProps } from "../dto/user";

const API_PATH = "/api/v1/users";
const userPath = (userId: number | string) => `${API_PATH}/${userId}`;

const show = async (userId: number | string) =>
  apiRequest<{ user: UserProps }>(userPath(userId));

const list = async () => apiRequest<{ users: UserProps[] }>(API_PATH);

export const users = {
  show,
  list,
};
