import * as React from "react";

import { stateLabel } from "../helpers/device_state_helper";
import { FC } from "react";

interface StateRowProps {
  working_mode: string;
  state: {
    stopped: boolean;
    awaiting: boolean;
    blocked: boolean;
    ventilating: boolean;
    warming: boolean;
    jalousie: boolean;
    restart: boolean;
    run: boolean;
    needs_filters_replacement: boolean;
    alarm_reset: boolean;
  };
}

const StateRow: FC<StateRowProps> = ({ state, working_mode }) => (
  <div className="row clearfix">
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <label>Состояние:</label>
      &nbsp;
      <label>{stateLabel(state)}</label>
    </div>
    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
      <label>Режим работы: </label>
      &nbsp;
      <label>
        {working_mode === "by_schedule" ? "ПО РАСПИСАНИЮ" : "РУЧНОЙ"}
      </label>
    </div>
  </div>
);

export default StateRow;
