import React, { FC, useEffect } from "react";

import InterfaceBlockRow from "./InterfaceBlockRow";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadInterfaceBlocks } from "../../redux/actions/interface_block/interface_blocks";
import { Link } from "react-router-dom";

const InterfaceBlocks: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const blocks = useSelector<BusstationState, any[]>((state) => state.blocks);

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadInterfaceBlocks());
    }
  }, [dispatch, currentUser.isLoading]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Блоки интерфейса</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Link
                    to="/interface-blocks/new"
                    className="btn btn-primary btn-lg waves-effect"
                  >
                    Новый блок
                  </Link>
                </div>
              </div>
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Название</th>
                        <th>Предпросмотр</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {blocks.map((block) => {
                        return (
                          <InterfaceBlockRow key={block.id} block={block} />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterfaceBlocks;
