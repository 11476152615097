import React, { FC } from "react";
import DeviceCell from "./DeviceCell";
import DeviceDropCell from "./DeviceDropCell";
import { InterfaceBlockProps } from "../../dto/device-map";

const DeviceRow: FC<{
  row_index: number;
  row: { blocks: InterfaceBlockProps[] };
  current_block?: any;
  onCellClick?: (block: any) => void;
  onCellDrop?: (block: any) => void;
}> = ({ row_index, row, current_block, onCellClick, onCellDrop }) => {
  return (
    <tr>
      {row.blocks.map((block, i) => {
        const commonProps = {
          key: i,
          block: { ...block, ord: i, row_index: row_index },
          row,
        };
        return typeof onCellClick === "undefined" ? (
          <DeviceDropCell onCellDrop={onCellDrop} {...commonProps} />
        ) : (
          <DeviceCell
            onCellClick={onCellClick}
            current_block={current_block}
            {...commonProps}
          />
        );
      })}
    </tr>
  );
};

export default DeviceRow;
