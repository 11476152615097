import React, { FC, useCallback, useState } from "react";

import tabPanel from "../common/TabPanel";
import tabPane from "../common/TabPane";

import {
  profileSettingChanged,
  updatePasswordRequest,
} from "../../redux/actions/profile/profile_actions";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { ProfileUserProps } from "../../dto/user";

const inputs = {
  old_password: { label: "Текущий пароль", autoComplete: "current-password" },
  password: { label: "Новый пароль", autoComplete: "new-password" },
  password_confirmation: {
    label: "Подтверждение пароля",
    autoComplete: "new-password",
  },
};

const ProfilePassword: FC<
  { user: ProfileUserProps } & {
    active_pane: string;
    tabClicked: (tab: string) => void;
  }
> = ({ user }) => {
  const [focused_field_name, setFocusedFieldName] = useState<string>("");

  const dispatch = useDispatch<DispatchType>();

  const fieldFocused = (field_name) => {
    setFocusedFieldName(field_name);
  };

  const onChange = (e) => {
    dispatch(
      profileSettingChanged({
        value: e.target.value,
        field_name: e.target.name,
      })
    );
  };

  const hasErrors = useCallback(() => {
    const errors = user.errors ?? {};
    let hasErrors = false;
    Object.keys(errors).every((k) => {
      hasErrors =
        ["old_password", "password", "password_confirmation"].indexOf(k) > -1 &&
        !!errors[k];
      return !hasErrors;
    });
    return hasErrors;
  }, [user.errors]);

  const onSave = useCallback(() => {
    if (hasErrors()) return false;
    dispatch(updatePasswordRequest(user));
  }, [dispatch, user, hasErrors]);

  return (
    <form>
      {user && (
        <input
          id="username"
          type="email"
          value={user.email}
          autoComplete="username"
          style={{ display: "none" }}
          readOnly
        />
      )}
      {user &&
        Object.keys(inputs).map((field_name) => {
          const label = inputs[field_name].label;
          const autoComplete = inputs[field_name].autoComplete;
          return (
            <div key={field_name} className="form-group form-float">
              <div
                className={`form-line${
                  (user.errors && user.errors[field_name] && " error") || ""
                }${
                  (user[field_name] && user[field_name].length > 0) ||
                  field_name === focused_field_name
                    ? " focused"
                    : ""
                }`}
              >
                <input
                  onFocus={(e) => fieldFocused(e.target.name)}
                  type="password"
                  id={field_name}
                  name={field_name}
                  value={user[field_name] || ""}
                  onChange={onChange}
                  className="form-control"
                  autoComplete={autoComplete}
                />
                <label className="form-label" htmlFor={field_name}>
                  {label}
                </label>
              </div>
              {user.errors && user.errors[field_name] && (
                <label className="error" htmlFor={field_name}>
                  {user.errors[field_name]}
                </label>
              )}
            </div>
          );
        })}

      <button
        disabled={hasErrors()}
        type="button"
        className="btn btn-primary m-t-15 waves-effect"
        onClick={onSave}
      >
        Обновить пароль
      </button>
    </form>
  );
};

export default tabPane(tabPanel(ProfilePassword), "password");
