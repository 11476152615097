import { createReducer } from "@reduxjs/toolkit";
import { loadDevice, removeDevice } from "../actions/device";
import { loadPanels, updatePanel } from "../actions/panels/panel";
import { getUint, getUshort } from "../../common";

const updateDevice = (state, action) => {
  const serial = action.payload.serial.toString();
  const idx = state.findIndex((item) => item.serial.toString() === serial);
  if (idx > -1) {
    const device = state[idx];
    if (action.payload.payload) {
      const prevCode = getUshort(device.payload, 0);
      const prevAlarmCode = getUint(device.payload, 1);
      const prevOnline = device.online;
      const code = getUshort(action.payload.payload, 0);
      const alarmCode = getUint(action.payload.payload, 1);
      const online = action.payload.online;
      if (
        prevCode === code &&
        prevAlarmCode === alarmCode &&
        prevOnline === online
      )
        return state;
    }
    return [
      ...state.slice(0, idx),
      { ...state[idx], ...action.payload },
      ...state.slice(idx + 1),
    ];
  } else {
    return state;
  }
};

export default createReducer<any[]>([], (builder) => {
  builder.addCase(loadPanels.fulfilled, (state, action) => action.payload);
  builder.addCase(loadDevice.fulfilled, (state, action) => {
    return updateDevice(state, action);
  });
  builder.addCase(updatePanel, (state, action) => {
    return updateDevice(state, action);
  });
  builder.addCase(removeDevice, (state, action) => {
    const idx = state.findIndex(
      (item) => item.serial === action.payload.serial
    );
    if (idx >= 0) {
      return [...state.slice(0, idx), ...state.slice(idx + 1)];
    } else {
      return state;
    }
  });
});

// export default function devices(state = [], action) {
//   let idx = -1;
//
//   switch (action.type) {
//     case SET_DEVICES:
//       return action.devices;
//     case ADD_DEVICE:
//       idx = state.findIndex((d) => d.serial === action.device.serial);
//       if (idx >= 0) {
//         const newDevice = Object.assign({}, state[idx], {
//           status: action.device.status,
//         });
//         return [...state.slice(0, idx), newDevice, ...state.slice(idx + 1)];
//       } else {
//         return [...state, action.device];
//       }
//     case REMOVE_DEVICE:
//       idx = state.findIndex((item) => item.serial === action.device.serial);
//       if (idx >= 0) {
//         return [...state.slice(0, idx), ...state.slice(idx + 1)];
//       } else {
//         return state;
//       }
//     case UPDATE_PANEL:
//       idx = state.findIndex((item) => item.serial === action.panel.serial);
//       if (idx > -1) {
//         return [
//           ...state.slice(0, idx),
//           { ...state[idx], ...action.panel },
//           ...state.slice(idx + 1),
//         ];
//       } else return state;
//     default:
//       return state;
//   }
// }
