import { enqueueSnackbar } from "notistack";

export const addAlert = (snackbar: {
  message: string;
  variant: "success" | "error";
}) => enqueueSnackbar(snackbar);

export const successToaster = (message: string) =>
  addAlert({ message, variant: "success" });

export const errorToaster = (message: string) =>
  addAlert({ message, variant: "error" });
