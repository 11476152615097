import React, { FC } from "react";
import moment from "moment/moment";

import { requestRemoveDeviceMap } from "../../redux/actions/device_map/device_maps_list";
import { DATE_FORMAT } from "../../common";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { Link } from "react-router-dom";

const DeviceMapRow: FC<{ device_map: any }> = ({ device_map }) => {
  const dispatch = useDispatch<DispatchType>();
  const removeRow = (e) => {
    e.preventDefault();
    dispatch(requestRemoveDeviceMap(device_map));
  };
  return (
    <tr>
      <td>{device_map.current_firmware.version}</td>
      <td>{device_map.interface_version}</td>
      <td>{moment(device_map.created_at).format(DATE_FORMAT)}</td>
      <td>{moment(device_map.updated_at).format(DATE_FORMAT)}</td>
      <td>
        <Link to={`/device-maps/${device_map.id}`}>
          <i className="material-icons">edit</i>
        </Link>
        <a href="#" onClick={removeRow}>
          <i className="material-icons">delete_forever</i>
        </a>
      </td>
    </tr>
  );
};

export default DeviceMapRow;
