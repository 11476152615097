import React, { FC, useCallback, useState } from "react";

const TabsContainer = (InnerComponent, active_tab) => {
  const Tab: FC<any> = (props: any) => {
    const [active_pane, setActivePane] = useState<string>(active_tab);

    const tabClicked = useCallback((field_name) => {
      setActivePane(field_name);
    }, []);

    return (
      <InnerComponent
        {...props}
        active_pane={active_pane}
        tabClicked={tabClicked}
      />
    );
  };

  return Tab;
};

export default TabsContainer;
