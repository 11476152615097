import React, { FC, useCallback } from "react";
import DeviceScheduleRow from "./DeviceScheduleRow";
import DeviceScheduleHeaderRow from "./DeviceScheduleHeaderRow";

const DeviceScheduleTable: FC<{
  schedule: any[];
  onChange: (setting: any, settingIndex: number) => void;
}> = ({ schedule, onChange }) => {
  const rowChanged = useCallback(
    (setting, settingIndex) => {
      onChange(setting, settingIndex);
    },
    [onChange]
  );

  return (
    <>
      <DeviceScheduleHeaderRow />
      {schedule.map((setting, i) => (
        <React.Fragment key={`${setting.day}${i}`}>
          <DeviceScheduleRow
            setting={setting}
            onChange={(setting) => rowChanged(setting, i)}
          />
          {i < schedule.length - 1 && (
            <div className="visible-xs col-xs-12">
              <hr className="visible-xs" />
            </div>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default DeviceScheduleTable;
