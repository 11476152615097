export function convertToUint(reg_values: number[]): number[] {
  const buffer = new ArrayBuffer(2 * reg_values.length);
  const ui16 = new Uint16Array(buffer);
  const ui32 = new Uint32Array(buffer);
  reg_values.forEach((val, i) => (ui16[i] = val));
  const uintValues = [];
  ui32.forEach((val) => uintValues.push(val));
  return uintValues;
}

export function splitUint(value: number): number[] {
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const ui32 = new Uint32Array(buffer);
  ui32[0] = value;
  return [ui16[0], ui16[1]];
}

export function splitInt(value: number) {
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const i32 = new Int32Array(buffer);
  i32[0] = value;
  return [ui16[0], ui16[1]];
}

export function splitShort(value: number) {
  const buffer = new ArrayBuffer(2);
  const ui16 = new Uint16Array(buffer);
  const i16 = new Int16Array(buffer);
  i16[0] = value;
  return [ui16[0]];
}

export function splitFloat(value: number) {
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const f32 = new Float32Array(buffer);
  f32[0] = value;
  return [ui16[0], ui16[1]];
}

export function getUint(reg_values: number[], offset: number): number {
  if (!reg_values || reg_values.length <= offset + 1) return 0;
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const ui32 = new Uint32Array(buffer);
  ui16[0] = reg_values[offset];
  ui16[1] = reg_values[offset + 1];
  return ui32[0];
}

export function getInt(reg_values: number[], offset: number): number {
  if (!reg_values || reg_values.length <= offset + 1) return 0;
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const i32 = new Int32Array(buffer);
  ui16[0] = reg_values[offset];
  ui16[1] = reg_values[offset + 1];
  return i32[0];
}

export function getShort(reg_values: number[], offset: number): number {
  if (!reg_values || reg_values.length <= offset) return 0;
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const i16 = new Int16Array(buffer);
  ui16[0] = reg_values[offset];
  return i16[0];
}

export function getUshort(reg_values: number[], offset: number): number {
  if (!reg_values || reg_values.length <= offset + 1) return 0;
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  ui16[0] = reg_values[offset];
  return ui16[0];
}

export function getFloat(reg_values: number[], offset: number): number {
  if (!reg_values || reg_values.length <= offset + 1) return 0;
  const buffer = new ArrayBuffer(4);
  const ui16 = new Uint16Array(buffer);
  const f32 = new Float32Array(buffer);
  ui16[0] = reg_values[offset];
  ui16[1] = reg_values[offset + 1];
  return f32[0];
}

export function getByDataType(
  reg_values: number[],
  offset: number,
  data_type: string
): number {
  switch (data_type) {
    case "uint":
      return getUint(reg_values, offset);
    case "int":
      return getInt(reg_values, offset);
    case "ushort":
      return getUshort(reg_values, offset);
    case "short":
      return getShort(reg_values, offset);
    case "float":
      return getFloat(reg_values, offset);
  }
}

export function fromDataType(value: number, data_type: string): number[] {
  switch (data_type) {
    case "uint":
      return splitUint(value);
    case "int":
      return splitInt(value);
    case "ushort":
      return [value];
    case "short":
      return splitShort(value);
    case "float":
      return splitFloat(value);
  }
}
