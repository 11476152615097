import { api } from "../../../api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { UserProps } from "../../../dto/user";
import { successToaster } from "../../../common/alerts";

export const updateUserRequest = createAsyncThunk(
  "users/update/role",
  async ({
    user,
    successMessage,
  }: {
    user: UserProps;
    successMessage: string;
  }) => {
    try {
      const { user: updatedUser } = await api.admin.users.update(user);
      successToaster(successMessage);
      return updatedUser;
    } catch (e) {
      handleErrors("Не удалось сохранить пользователя", e);
    }
  }
);

export const removeUserRequest = createAsyncThunk(
  "users/remove",
  async (user: UserProps) => {
    try {
      await api.admin.users.destroy(user.id);
      successToaster(`Пользователь ${user.email} удалён`);
      return user;
    } catch (e) {
      handleErrors(`Не удалось удалить пользователя ${user.email}`, e);
    }
  }
);

export const loadUsers = createAsyncThunk("users/load", async () => {
  try {
    const { users } = await api.admin.users.list();
    return users;
  } catch (e) {
    handleErrors("Не удалось загрузить пользователей", e);
  }
});
