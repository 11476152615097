import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { handleErrors } from "../../../common";
import { successToaster } from "../../../common/alerts";

export const deviceInterfaceVersionChanged =
  createAction<number>("version/changed");

const prepareFormData = (device_interface: {
  version: string;
  rows: { blocks: { id: string; row_index: string; ord: string }[] }[];
}) => {
  const formData = new FormData();
  formData.append("version", device_interface.version);
  device_interface.rows.forEach((row) => {
    row.blocks.forEach((block) => {
      if (block.id !== void 0) {
        formData.append(
          "interface_blocks_interfaces_attributes[][interface_block_id]",
          block.id
        );
        formData.append(
          "interface_blocks_interfaces_attributes[][row]",
          block.row_index
        );
        formData.append(
          "interface_blocks_interfaces_attributes[][ord]",
          block.ord
        );
      }
    });
  });
  return formData;
};

export const requestCreateDeviceInterface = createAsyncThunk(
  "device_interface/create",
  async (device_interface: any) => {
    try {
      const formData = prepareFormData(device_interface);
      const { device_interface: createdInterface } =
        await api.admin.interfaces.create(formData);
      successToaster("Интерфейс успешно сохранён");
      return createdInterface;
    } catch (error) {
      handleErrors("Не удалось сохранить интерфейс", error);
    }
  }
);

export const requestUpdateDeviceInterface = createAsyncThunk(
  "device_interface/update",
  async (device_interface: any) => {
    try {
      const formData = prepareFormData(device_interface);
      const { device_interface: updatedInterface } =
        await api.admin.interfaces.update(device_interface.id, formData);
      successToaster("Интерфейс успешно сохранён");
      return updatedInterface;
    } catch (error) {
      handleErrors("Не удалось сохранить интерфейс", error);
    }
  }
);

export const addDeviceRow = createAction("device_row/add");

export const removeDeviceRow = createAction("device_row/remove");

export const addDeviceCell = createAction("device_cell/add");

export const removeDeviceCell = createAction<any>("device_cell/remove");

export const removeBottomCell = createAction<number>(
  "device_cell/bottom/remove"
);

export const addBottomCell = createAction<number>("device_cell/bottom/add");

export const updateDeviceCell = createAction<any>("device_cell/update");

export const onCellDrop = createAsyncThunk(
  "cell/drop",
  async (block: { units: boolean; ord: number }, { dispatch }) => {
    if (block.units) {
      dispatch(addDeviceRow());
      dispatch(removeBottomCell(block.ord));
    }
    dispatch(updateDeviceCell(block));
    dispatch(addDeviceCell());
  }
);
