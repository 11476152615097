import axios, { AxiosRequestConfig } from "axios";
// import { BASE_URL } from "../config/config";

export const apiRequest = async <T>(
  url: string,
  method = "GET",
  payload = {},
  headers = {}
): Promise<T> => {
  const config: AxiosRequestConfig = {
    url,
    method,
    headers,
  };
  if (method === "GET" || method === "get") {
    config.params = payload;
  } else {
    config.data = payload;
  }
  const { data } = await axios(config);
  return data as T;
};
