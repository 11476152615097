import { createReducer } from "@reduxjs/toolkit";
import {
  togglePanelRunningRequest,
  updatePanel,
  updatePanelAdminSettings,
  updatePanelSchedule,
  updatePanelSettings,
  updatePanelTime,
} from "../actions/panels/panel";
import { loadDevice } from "../actions/device";
import { requestDeviceSettings } from "../actions/device_settings/device_settings";

export default createReducer<any>({}, (builder) => {
  builder.addCase(loadDevice.fulfilled, (state, action) => action.payload);
  builder.addCase(
    requestDeviceSettings.fulfilled,
    (state, action) => action.payload
  );
  builder.addCase(updatePanel, (state, action) => {
    if (state.serial !== action.payload.serial) return state;
    return { ...state, ...action.payload };
  });
  builder.addCase(updatePanelSchedule, (state, action) => {
    return {
      ...state,
      schedule: [
        ...state.schedule.slice(0, action.payload.replaceIdx),
        ...action.payload.values,
        ...state.schedule.slice(
          action.payload.replaceIdx + action.payload.values.length
        ),
      ],
    };
  });
  builder.addCase(updatePanelAdminSettings, (state, action) => {
    return {
      ...state,
      admin_settings: [
        ...state.admin_settings.slice(0, action.payload.replaceIdx),
        ...action.payload.values,
        ...state.admin_settings.slice(
          action.payload.replaceIdx + action.payload.values.length
        ),
      ],
    };
  });
  builder.addCase(updatePanelSettings, (state, action) => {
    return {
      ...state,
      settings: [
        ...state.settings.slice(0, action.payload.replaceIdx),
        ...action.payload.values,
        ...state.settings.slice(
          action.payload.replaceIdx + action.payload.values.length
        ),
      ],
    };
  });
  builder.addCase(updatePanelTime, (state, action) => {
    return {
      ...state,
      time: [...action.payload.values],
      timeSetAt: action.payload.timeSetAt,
    };
  });
  builder.addCase(togglePanelRunningRequest.fulfilled, (state, action) => ({
    ...state,
    ...action.payload,
  }));
});
