import React, { FC, useCallback, useEffect, useState } from "react";
import Panel from "./Panel";
import PanelMenu from "./PanelMenu";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { PanelInterface } from "../../dto";
// import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";
// import { requestSaveLabel } from "../../redux/actions/device_labels";
import { loadDevice } from "../../redux/actions/device";

const DevicePanel: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const { panelId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(
        loadDevice({
          deviceId: panelId,
          cb: (panel) => setLabel(panel.label ?? panel.serial.toString()),
        })
      );
    }
  }, [dispatch, panelId, currentUser.isLoading]);

  const panel = useSelector<BusstationState, PanelInterface>(
    (state) => state.panel
  );

  const [label, setLabel] = useState<string>(
    panel?.label ?? panel?.serial?.toString() ?? ""
  );

  // const [debouncedLabel] = useDebounce(label, 500);

  // const saveLabel = useCallback(
  //   (label) => {
  //     if (
  //       panel.serial &&
  //       label &&
  //       label.length > 0 &&
  //       label.toString() !== panel.serial.toString()
  //     )
  //       dispatch(requestSaveLabel({ deviceId: panel.id, label }));
  //   },
  //   [dispatch, panel.serial, panel.id]
  // );

  const labelChanged = useCallback(
    (e) => {
      setLabel(e.target.value);
    },
    [setLabel]
  );

  // const saveIfEnter = useCallback(
  //   (e) => {
  //     if (e.keyCode !== 13 || label.length === 0) return;
  //     e.preventDefault();
  //     saveLabel(label);
  //   },
  //   [saveLabel, label]
  // );

  // useEffect(() => {
  //   saveLabel(debouncedLabel);
  // }, [debouncedLabel, saveLabel]);

  const col_span = currentUser.attributes.role === "reseller" ? 12 : 9;

  return (
    <div className="container-fluid">
      <div className="block-header">
        {(currentUser.attributes.role === "reseller" && (
          <h2>Панель {label}</h2>
        )) || (
          <h2>
            Панель{" "}
            <input
              disabled={!panel}
              type="text"
              defaultValue={panel?.label ?? panel?.serial?.toString() ?? ""}
              onChange={labelChanged}
              // onKeyUp={saveIfEnter}
            />
          </h2>
        )}
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 device-col">
          <div className="card">
            <div className="body" style={{ backgroundColor: "#52A3ED" }}>
              <div className="row clearfix m-b-0">
                {currentUser.attributes.role !== "reseller" && (
                  <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 panel-menu-col">
                    <PanelMenu panel={panel} />
                  </div>
                )}
                <div
                  className={`col-lg-${col_span} col-md-${col_span} col-sm-12 col-xs-12 m-b-0 device-panel-container`}
                >
                  <Panel panel={panel} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevicePanel;
