import { createReducer } from "@reduxjs/toolkit";
import { loadFirmware, setFirmware } from "../actions/firmware";
import { loadDeviceMap } from "../actions/device_map/device_map";
import { FirmwareProps } from "../../dto/device-map";

export default createReducer<FirmwareProps>({ registers: [] }, (builder) => {
  builder.addCase(setFirmware, (state, action) => action.payload);
  builder.addCase(loadFirmware.fulfilled, (state, action) => action.payload);
  builder.addCase(
    loadDeviceMap.fulfilled,
    (state, action) => action.payload.current_firmware
  );
});
