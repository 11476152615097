import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { DeviceInterfaceProps } from "../../../dto/device-map";
import { handleErrors } from "../../../common";
import { successToaster } from "../../../common/alerts";

export const addInterface = createAction<any>("interfaces/add");

export const requestRemoveInterface = createAsyncThunk(
  "interfaces/remove",
  async (_interface: DeviceInterfaceProps) => {
    try {
      await api.admin.interfaces.destroy(_interface.id);
      successToaster("Интерфейс удалён");
      return _interface;
    } catch (error) {
      handleErrors("Не удалось удалить интерфейс", error);
    }
  }
);

export const loadInterfaceList = createAsyncThunk(
  "interface/list/load",
  async () => {
    try {
      const { device_interfaces } = await api.admin.interfaces.list();
      return device_interfaces;
    } catch (e) {
      handleErrors("Не удалось загрузить интерфейсы.", e);
    }
  }
);

export const loadInterfaceWithBlocks = createAsyncThunk(
  "interface/load/with_blocks",
  async (interfaceId: number | string | null = null) => {
    try {
      if (interfaceId) {
        const [{ device_interface }, { blocks }] = await Promise.all([
          api.admin.interfaces.show(interfaceId),
          api.admin.interfaceBlocks.list(),
        ]);
        device_interface.rows.forEach((row) => row.blocks.push({ url: "" }));
        return { device_interface, blocks };
      } else {
        const { blocks } = await api.admin.interfaceBlocks.list();
        return {
          device_interface: {
            id: null,
            version: null,
            rows: [{ blocks: [{ url: "" }] }],
            units: 1,
          },
          blocks,
        };
      }
    } catch (e) {
      handleErrors("Не удалось загрузить интерфейс", e);
    }
  }
);
