import React, { FC, useCallback } from "react";
import moment from "moment";

import { requestRemoveFirmware } from "../../redux/actions/firmware";
import { DATE_FORMAT } from "../../common";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";

const FirmwareTableRow: FC<{ firmware: any }> = ({ firmware }) => {
  const dispatch = useDispatch<DispatchType>();
  const remove = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(requestRemoveFirmware(firmware));
    },
    [firmware, dispatch]
  );

  return (
    <tr>
      <td>
        <Link to={`/firmwares/${firmware.id}`}>{firmware.version}</Link>
      </td>
      <td>{moment(firmware.updated_at).format(DATE_FORMAT)}</td>
      <td>
        <a href="#" onClick={remove}>
          <i className="material-icons">delete_forever</i>
        </a>
      </td>
    </tr>
  );
};

export default FirmwareTableRow;
