import React, { FC } from "react";

import { ItemTypes } from "./Constants";
import { useDrop } from "react-dnd";

const DeviceCell: FC<{
  block: any;
  onCellDrop?: (item: any) => void;
}> = ({ block, onCellDrop }) => {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.TOOLBAR_BLOCK,
      drop: (item: {
        name: string;
        inner: boolean;
        units: boolean;
        id: number;
        thumb_url: string;
      }) => {
        if (typeof onCellDrop === "function") {
          onCellDrop({ ...block, ...item });
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [block, onCellDrop]
  );
  return (
    <td
      ref={drop}
      rowSpan={block.units ? 2 : 1}
      style={{ width: block.thumb_url === void 0 ? 100 : "auto" }}
    >
      <img
        alt={block.id}
        src={block.thumb_url}
        className={
          block.inner ? "arrow" : block.units ? "two-units-img" : "one-unit-img"
        }
      />
      {isOver && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: 76,
            width: 100,
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: "lightgreen",
          }}
        />
      )}
    </td>
  );
};

export default DeviceCell;
