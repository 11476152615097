import React, { FC } from "react";
import BitMaskCell from "./BitMaskCell";

const BitMaskRow: FC<{ row_index: number; bits: number[] }> = ({
  row_index,
  bits,
}) => (
  <tr>
    <td style={{ border: "none" }}>{row_index * 8}</td>
    {bits.map((bit, i) => (
      <BitMaskCell
        key={i}
        row_index={row_index}
        cell_index={i}
        checked={bit === 1}
      />
    ))}
    <td style={{ border: "none" }}>{row_index * 8 + 7}</td>
  </tr>
);

export default BitMaskRow;
