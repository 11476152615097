import { getUshort, handleErrors, stopped } from "../../../common";
import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { PanelInterface } from "../../../dto";
import { successToaster } from "../../../common/alerts";

export const updatePanel = createAction<any>("panel/update");

export const updatePanelSchedule = createAction<{
  values: number[];
  replaceIdx: number;
}>("panel/update/schedule");

export const updatePanelTime = createAction<{
  values: number[];
  timeSetAt: string;
}>("panel/update/time");

export const updatePanelSettings = createAction<{
  values: number[];
  replaceIdx: number;
}>("panel/update/settings");

export const updatePanelAdminSettings = createAction<{
  values: number[];
  replaceIdx: number;
}>("panel/update/settings/admin");

export const togglePanelRunningRequest = createAsyncThunk(
  "panel/run",
  async (panel: PanelInterface) => {
    const code = getUshort(panel.payload, 0);
    const start = stopped(code);
    try {
      const { device } = await api.devices.toggleRunning(panel.id, start);
      successToaster(`Панель ${start ? "запущена" : "остановлена"}`);
      return device;
    } catch (error) {
      handleErrors(
        `Не удалось ${start ? "запустить" : "остановить"} панель`,
        error
      );
    }
  }
);

export const reconnectPanelRequest = createAsyncThunk(
  "panel/reconnect",
  async (panel: PanelInterface) => {
    try {
      await api.devices.destroyConnection(panel.id);
      successToaster(`Подключение панели ${panel.serial} обновлено`);
    } catch (error) {
      handleErrors(
        `Не удалось обновить подключение панели ${panel.serial}`,
        error
      );
    }
  }
);

export const destroyPanelRequest = createAsyncThunk(
  "panel/destroy",
  async (panel: PanelInterface) => {
    try {
      await api.devices.destroy(panel.id);
      successToaster(`Панель ${panel.serial} удалена`);
    } catch (error) {
      handleErrors(`Не удалось удалить панель ${panel.serial}`, error);
    }
  }
);

export const loadPanels = createAsyncThunk(
  "panels/load",
  async (_, { rejectWithValue }) => {
    try {
      const { devices } = await api.devices.list();
      return devices;
    } catch (e) {
      handleErrors("Не удалось загрузить панели", e);
      return rejectWithValue(e);
    }
  }
);
