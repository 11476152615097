import React, { FC, useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import FirmwareDecorator from "../../redux/decorators/firmware_decorator";
import RegValueTableHeader from "./RegValueTableHeader";
import RegValueRow from "./RegValueRow";
import RegNewValueRow from "./RegNewValueRow";

import {
  toggleEditDialog,
  regSaveSettings,
} from "../../redux/actions/device_map/device_map";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";

const SettingsRegistersDialog: FC<{
  visible: boolean;
  firmware: any;
  read_address?: number;
  write_address?: number;
  register_settings: any[];
}> = ({
  visible,
  read_address,
  write_address,
  firmware: rawFirmware,
  register_settings,
}) => {
  const dispatch = useDispatch<DispatchType>();

  const [label, setLabel] = useState<string>("");

  const [show_to_user, setShowToUser] = useState<boolean>(false);

  const [settings, setSettings] = useState<any[] | null>(null);

  const firmware = useMemo(
    () => new FirmwareDecorator(rawFirmware),
    [rawFirmware]
  );

  const saveState = () => {
    dispatch(
      regSaveSettings({
        label,
        show_to_user,
        settings,
        read_address,
        write_address,
      })
    );
  };

  const handleClose = () => {
    saveState();
    dispatch(toggleEditDialog(false));
  };

  const findSetting = () => {
    return register_settings.find(
      (rs) =>
        rs.read_address === read_address && rs.write_address === write_address
    );
  };

  const initSetting = () => {
    const { label, show_to_user, settings } = findSetting() || {
      label: "",
      show_to_user: false,
    };
    setLabel(label);
    setShowToUser(show_to_user);
    if (settings) setSettings(settings);
  };

  const regLabelChanged = (e) => {
    setLabel(e.target.value);
  };

  const showToUserChanged = (e) => {
    setShowToUser(e.target.checked);
  };

  const presetStateChanged = (e) => {
    if (e.target.checked) setSettings([]);
  };

  const presetInternalValueChanged = (val, index) => {
    setSettings([
      ...settings.slice(0, index),
      {
        interface_value: settings[index].interface_value,
        internal_value: val,
      },
      ...settings.slice(index + 1),
    ]);
  };

  const presetInterfaceValueChanged = (val, index) => {
    setSettings([
      ...settings.slice(0, index),
      {
        internal_value: settings[index].internal_value,
        interface_value: val,
      },
      ...settings.slice(index + 1),
    ]);
  };

  const removePreset = (index: number) => {
    if (!settings) return;
    setSettings([...settings.slice(0, index), ...settings.slice(index + 1)]);
  };

  const createPreset = useCallback(
    (internal_value, interface_value) => {
      setSettings(
        settings === null
          ? [{ internal_value, interface_value }]
          : [...settings, { internal_value, interface_value }]
      );
    },
    [settings]
  );

  const read_reg = firmware.register(read_address);
  const write_reg = firmware.register(write_address);
  return (
    <Modal onEnter={initSetting} show={visible} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{`${read_reg.description} - ${write_reg.description}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 form-control-label">
            <label htmlFor="reg_name">Название в интерфейсе</label>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <div className="form-group">
              <div className="form-line">
                <input
                  className="form-control"
                  type="text"
                  id="reg_name"
                  value={label}
                  onChange={regLabelChanged}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <input
              type="checkbox"
              id="show_to_user"
              name="show_to_user"
              checked={show_to_user}
              onChange={showToUserChanged}
            />
            <label htmlFor="show_to_user">Показывать пользователям</label>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
            <input
              type="checkbox"
              id="is_radio"
              name="is_radio"
              checked={settings !== null}
              onChange={presetStateChanged}
            />
            <label htmlFor="is_radio">Предустановленные значения</label>
          </div>
        </div>
        {settings && settings !== void 0 && <RegValueTableHeader />}
        {settings && settings !== void 0 && (
          <RegNewValueRow createRegPreset={createPreset} />
        )}
        {settings &&
          settings !== void 0 &&
          settings.map((v, i) => {
            return (
              <RegValueRow
                key={i}
                id={i}
                interface_value={v.interface_value}
                internal_value={v.internal_value}
                removeRegPreset={removePreset}
                internalValueChanged={presetInternalValueChanged}
                interfaceValueChanged={presetInterfaceValueChanged}
              />
            );
          })}
      </Modal.Body>
    </Modal>
  );
};

// class SettingsRegistersDialog extends React.Component {
//   constructor(props) {
//     super(props);
//     this.handleClose = this.handleClose.bind(this);
//     this.showToUserChanged = this.showToUserChanged.bind(this);
//     this.presetStateChanged = this.presetStateChanged.bind(this);
//     this.createPreset = this.createPreset.bind(this);
//     this.removePreset = this.removePreset.bind(this);
//     this.presetInternalValueChanged =
//       this.presetInternalValueChanged.bind(this);
//     this.presetInterfaceValueChanged =
//       this.presetInterfaceValueChanged.bind(this);
//     this.handleClickOutside = this.handleClickOutside.bind(this);
//     this.regLabelChanged = this.regLabelChanged.bind(this);
//     this.saveState = this.saveState.bind(this);
//     this.initSetting = this.initSetting.bind(this);
//     this.firmware = new FirmwareDecorator(this.props.firmware);
//     this.state = {
//       label: "",
//       show_to_user: false,
//       settings: void 0,
//     };
//   }
//
//   handleClickOutside() {
//     this.handleClose();
//   }
//
//   handleClose() {
//     this.saveLastPreset();
//     this.saveState();
//     store.dispatch(toggleEditDialog(false));
//   }
//
//   initSetting() {
//     const setting = this.findSetting() || {
//       label: "",
//       show_to_user: false,
//       settings: void 0,
//     };
//     if (!setting.hasOwnProperty("settings")) setting.settings = void 0;
//     this.setState(setting);
//   }
//
//   render() {
//     const read_reg = this.firmware.register(this.props.read_address);
//     const write_reg = this.firmware.register(this.props.write_address);
//     return (
//       <Modal
//         onEnter={this.initSetting}
//         show={this.props.visible}
//         onHide={this.handleClose}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{`${read_reg.description} - ${write_reg.description}`}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className="row clearfix">
//             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 form-control-label">
//               <label htmlFor="reg_name">Название в интерфейсе</label>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
//               <div className="form-group">
//                 <div className="form-line">
//                   <input
//                     className="form-control"
//                     type="text"
//                     id="reg_name"
//                     value={this.state.label}
//                     onChange={this.regLabelChanged}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row clearfix">
//             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
//               <input
//                 type="checkbox"
//                 id="show_to_user"
//                 name="show_to_user"
//                 checked={this.state.show_to_user}
//                 onChange={this.showToUserChanged}
//               />
//               <label htmlFor="show_to_user">Показывать пользователям</label>
//             </div>
//             <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
//               <input
//                 type="checkbox"
//                 id="is_radio"
//                 name="is_radio"
//                 checked={this.state.settings !== void 0}
//                 onChange={this.presetStateChanged}
//               />
//               <label htmlFor="is_radio">Предустановленные значения</label>
//             </div>
//           </div>
//           {this.state.settings && this.state.settings !== void 0 && (
//             <RegValueTableHeader />
//           )}
//           {this.state.settings && this.state.settings !== void 0 && (
//             <RegNewValueRow
//               ref="new_preset_editor"
//               createRegPreset={this.createPreset}
//             />
//           )}
//           {this.state.settings &&
//             this.state.settings !== void 0 &&
//             this.state.settings.map((v, i) => {
//               return (
//                 <RegValueRow
//                   id={i}
//                   interface_value={v.interface_value}
//                   internal_value={v.internal_value}
//                   removeRegPreset={this.removePreset}
//                   internalValueChanged={this.presetInternalValueChanged}
//                   interfaceValueChanged={this.presetInterfaceValueChanged}
//                 />
//               );
//             })}
//         </Modal.Body>
//       </Modal>
//     );
//   }
//
//   findSetting() {
//     return this.props.register_settings.find(
//       (rs) =>
//         rs.read_address === this.props.read_address &&
//         rs.write_address === this.props.write_address
//     );
//   }
//
//   regLabelChanged(e) {
//     this.setState({ label: e.target.value });
//   }
//
//   showToUserChanged(e) {
//     this.setState({ show_to_user: e.target.checked });
//   }
//
//   presetStateChanged(e) {
//     this.setState({ settings: e.target.checked ? [] : void 0 });
//   }
//
//   presetInternalValueChanged(val, index) {
//     this.setState({
//       settings: [
//         ...this.state.settings.slice(0, index),
//         {
//           interface_value: this.state.settings[index].interface_value,
//           internal_value: val,
//         },
//         ...this.state.settings.slice(index + 1),
//       ],
//     });
//   }
//
//   presetInterfaceValueChanged(val, index) {
//     this.setState({
//       settings: [
//         ...this.state.settings.slice(0, index),
//         {
//           internal_value: this.state.settings[index].internal_value,
//           interface_value: val,
//         },
//         ...this.state.settings.slice(index + 1),
//       ],
//     });
//   }
//
//   removePreset(index) {
//     if (this.state.settings === void 0) return;
//     this.setState({
//       settings: [
//         ...this.state.settings.slice(0, index),
//         ...this.state.settings.slice(index + 1),
//       ],
//     });
//   }
//
//   createPreset(internal_value, interface_value) {
//     this.setState({
//       settings:
//         this.state.settings === void 0
//           ? [{ internal_value, interface_value }]
//           : [...this.state.settings, { internal_value, interface_value }],
//     });
//   }
//
//   saveState() {
//     store.dispatch(
//       regSaveSettings({
//         ...this.state,
//         read_address: this.props.read_address,
//         write_address: this.props.write_address,
//       })
//     );
//   }
//
//   saveLastPreset() {
//     this.refs.new_preset_editor && this.refs.new_preset_editor.savePreset();
//   }
// }
//
// SettingsRegistersDialog.propTypes = {
//   visible: PropTypes.bool.isRequired,
//   firmware: PropTypes.object.isRequired,
//   read_address: PropTypes.number,
//   write_address: PropTypes.number,
//   register_settings: PropTypes.array.isRequired,
// };

export default SettingsRegistersDialog;
