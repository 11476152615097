import React, { FC, useEffect } from "react";

import FirmwareTableRow from "./FirmwareTableRow";
import FileUploader from "../common/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadFirmwareList } from "../../redux/actions/firmware";

const FirmwareTable: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const firmwares = useSelector<BusstationState, any[]>(
    (state) => state.firmwares
  );

  const current_firmware = useSelector<BusstationState, any>(
    (state) => state.current_firmware
  );

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadFirmwareList());
    }
  }, [dispatch, currentUser.isLoading]);

  return (
    <div className="container-fluid">
      <div className="block-header">
        <h2>Прошивки</h2>
      </div>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="body">
              <FileUploader
                firmware_number={current_firmware.number}
                file_name={current_firmware.file_name}
              />
              <table className="table">
                <thead>
                  <tr>
                    <th>Версия прошивки</th>
                    <th>Обновлена</th>
                  </tr>
                </thead>
                <tbody>
                  {firmwares.map((fw) => {
                    return <FirmwareTableRow key={fw.id} firmware={fw} />;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirmwareTable;
