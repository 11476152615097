export const DATE_FORMAT = "DD.MM.YYYY";

// export function dateFromString(str: string): Date {
//   const b = str.split(/\D/);
//   const year = parseInt(b[0]);
//
//   return new Date(
//     year < 2000 ? year + 2000 : year,
//     parseInt(b[1]) - 1,
//     parseInt(b[2]),
//     parseInt(b[3]),
//     parseInt(b[4]),
//     parseInt(b[5])
//   );
// }

// export function timeFromString(str: string): Date {
//   const b = str.split(/\D/);
//   const date = new Date();
//   date.setHours(parseInt(b[0]));
//   date.setMinutes(parseInt(b[1]));
//   return date;
// }

// export function stringFromTime(d?: Date | null): string {
//   return d === void 0 || d === null
//     ? "00:00"
//     : `${padWithZeros(d.getHours())}:${padWithZeros(d.getMinutes())}`;
// }

// function padWithZeros(num: number): string {
//   return ("0" + num).slice(-2);
// }

export function isoDateString(d: Date): string {
  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  return (
    d.getFullYear() +
    "-" +
    pad(d.getMonth() + 1) +
    "-" +
    pad(d.getDate()) +
    "T" +
    pad(d.getHours()) +
    ":" +
    pad(d.getMinutes()) +
    ":" +
    pad(d.getSeconds()) +
    "Z"
  );
}
