import React, { FC, useCallback } from "react";

import tabPanel from "../common/TabPanel";
import tabPane from "../common/TabPane";
import {
  profileSettingChanged,
  saveProfileSettingsRequest,
} from "../../redux/actions/profile/profile_actions";
import { useDispatch } from "react-redux";
import { DispatchType } from "../../redux/store/type";
import { ProfileUserProps } from "../../dto/user";

const inputs = {
  first_name: "Имя",
  last_name: "Фамилия",
  email: "Email",
  phone: "Телефон",
};

const checkboxes = {
  notify_via_sms: "Присылать СМС-уведомления",
  notify_via_email: "Присылать уведомления на почту",
};

const ProfileInfo: FC<
  { user: ProfileUserProps } & {
    active_pane: string;
    tabClicked: (tab: string) => void;
  }
> = ({ user }) => {
  const dispatch = useDispatch<DispatchType>();
  const valueChanged = useCallback(
    (e) => {
      dispatch(
        profileSettingChanged({
          value: e.target.value,
          field_name: e.target.name,
        })
      );
    },
    [dispatch]
  );

  const checkChanged = useCallback(
    (e) => {
      dispatch(
        profileSettingChanged({
          value: e.target.checked,
          field_name: e.target.name,
        })
      );
    },
    [dispatch]
  );

  const hasErrors = useCallback(() => {
    const errors = user.errors ?? {};
    let hasErrors = false;
    Object.keys(errors).every((k) => {
      hasErrors =
        ["first_name", "last_name", "email", "phone"].indexOf(k) > -1 &&
        !!errors[k];
      return !hasErrors;
    });
    return hasErrors;
  }, [user.errors]);

  const saveInfo = useCallback(() => {
    if (hasErrors()) return false;
    dispatch(saveProfileSettingsRequest(user));
  }, [dispatch, user, hasErrors]);

  return (
    <form className="form-horizontal">
      {user &&
        Object.keys(inputs).map((field_name) => {
          const label = inputs[field_name];
          return (
            <div key={field_name} className="row clearfix">
              <div className="col-lg-2 col-md-2 col-sm-4 col-xs-5 form-control-label">
                <label htmlFor="first_name">{label}</label>
              </div>
              <div className="col-lg-10 col-md-10 col-sm-8 col-xs-7">
                <div className="form-group">
                  <div
                    className={`form-line${
                      (user.errors && user.errors[field_name] && " error") || ""
                    }`}
                  >
                    <input
                      type={field_name === "email" ? "email" : "text"}
                      id={field_name}
                      name={field_name}
                      className="form-control"
                      value={user[field_name] || ""}
                      onChange={valueChanged}
                    />
                  </div>
                  {user.errors && user.errors[field_name] && (
                    <label className="error" htmlFor={field_name}>
                      {user.errors[field_name]}
                    </label>
                  )}
                </div>
              </div>
            </div>
          );
        })}

      {user &&
        Object.keys(checkboxes).map((field_name) => {
          const label = checkboxes[field_name];
          return (
            <div key={field_name} className="row clearfix">
              <div className="col-lg-offset-2 col-md-offset-2 col-sm-offset-4 col-xs-offset-5">
                <input
                  type="checkbox"
                  id={field_name}
                  name={field_name}
                  checked={user[field_name]}
                  onChange={checkChanged}
                  className="filled-in"
                />
                <label htmlFor={field_name}>{label}</label>
              </div>
            </div>
          );
        })}

      <div className="row clearfix">
        <div className="col-lg-offset-2 col-md-offset-2 col-sm-offset-4 col-xs-offset-5">
          <button
            disabled={hasErrors()}
            type="button"
            className="btn btn-primary m-t-15 waves-effect"
            onClick={saveInfo}
          >
            Сохранить!
          </button>
        </div>
      </div>
    </form>
  );
};

export default tabPane(tabPanel(ProfileInfo), "info");
