import { apiRequest } from "../common";
import { FirmwareProps } from "../../dto/device-map";

const API_URL = "/api/v1/admin/firmwares";
const firmwareUrl = (firmwareId: number | string) => `${API_URL}/${firmwareId}`;

const show = async (firmwareId: number | string) =>
  apiRequest<{ firmware: FirmwareProps }>(firmwareUrl(firmwareId));

const list = async ({
  interfaceId,
  deviceMapId,
}: {
  interfaceId?: string;
  deviceMapId?: string;
} = {}) => {
  const data = { interfaceId, deviceMapId };
  if (!interfaceId) delete data.interfaceId;
  if (!deviceMapId) delete data.deviceMapId;
  const searchParams = new URLSearchParams(data);
  const search = searchParams.toString();

  return apiRequest<{ firmwares: FirmwareProps[] }>(
    `${API_URL}${search.length > 0 ? `?${search}` : ""}`
  );
};

const create = async (data: any) =>
  apiRequest<{ firmware: FirmwareProps }>(API_URL, "post", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const destroy = async (firmwareId: number | string) =>
  apiRequest<{ firmware: FirmwareProps }>(firmwareUrl(firmwareId), "delete");

export const firmwares = {
  create,
  show,
  list,
  destroy,
};
