import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { BusstationState } from "../../redux/store/type";

export const AdminLayout = () => {
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  if (!currentUser.isLoading && currentUser.attributes.role !== "admin") {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};
