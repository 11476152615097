import React, { FC, useEffect } from "react";
import FirmwareRow from "./FirmwareRow";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadFirmware } from "../../redux/actions/firmware";
import { useParams } from "react-router-dom";
import { LoadingProgress } from "../common/LoadingProgress";

const Firmware: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const firmware = useSelector<BusstationState, { registers: any[] }>(
    (state) => state.firmware
  );

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const { firmwareId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(loadFirmware(firmwareId));
    }
  }, [dispatch, firmwareId, currentUser.isLoading]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Адрес</th>
          <th>Тип данных</th>
          <th>Описание</th>
        </tr>
      </thead>
      <tbody>
        {(firmware.registers.length === 0 && <LoadingProgress />) ||
          firmware.registers.map((reg) => {
            return <FirmwareRow key={reg.address} register={reg} />;
          })}
      </tbody>
    </table>
  );
};

export default Firmware;
