import React, { FC, useCallback, useEffect } from "react";

import DeviceSettingsTable from "./DeviceSettingsTable";
import {
  requestDeviceSettings,
  saveDeviceSettings,
} from "../../redux/actions/device_settings/device_settings";
import { fromDataType } from "../../common";
import { updatePanelAdminSettings } from "../../redux/actions/panels/panel";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { PanelInterface } from "../../dto";
import { Link, useParams } from "react-router-dom";

const DeviceAdminSettingsPanel: FC = () => {
  const dispatch = useDispatch<DispatchType>();
  const panel = useSelector<BusstationState, PanelInterface>(
    (state) => state.panel
  );

  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const { panelId } = useParams();

  useEffect(() => {
    if (!currentUser.isLoading) {
      dispatch(
        requestDeviceSettings({
          deviceId: panelId,
          firmwareSections: ["admin_settings"],
        })
      );
    }
  }, [dispatch, panelId, currentUser.isLoading]);

  const handleSaveDeviceSettings = useCallback(() => {
    dispatch(
      saveDeviceSettings({ id: panel.id, admin_settings: panel.admin_settings })
    );
  }, [panel.id, panel.admin_settings, dispatch]);

  const settingChanged = useCallback(
    (value, replaceIdx, dataType) => {
      const values = fromDataType(value, dataType);
      dispatch(updatePanelAdminSettings({ values, replaceIdx }));
    },
    [dispatch]
  );

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <h2>
            <Link to={`/panels/${panel.id}`}>НАЗАД</Link> | Администртивные
            настройки панели {panel.label ?? panel.serial}
          </h2>
        </div>
        <div className="row clearfix">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="body">
                <DeviceSettingsTable
                  firmware={panel.template.firmware}
                  settingChanged={settingChanged}
                  settings={panel.template.admin_settings}
                  payload={panel.admin_settings}
                />
                <div className="row clearfix">
                  <div className="col-lg-1 col-md-2 col-sm-6 col-xs-6">
                    <button
                      className="btn btn-primary waves-effect"
                      onClick={handleSaveDeviceSettings}
                    >
                      Сохранить!
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    display: panel.action_in_progress ? "" : "none",
                  }}
                  className="loading"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviceAdminSettingsPanel;
