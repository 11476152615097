import React, { useEffect } from "react";
import ProfileTabs from "./ProfileTabs";
import ProfileAvatar from "./ProfileAvatar";
import { useDispatch, useSelector } from "react-redux";
import { BusstationState, DispatchType } from "../../redux/store/type";
import { loadProfile } from "../../redux/actions/profile/profile_actions";
import { ProfileUserProps } from "../../dto/user";

export default function UserProfile() {
  const { currentUser } = useSelector<BusstationState, any>(
    (state) => state.reduxTokenAuth
  );

  const current_user = useSelector<BusstationState, ProfileUserProps>(
    (state) => state.profile
  );

  const dispatch = useDispatch<DispatchType>();

  useEffect(() => {
    if (!currentUser.isLoading) dispatch(loadProfile());
  }, [currentUser.isLoading, dispatch]);

  return (
    <div className="row clearfix">
      <div className="col-fixed-155">
        <ProfileAvatar user={current_user} />
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-offset-175">
        <ProfileTabs user={current_user} />
      </div>
    </div>
  );
}
