import { api } from "../../../api";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { FirmwareProps } from "../../../dto/device-map";
import { handleErrors } from "../../../common";
import { successToaster } from "../../../common/alerts";

export const setFirmware = createAction<FirmwareProps>("firmware/set");

export const saveFirmwareRequest = createAsyncThunk(
  "firmware/save",
  async (currentFirmware: any, { dispatch }) => {
    const formData = new FormData();
    formData.append("firmware_number", currentFirmware.number);
    formData.append("file", currentFirmware.file);
    try {
      const { firmware } = await api.admin.firmwares.create(formData);
      dispatch(resetCurrentFirmware());
      successToaster("Прошивка сохранена");
      return firmware;
    } catch (error) {
      handleErrors("Не удалось сохранить прошивку", error);
    }
  }
);

export const requestRemoveFirmware = createAsyncThunk(
  "firmware/remove",
  async (firmware: FirmwareProps) => {
    try {
      await api.admin.firmwares.destroy(firmware.id);
      successToaster(`Прошивка ${firmware.version} удалена`);
      return firmware;
    } catch (error) {
      handleErrors("Не удалось удалить прошивку", error);
    }
  }
);

export const setFirmwareNumber = createAction<number>("fw/number/set");

export const addFirmwareFile = createAction<{ file_name: string; file: Blob }>(
  "fw/file/add"
);

export const resetCurrentFirmware = createAction("fw/reset");

export const loadFirmware = createAsyncThunk(
  "fw/load",
  async (firmwareId: number | string) => {
    try {
      const { firmware } = await api.admin.firmwares.show(firmwareId);
      return firmware;
    } catch (e) {
      handleErrors("Не удалось загрузить прошивку", e);
    }
  }
);

export const loadFirmwareList = createAsyncThunk("fw/list/load", async () => {
  try {
    const { firmwares } = await api.admin.firmwares.list();
    return firmwares;
  } catch (e) {
    handleErrors("Не удалось загрузить прошивки", e);
  }
});
