import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "moment/locale/ru";
import store from "../redux/store";
import { Provider } from "react-redux";
import { ruRU as globalRu } from "@mui/material/locale";
import { SnackbarProvider } from "notistack";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  globalRu
);

const Root: FC = () => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Outlet />
        </SnackbarProvider>
      </Provider>
    </LocalizationProvider>
  </ThemeProvider>
);

export default Root;
