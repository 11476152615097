export const stopped = (code: number) => (code & 1) > 0;
export const awaiting = (code: number) => (code & 2) > 0;
export const blocked = (code: number) => (code & 4) > 0;
export const ventilating = (code: number) => (code & 8) > 0;
export const warming = (code: number) => (code & 16) > 0;
export const jalousie = (code: number) => (code & 32) > 0;
export const season = (code: number) =>
  (code & 128) > 0 ? "winter" : "summer";
export const workingMode = (code: number) =>
  (code & 256) > 0 ? "by_schedule" : "manual";
export const restart = (code: number) => (code & 512) > 0;
export const run = (code: number) => (code & 1024) > 0;
export const needs_filters_replacement = (code: number) => (code & 2048) > 0;
export const alarm_reset = (code: number) => (code & 4096) > 0;
export const deviceState = (code: number) => ({
  stopped: stopped(code),
  awaiting: awaiting(code),
  blocked: blocked(code),
  ventilating: ventilating(code),
  warming: warming(code),
  jalousie: jalousie(code),
  restart: restart(code),
  run: run(code),
  needs_filters_replacement: needs_filters_replacement(code),
  alarm_reset: alarm_reset(code),
});
