import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../api";
import { PanelInterface } from "../../../dto";
import { handleErrors } from "../../../common";

export const removeDevice = createAction<{
  id: number;
  serial: number | string;
}>("devices/remove");

export const loadDevice = createAsyncThunk(
  "devices/show",
  async ({
    deviceId,
    cb,
  }: {
    deviceId: number | string;
    cb?: (panel: PanelInterface) => void;
  }) => {
    try {
      const { device } = await api.devices.show(deviceId);
      if (cb) cb(device);
      return device;
    } catch (e) {
      handleErrors("Не удалось загрузить панель", e);
    }
  }
);
