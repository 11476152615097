import React, { FC } from "react";

import TabLink from "../common/TabLink";
import ProfileInfo from "./ProfileInfo";
import ProfilePassword from "./ProfilePassword";

import TabsContainer from "../common/TabsContainer";
import { ProfileUserProps } from "../../dto/user";

const ProfileTabs: FC<
  { user: ProfileUserProps } & {
    active_pane: string;
    tabClicked: (tab: string) => void;
  }
> = ({ active_pane, tabClicked, user }) => {
  return (
    <React.Fragment>
      <ul className="nav nav-tabs tab-nav-right" role="tablist">
        {[
          { field_name: "info", title: "Общая информация" },
          { field_name: "password", title: "Смена пароля" },
        ].map((info, i) => (
          <TabLink
            key={i}
            active_pane={active_pane}
            field_name={info.field_name}
            title={info.title}
            onClick={tabClicked}
            // isDisabled={info.isDisabled}
          />
        ))}
      </ul>
      <div className="tab-content">
        <ProfileInfo
          user={user}
          active_pane={active_pane}
          tabClicked={tabClicked}
        />
        <ProfilePassword
          user={user}
          active_pane={active_pane}
          tabClicked={tabClicked}
        />
      </div>
    </React.Fragment>
  );
};

export default TabsContainer(ProfileTabs, "info");
