import React, { FC, useCallback } from "react";
import { InputLabel, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

interface DeviceSettingsRowProps {
  setting: {
    read_address: number | string;
    write_address: number | string;
    label: string;
    settings: { internal_value: string; interface_value: string }[];
  };
  settingChanged: (value: number) => void;
  value: number;
}

const DeviceSettingsRow: FC<DeviceSettingsRowProps> = ({
  setting,
  value,
  settingChanged,
}) => {
  const changed = useCallback(
    (e) => {
      settingChanged(parseInt(e.target.value));
    },
    [settingChanged]
  );
  return (
    <div className="row clearfix">
      <div
        className={`col-lg-12${
          setting.settings === void 0 ? " text-center" : ""
        }`}
      >
        {setting.settings ? (
          <FormControl fullWidth>
            <InputLabel
              id={`${setting.write_address}:${setting.read_address}-label`}
            >
              {setting.label}
            </InputLabel>
            <Select
              labelId={`${setting.write_address}:${setting.read_address}-label`}
              value={value}
              label={setting.label}
              onChange={changed}
              fullWidth={true}
            >
              {setting.settings.map((s) => (
                <MenuItem key={s.internal_value} value={s.internal_value}>
                  {s.interface_value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            label={setting.label}
            type="number"
            value={value}
            fullWidth={true}
            onChange={changed}
          />
        )}
      </div>
    </div>
  );
};

export default DeviceSettingsRow;
